export const FacadeAbi = [
  {
    inputs: [
      { internalType: 'contract IWETH', name: 'weth', type: 'address' },
      { internalType: 'contract IUniswapV2Router01', name: 'router', type: 'address' },
      { internalType: 'contract IOptionsManager', name: 'manager', type: 'address' },
      { internalType: 'address', name: 'trustedForwarder', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    inputs: [],
    name: 'WETH',
    outputs: [{ internalType: 'contract IWETH', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: '_trustedForwarder',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IHegicStaking[]', name: 'stakings', type: 'address[]' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'claimAllStakingProfits',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IHegicPool', name: 'pool', type: 'address' },
      { internalType: 'uint256', name: 'period', type: 'uint256' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'uint256', name: 'strike', type: 'uint256' },
      { internalType: 'address[]', name: 'swappath', type: 'address[]' },
      { internalType: 'uint256', name: 'acceptablePrice', type: 'uint256' },
    ],
    name: 'createOption',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'exchange',
    outputs: [{ internalType: 'contract IUniswapV2Router01', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'optionId', type: 'uint256' }],
    name: 'exercise',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IHegicPool', name: 'pool', type: 'address' },
      { internalType: 'uint256', name: 'period', type: 'uint256' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'uint256', name: 'strike', type: 'uint256' },
    ],
    name: 'getBaseOptionCost',
    outputs: [
      { internalType: 'uint256', name: 'total', type: 'uint256' },
      { internalType: 'uint256', name: 'settlementFee', type: 'uint256' },
      { internalType: 'uint256', name: 'premium', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IHegicPool', name: 'pool', type: 'address' },
      { internalType: 'uint256', name: 'period', type: 'uint256' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'uint256', name: 'strike', type: 'uint256' },
      { internalType: 'address[]', name: 'swappath', type: 'address[]' },
    ],
    name: 'getOptionPrice',
    outputs: [
      { internalType: 'uint256', name: 'total', type: 'uint256' },
      { internalType: 'uint256', name: 'baseTotal', type: 'uint256' },
      { internalType: 'uint256', name: 'settlementFee', type: 'uint256' },
      { internalType: 'uint256', name: 'premium', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'forwarder', type: 'address' }],
    name: 'isTrustedForwarder',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'optionsManager',
    outputs: [{ internalType: 'contract IOptionsManager', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract IHegicPool', name: 'pool', type: 'address' }],
    name: 'poolApprove',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IHegicPool', name: 'pool', type: 'address' },
      { internalType: 'bool', name: 'hedged', type: 'bool' },
      { internalType: 'uint256', name: 'minShare', type: 'uint256' },
    ],
    name: 'provideEthToPool',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'payable',
    type: 'function',
  },
  { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IHegicPool', name: 'pool', type: 'address' },
      { internalType: 'uint256[]', name: 'optionIDs', type: 'uint256[]' },
    ],
    name: 'unlockAll',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'versionRecipient',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'pure',
    type: 'function',
  },
];
