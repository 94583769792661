/* eslint-disable jsx-a11y/accessible-emoji */
import './app.less';
import './app.css';
import { TBDProvider, OptionsProvider, OptionPositionsProvider } from '@tbd/react';
import { LogLevel, FetchOptions, MultiNetworkConfiguration } from '@tbd/sdk';
import { Button, notification } from 'antd';
import { constants, ethers } from 'ethers';
import { useCallback, useReducer, useMemo } from 'react';
import { GlobalStyle } from '../components/GlobalStyle';
import { styled, ThemeProvider } from '../components/ThemeProvider';
import { Weighted } from '../components/Weighted';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { DAppProvider, useEthers } from '@usedapp/core';
import _ from 'lodash';
import { AppStateProvider } from '../components/AppStateProvider';
import { useNetworkState, NetworkStateProvider, NetworkToNetworkId, Network } from '../components/NetworkProvider';
import pjson from '../../../../package.json';
import { WalletModal } from '../components/wallet/WalletModal';
import { WalletModalProvider } from '../components/WalletModalProvider';
import { CartStateProvider } from '../components/cart/CartStateProvider';
import { CartModal } from '../components/cart/CartModal';
import MediaQuery from 'react-responsive';
import { network_configs, add_network_configs } from '../utils/network_configs';
import { TopBar } from '../components/shared/TopBar';
import { BottomBar } from '../components/shared/BottomBar';
import { MobileBottomBar } from '../components/shared/MobileBottomBar';
import { LLBuyOptions } from '../lazy/LLBuyOptions';
import { LLMyPositions } from '../lazy/LLMyPositions';

const ContentContainer = styled.div`
  width: 100%;

  @media (max-width: 1223px) {
    padding-bottom: calc(92px + env(safe-area-inset-bottom));
  }

  @media (min-width: 1224px) {
    padding-top: 80px;
    padding-bottom: 40px;
  }
`;

const floorToDay = (d: Date): Date => {
  return new Date(d.getTime() - (d.getTime() % (24 * 60 * 60 * 1000)));
};

const updateFetchOptions = (state: FetchOptions, action: Partial<FetchOptions>) => {
  const res = {
    ...state,
    ...action,
  };

  if (_.isEqual(state, res)) {
    return state;
  }
  return res;
};

const getTBDConfig = (network: Network, provider: ethers.providers.BaseProvider): MultiNetworkConfiguration => {
  switch (network) {
    case 'optimism': {
      return {
        10: {
          provider,
        },
      };
    }
    case 'optimism_fork': {
      return {
        10: {
          provider,
        },
      };
    }
    case 'arbitrum': {
      return {
        42161: {
          provider,
        },
      };
    }
    case 'arbitrum_fork': {
      return {
        42161: {
          provider,
        },
      };
    }
    case 'ethereum': {
      return {
        1: {
          provider,
        },
      };
    }
    case 'ethereum_fork': {
      return {
        1: {
          provider,
        },
      };
    }
    case 'kovan': {
      return {
        42: {
          provider,
        },
      };
    }
    case 'kovan_fork': {
      return {
        42: {
          provider,
        },
      };
    }
  }
};

const isTestMode = (network: Network): boolean => {
  switch (network) {
    case 'ethereum':
      return false;
    case 'arbitrum':
      return false;
    case 'optimism':
      return false;
    case 'ethereum_fork':
      return true;
    default:
      return false;
  }
};

const InformationRowData = styled.span<{ color?: string }>`
  color: ${(props) => props.color || '#000000'};
  font-size: 16px;
`;

const InvalidNetworkText = styled.span`
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
`;

const InvalidNetworkSubText = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-top: 12px;
`;

const NetworkGuard = ({ children }: React.PropsWithChildren<unknown>) => {
  const { chainId, library } = useEthers();
  const [networkState] = useNetworkState();

  const switchNetwork = useCallback(() => {
    if (chainId === undefined) {
      return;
    }
    if (chainId !== NetworkToNetworkId[networkState.network]) {
      library
        .send('wallet_switchEthereumChain', [{ chainId: `0x${NetworkToNetworkId[networkState.network].toString(16)}` }])
        .catch((e) => {
          if (e.code === 4902 && add_network_configs[networkState.network]) {
            library
              .send(`wallet_addEthereumChain`, [
                {
                  chainId: `0x${add_network_configs[networkState.network].chainId.toString(16)}`,
                  chainName: add_network_configs[networkState.network].chainName,
                  nativeCurrency: add_network_configs[networkState.network].currency,
                  rpcUrls: [add_network_configs[networkState.network].rpc],
                  blockExplorerUrls: [add_network_configs[networkState.network].explorer],
                },
              ])
              .catch((_e) => {
                notification.error({
                  message: `Error while trying to switch networks: ${_e.message}`,
                });
              });
          } else if (e.code !== -32002) {
            notification.error({
              message: `Error while trying to switch networks: ${e.message}`,
            });
          }
          console.error(e.message);
        });
    }
  }, [chainId, networkState.network, library]);

  if (chainId === undefined) {
    return <p>LOADING</p>;
  }

  if (chainId !== NetworkToNetworkId[networkState.network]) {
    return (
      <div
        style={{
          width: '100%',
          height: '50vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            maxWidth: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <InvalidNetworkText>Invalid network</InvalidNetworkText>
          <InvalidNetworkSubText>
            You are currently connected to a network with id <Weighted weight="900">{chainId}</Weighted> while network{' '}
            <span style={{ textTransform: 'capitalize' }}>{networkState.network}</span> with id{' '}
            <Weighted weight="900">{NetworkToNetworkId[networkState.network]}</Weighted> was expected.
          </InvalidNetworkSubText>
          <Button
            onClick={switchNetwork}
            type={'primary'}
            style={{
              marginTop: 32,
            }}
          >
            <span>
              Jump to <span style={{ textTransform: 'capitalize', fontWeight: 700 }}>{networkState.network}</span>
            </span>
          </Button>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const AppWithPositionProvider = ({
  fetchOpt,
  setFetchOpt,
}: {
  fetchOpt: FetchOptions;
  setFetchOpt: (fo: FetchOptions) => void;
}): JSX.Element => {
  const { account } = useEthers();

  return (
    <OptionPositionsProvider account={account}>
      <Router>
        <WalletModal />
        <CartModal />
        <MediaQuery minWidth={1224}>
          <TopBar />
          <BottomBar />
        </MediaQuery>
        <MediaQuery maxWidth={1223}>
          <MobileBottomBar />
        </MediaQuery>
        <ContentContainer>
          <NetworkGuard>
            <Switch>
              <Route path="/buy">
                <LLBuyOptions fetchOptions={fetchOpt} setFetchOptions={setFetchOpt} />
              </Route>
              <Route path="/sell">
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '80vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <InformationRowData>Work in Progress 👷‍♂️</InformationRowData>
                </div>
              </Route>
              <Route path="/positions">
                <LLMyPositions />
              </Route>
              <Redirect to="/buy" />
            </Switch>
          </NetworkGuard>
        </ContentContainer>
      </Router>
    </OptionPositionsProvider>
  );
};

const AppWithProvider = (): JSX.Element => {
  const [networkState] = useNetworkState();
  const { library, chainId, account } = useEthers();
  const [fetchOpt, setFetchOpt] = useReducer(updateFetchOptions, {
    expiry: [floorToDay(new Date()), floorToDay(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000))],
    strike: [constants.Zero, constants.MaxUint256],
  });
  const testMode = useMemo(() => isTestMode(networkState.network), [networkState.network]);
  const config = useMemo(() => {
    return getTBDConfig(networkState.network, library as unknown as ethers.providers.BaseProvider);
  }, [networkState.network, library]);

  if (library) {
    if (account) {
      return (
        <>
          <GlobalStyle />
          <TBDProvider
            config={config}
            logLevel={LogLevel.ERROR}
            testMode={testMode}
            paused={NetworkToNetworkId[networkState.network] !== chainId || chainId === undefined}
          >
            <OptionsProvider fetchOpt={fetchOpt}>
              <WalletModalProvider>
                <CartStateProvider>
                  <ThemeProvider>
                    <AppWithPositionProvider fetchOpt={fetchOpt} setFetchOpt={setFetchOpt} />
                  </ThemeProvider>
                </CartStateProvider>
              </WalletModalProvider>
            </OptionsProvider>
          </TBDProvider>
        </>
      );
    } else {
      return (
        <>
          <GlobalStyle />
          <TBDProvider
            config={config}
            logLevel={LogLevel.ERROR}
            testMode={testMode}
            paused={NetworkToNetworkId[networkState.network] !== chainId || chainId === undefined}
          >
            <OptionsProvider fetchOpt={fetchOpt}>
              <WalletModalProvider>
                <CartStateProvider>
                  <ThemeProvider>
                    <AppWithPositionProvider fetchOpt={fetchOpt} setFetchOpt={setFetchOpt} />
                  </ThemeProvider>
                </CartStateProvider>
              </WalletModalProvider>
            </OptionsProvider>
          </TBDProvider>
        </>
      );
    }
    console.log('RENDER');
  } else {
    return <p>LOADING</p>;
  }
};

const AppWithAppState = (): JSX.Element => {
  const [networkState] = useNetworkState();

  return (
    <DAppProvider config={network_configs[networkState.network]}>
      <AppWithProvider />
    </DAppProvider>
  );
};

export function App() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <AppStateProvider version={pjson.version}>
        <NetworkStateProvider version={pjson.version}>
          <AppWithAppState />
        </NetworkStateProvider>
      </AppStateProvider>
    </div>
  );
}

export default App;
