import { ContractCall, useContractCalls } from '@usedapp/core';
import { Falsy } from '@usedapp/core/dist/esm/src/model/types';
import { Interface } from 'ethers/lib/utils';
import { useMemo } from 'react';
import { ERC20Abi } from './ERC20Abi';

export interface ERC20Details {
  symbol: string;
  name: string;
  decimals: number;
  address: string;
}

const exceptions = [null, '0xETH', '0xUSD'];

const getExceptionDetails = (addr: string): ERC20Details => {
  switch (addr) {
    case '0xETH': {
      return {
        symbol: 'ETH',
        name: 'Ethereum',
        decimals: 18,
        address: addr,
      };
    }
    case '0xUSD': {
      return {
        symbol: 'USD',
        name: 'US Dollar',
        decimals: 8,
        address: addr,
      };
    }
    default: {
      return {
        symbol: undefined,
        name: undefined,
        decimals: undefined,
        address: undefined,
      };
    }
  }
};

const isException = (addr: string) => exceptions.indexOf(addr) !== -1;

export const useERC20Details = (erc20Address: string): ERC20Details => {
  const [symbol, name, decimals] = useContractCalls([
    isException(erc20Address) || erc20Address === undefined
      ? false
      : {
          address: erc20Address,
          abi: new Interface(ERC20Abi),
          method: 'symbol',
          args: [],
        },
    isException(erc20Address) || erc20Address === undefined
      ? false
      : {
          address: erc20Address,
          abi: new Interface(ERC20Abi),
          method: 'name',
          args: [],
        },
    isException(erc20Address) || erc20Address === undefined
      ? false
      : {
          address: erc20Address,
          abi: new Interface(ERC20Abi),
          method: 'decimals',
          args: [],
        },
  ]);

  if (isException(erc20Address)) {
    return getExceptionDetails(erc20Address);
  } else {
    return {
      symbol: symbol ? symbol[0] : undefined,
      name: name ? name[0] : undefined,
      decimals: decimals ? decimals[0] : undefined,
      address: erc20Address,
    };
  }
};

export const useERC20sDetails = (erc20Addresses: string[]): ERC20Details[] => {
  const results = useContractCalls([
    ...(erc20Addresses
      .map((token: string) => [
        isException(token)
          ? false
          : {
              address: token,
              abi: new Interface(ERC20Abi),
              method: 'symbol',
              args: [],
            },
        isException(token)
          ? false
          : {
              address: token,
              abi: new Interface(ERC20Abi),
              method: 'name',
              args: [],
            },

        isException(token)
          ? false
          : {
              address: token,
              abi: new Interface(ERC20Abi),
              method: 'decimals',
              args: [],
            },
      ])
      .reduce((a, b) => a.concat(b), []) as (ContractCall | Falsy)[]),
  ]);

  return useMemo(
    () =>
      erc20Addresses.map((addr: string, idx: number) =>
        isException(addr)
          ? getExceptionDetails(addr)
          : {
              symbol: results[idx * 3] ? results[idx * 3][0] : undefined,
              name: results[idx * 3 + 1] ? results[idx * 3 + 1][0] : undefined,
              decimals: results[idx * 3 + 2] ? results[idx * 3 + 2][0] : undefined,
              address: addr,
            }
      ),
    [results, erc20Addresses]
  );
};
