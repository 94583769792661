const addZ = (c: number) => (c < 16 ? `0${c.toString(16)}` : c.toString(16));

const clear = (color: string, strength: number) => {
  const r = Math.floor(parseInt(color.slice(1, 3), 16) * strength + 255 * (1 - strength));
  const g = Math.floor(parseInt(color.slice(3, 5), 16) * strength + 255 * (1 - strength));
  const b = Math.floor(parseInt(color.slice(5, 7), 16) * strength + 255 * (1 - strength));
  return `#${addZ(r)}${addZ(g)}${addZ(b)}`;
};

export const glassmorphism = (backgroundColor: string, fallbackBackgroundColor: string, strength = 0.05) => `
background-color: ${clear(backgroundColor, strength)};
@supports ((-webkit-backdrop-filter: blur(8px)) or (backdrop-filter: blur(8px))) {
    background-color: ${backgroundColor};
    backdrop-filter: blur(8px);
}
`;
