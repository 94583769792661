import { PlatformIcon } from '../PlatformIcon';
import { styled, Theme } from '../ThemeProvider';
import { CartItem, CartStateContext, ParamError } from './CartStateProvider';
import PlatformIcons from '../../assets/platforms';
import { useERC20Details } from '../../ethereum/useERC20Details';
import { Weighted } from '../Weighted';
import { BigNumber } from '@ethersproject/bignumber';
import Decimal from 'decimal.js';
import { OptionAmount, OptionAsset, OptionExpiry } from '@tbd/sdk';
import { Col, DatePicker, InputNumber, Row, Slider } from 'antd';
import { OptionStrike } from '@tbd/sdk';
import { Moment } from 'moment';
import { useMemo, useState, useContext, useCallback, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTheme } from 'styled-components';
import { UpOutlined, ShoppingCartOutlined, CloseOutlined } from '@ant-design/icons';
import { useArtifacts } from '../../ethereum/useArtifacts';
import MediaQuery from 'react-responsive';

const CartItemDiv = styled.div`
  position: relative;
  background-color: #00000066;
  min-height: 100px;
  width: 100%;
  border-radius: 6px;
  margin: 8px;
`;

const CartItemHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1223px) {
    flex-direction: column;
  }

  @media (min-width: 1224px) {
    flex-direction: row;
    height: 100px;
  }
`;

const Separator = styled.div`
  background-color: #ffffff66;
  height: 2px;
  width: 70%;
  margin-left: 15%;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const DataRow = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
`;

const WhiteText = styled.span`
  color: #ffffff;
  font-size: 18px;
`;
const ErrorText = styled.span`
  text-transform: none;
  color: ${(props) => props.theme.error};
  font-size: 14px;
`;

const BNToString = (bn: BigNumber, decimals: number): string =>
  (Math.floor(new Decimal(bn.toString()).div(`1e${decimals - 2}`).toNumber()) / 100).toLocaleString();

const ExpiryPickContainer = styled.div`
  padding: 12px;
  @media (max-width: 1223px) {
    width: 100%;
  }

  @media (min-width: 1224px) {
    width: 50%;
  }
`;

const ExpiryPick = ({
  error,
  expiry,
  currentExpiry,
  setExpiry,
}: {
  error: ParamError;
  expiry: OptionExpiry;
  currentExpiry: Date;
  setExpiry: (expiry: Date) => void;
}) => {
  const start = useMemo(() => Math.floor((expiry[0] as Date).getTime() / 1000), [expiry]);
  const end = useMemo(() => Math.floor((expiry[1] as Date).getTime() / 1000), [expiry]);
  const theme = useTheme() as Theme;

  return (
    <ExpiryPickContainer>
      <WhiteText style={{ textTransform: 'uppercase', color: currentExpiry !== null ? 'white' : theme.error }}>
        Expiry{error ? <ErrorText> {error.error}</ErrorText> : null}
      </WhiteText>
      <div
        style={{
          marginTop: 4,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: 24,
          backgroundColor: '#ffffff11',
          borderRadius: 6,
          minHeight: 100,
        }}
      >
        <DatePicker
          onChange={(value: Moment) => {
            setExpiry(value ? value.toDate() : null);
          }}
          style={{ width: '100%' }}
          disabledDate={(time: Moment) => time.unix() <= start || time.unix() >= end}
          value={currentExpiry ? moment(currentExpiry) : null}
        />
      </div>
    </ExpiryPickContainer>
  );
};

const StyledSlider = styled(Slider)`
  & .ant-slider-mark-text {
    color: white;
  }
`;

const AmountPickContainer = styled.div`
  padding: 12px;
  @media (max-width: 1223px) {
    width: 100%;
  }

  @media (min-width: 1224px) {
    width: 50%;
  }
`;

const AmountPick = ({
  error,
  underlyingAsset,
  amount,
  idx,
}: {
  error: ParamError;
  underlyingAsset: OptionAsset;
  amount: OptionAmount;
  idx: number;
}) => {
  const underlyingAssetDetails = useERC20Details(underlyingAsset);
  const [cartState, dispatch] = useContext(CartStateContext);
  const cartItem = cartState.items[idx];
  const theme = useTheme() as Theme;

  const setAmount = useCallback(
    (value: BigNumber) => {
      dispatch({
        type: 'setAmount',
        item: idx,
        value,
      });
    },
    [dispatch, idx]
  );

  const max = useMemo(() => {
    if (!underlyingAssetDetails.decimals) {
      return 0;
    }
    return new Decimal((amount as BigNumber).toString()).div(`1e${underlyingAssetDetails.decimals}`).toNumber();
  }, [amount, underlyingAssetDetails]);

  return (
    <AmountPickContainer>
      <WhiteText style={{ textTransform: 'uppercase', color: cartItem.amount !== null ? 'white' : theme.error }}>
        Amount{error ? <ErrorText> {error.error}</ErrorText> : null}
      </WhiteText>
      <div
        style={{
          marginTop: 4,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 24,
          backgroundColor: '#ffffff11',
          borderRadius: 6,
          minHeight: 100,
        }}
      >
        <MediaQuery minWidth={1024}>
          <InputNumber
            max={max}
            min={0}
            style={{ width: '100%' }}
            value={
              cartItem.amount && underlyingAssetDetails.decimals
                ? cartItem.amount.div(`1${'0'.repeat(underlyingAssetDetails.decimals - 2)}`).toNumber() / 100
                : null
            }
            onChange={(value) => {
              setAmount(
                BigNumber.from(Math.floor(value * 100)).mul(`1${'0'.repeat(underlyingAssetDetails.decimals - 2)}`)
              );
            }}
          />
        </MediaQuery>
        <MediaQuery maxWidth={1023}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <InputNumber
              max={max}
              min={0}
              style={{ width: '100%' }}
              value={
                cartItem.amount && underlyingAssetDetails.decimals
                  ? cartItem.amount.div(`1${'0'.repeat(underlyingAssetDetails.decimals - 2)}`).toNumber() / 100
                  : null
              }
              onChange={(value) => {
                setAmount(
                  BigNumber.from(Math.floor(value * 100)).mul(`1${'0'.repeat(underlyingAssetDetails.decimals - 2)}`)
                );
              }}
            />
            <WhiteText
              style={{
                fontSize: 14,
                opacity: 0.5,
                marginTop: 6,
                marginBottom: -18,
              }}
            >
              Available: ${underlyingAssetDetails.symbol} {max.toFixed(2)}
            </WhiteText>
          </div>
        </MediaQuery>
      </div>
    </AmountPickContainer>
  );
};

const StrikePick = ({
  error,
  strikeDecimals,
  strikeAsset,
  underlyingAsset,
  strike,
  setStrike,
  currentStrike,
  pricings,
}: {
  error: ParamError;
  pricings: { [key: string]: [number, number] };
  strikeDecimals: number;
  underlyingAsset: OptionAsset;
  strikeAsset: OptionAsset;
  strike: OptionStrike;
  setStrike: (strike: BigNumber) => void;
  currentStrike: BigNumber;
}) => {
  const strikeAssetDetails = useERC20Details(strikeAsset);
  const underlyingAssetDetails = useERC20Details(underlyingAsset);
  const [decimals, price] = underlyingAssetDetails ? pricings[underlyingAssetDetails.address] : [null, null];
  const theme = useTheme() as Theme;

  const [min, max] = useMemo(() => {
    if (!strikeAssetDetails.decimals) {
      return null;
    }
    return [
      (strike[0] as BigNumber).div(`1${'0'.repeat(strikeAssetDetails.decimals)}`).toNumber(),
      (strike[1] as BigNumber).div(`1${'0'.repeat(strikeAssetDetails.decimals)}`).toNumber(),
    ];
  }, [strike, strikeAssetDetails]);

  const [localStrike, setLocalStrike] = useState(null);

  useEffect(() => {
    if (localStrike === null && price !== null) {
      setLocalStrike(Math.floor(price));
    }
  }, [localStrike, price]);

  useEffect(() => {
    const tid = setTimeout(() => {
      if (localStrike !== null) {
        setStrike(BigNumber.from(localStrike).mul(`1${'0'.repeat(strikeDecimals)}`));
      }
    }, 150);
    return () => {
      clearTimeout(tid);
    };
  }, [localStrike, setStrike, strikeDecimals]);

  return (
    <div
      style={{
        width: '100%',
        padding: 12,
      }}
    >
      <WhiteText style={{ textTransform: 'uppercase', color: localStrike !== null ? 'white' : theme.error }}>
        Strike{error ? <ErrorText> {error.error}</ErrorText> : null}
      </WhiteText>
      <div
        style={{
          marginTop: 4,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 24,
          backgroundColor: '#ffffff11',
          borderRadius: 6,
          minHeight: 100,
        }}
      >
        <MediaQuery maxWidth={1023}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <InputNumber
              min={min}
              max={max}
              style={{ width: '100%' }}
              onChange={(value: number) => setLocalStrike(value)}
              value={localStrike}
            />
            <WhiteText
              style={{
                fontSize: 14,
                opacity: 0.5,
                marginTop: 6,
                marginBottom: -18,
              }}
            >
              Current price: {price.toFixed(0)}
            </WhiteText>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <StyledSlider
            marks={
              !_.isNil(min) && !_.isNil(max)
                ? {
                    [min]: min.toLocaleString(),
                    [max]: max.toLocaleString(),
                    ...(price !== null
                      ? {
                          [Math.floor(price)]: Math.floor(price).toLocaleString(),
                        }
                      : {}),
                  }
                : undefined
            }
            style={{
              width: '75%',
            }}
            min={min}
            max={max}
            onChange={setLocalStrike}
            value={localStrike}
          />
          <InputNumber
            min={min}
            max={max}
            style={{ marginLeft: '24px', width: '25%' }}
            onChange={(value: number) => setLocalStrike(value)}
            value={localStrike}
          />
        </MediaQuery>
      </div>
    </div>
  );
};

const ConfigurationSection = ({
  idx,
  configIdx,
  pricings,
}: {
  idx: number;
  configIdx: number;
  pricings: { [key: string]: [number, number] };
}) => {
  const [cartState, dispatch] = useContext(CartStateContext);
  const cartItem = cartState.items[idx];

  const setParameter = useCallback(
    (value: unknown) => {
      dispatch({
        type: 'setParameter',
        item: idx,
        idx: configIdx,
        value,
      });
    },
    [configIdx, dispatch, idx]
  );

  if (configIdx >= cartItem.requiredParams.length) {
    return null;
  }
  switch (cartItem.requiredParams[configIdx]) {
    case 'expiry': {
      return (
        <ExpiryPick
          error={cartItem.paramErrors[configIdx]}
          expiry={cartItem.product.expiry}
          setExpiry={setParameter}
          currentExpiry={cartItem.providedParams[configIdx] as Date}
        />
      );
    }
    case 'strike': {
      return (
        <StrikePick
          error={cartItem.paramErrors[configIdx]}
          pricings={pricings}
          strikeDecimals={cartItem.product.strikeDecimals}
          underlyingAsset={cartItem.product.underlyingAsset}
          strikeAsset={cartItem.product.strikeAsset}
          strike={cartItem.product.strike}
          setStrike={setParameter}
          currentStrike={cartItem.providedParams[configIdx] as BigNumber}
        />
      );
    }
  }
};

const PickersDiv = styled.div`
  & .ant-input-number {
    border: none;
    border-radius: 3px;
    background: none;

    input {
      font-size: 18px;
      color: white;
      background-color: #00000044;
      border-radius: 3px;
    }
  }

  & .ant-picker {
    background: none;
    background-color: #00000044;
    padding: 12px;
    border: none;

    .ant-picker-suffix {
      color: white;
    }

    input {
      color: white;
    }
  }

  & .ant-picker-clear {
    background: #272727;
  }
`;

const NatureToText = {
  EU: 'European 🇪🇺',
  US: 'American 🇺🇸',
};

const getConfigurationWarningOrError = (item: CartItem): string => {
  const activeParamErrors: [ParamError, number][] = item.paramErrors
    .map((e, idx): [ParamError, number] => [e, idx])
    .filter((pe) => !!pe[0]);
  if (activeParamErrors.length > 0) {
    const activeParamFields = activeParamErrors.map((v) => item.requiredParams[v[1]]);
    return `Errors on ${activeParamFields.join(',')}`;
  }

  if (item.amountError) {
    return item.amountError.error;
  }

  const unfilledParams: number[] = item.providedParams
    .map((e, idx): [unknown, number] => [e, idx])
    .filter((p) => !p[0])
    .map((p) => p[1]);
  if (unfilledParams.length > 0 || item.amount === null) {
    const affectedFields: string[] = unfilledParams.map((idx) => item.requiredParams[idx]);
    if (item.amount === null) {
      affectedFields.push('amount');
    }
    return `Missing ${affectedFields.join(', ')}`;
  }

  return 'Ready for purchase';
};

const getConfigurationTitle = (item: CartItem): [string, boolean] => {
  const activeParamErrors: [ParamError, number][] = item.paramErrors
    .map((e, idx): [ParamError, number] => [e, idx])
    .filter((pe) => !!pe[0]);
  const unfilledParams: number[] = item.providedParams
    .map((e, idx): [unknown, number] => [e, idx])
    .filter((p) => !p[0])
    .map((p) => p[1]);
  if (item.amountError || activeParamErrors.length > 0) {
    return [`Error`, false];
  }
  if (unfilledParams.length || item.amount === null) {
    return [`Configuration required`, false];
  }
  return [`Ready`, true];
};

const RemoveButtonContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #00000066;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;

  &:hover {
    color: ${(props) => props.theme.error};
  }
  transition: all 200ms ease-in-out;
`;

const RemoveButtonIcon = styled(CloseOutlined)`
  color: inherit;
  font-size: 17px;
`;

const RemoveButton = ({ idx }: { idx: number }) => {
  const [, dispatch] = useContext(CartStateContext);
  return (
    <RemoveButtonContainer>
      <RemoveButtonIcon
        onClick={() => {
          dispatch({
            type: 'removeCartItem',
            item: idx,
          });
        }}
      />
    </RemoveButtonContainer>
  );
};

const CostLineWhiteText = styled(WhiteText)`
  @media (max-width: 1223px) {
    font-size: 14px;
  }
`;

const CartItemCostLine = ({
  description,
  currency,
  amount,
}: {
  description: string;
  currency: string;
  amount: BigNumber;
}) => {
  const costAssetDetails = useERC20Details(currency);

  if (!costAssetDetails) {
    return <WhiteText>LOADING</WhiteText>;
  }
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <CostLineWhiteText>{description}</CostLineWhiteText>{' '}
      <CostLineWhiteText>
        <Weighted weight="900">
          {!_.isNil(costAssetDetails?.decimals)
            ? new Decimal(amount.toString())
                .div(`1e${costAssetDetails.decimals}`)
                .toFixed(amount.eq(0) ? 0 : Math.max(3, costAssetDetails.decimals - amount.toString().length))
            : '...'}{' '}
          ${costAssetDetails.symbol}
        </Weighted>
      </CostLineWhiteText>
    </div>
  );
};

const addZ = (v: number): string => (v < 10 ? `0${v}` : v.toString());

const CostLineContainer = styled.div`
  @media (max-width: 1223px) {
    width: 90%;
  }

  @media (min-width: 1224px) {
    width: 65%;
  }
`;

export const CartItemComponent = ({
  idx,
  pricings,
}: {
  idx: number;
  pricings: { [key: string]: [number, number] };
}) => {
  const [cartState, dispatch] = useContext(CartStateContext);
  const cartItem = cartState.items[idx];
  const underlyingAssetDetails = useERC20Details(cartItem.product.underlyingAsset);
  const strikeAssetDetails = useERC20Details(cartItem.product.strikeAsset);
  const premiumAssetDetails = useERC20Details(cartItem.product.premiumAsset);
  const [configTitle, configStatus] = getConfigurationTitle(cartItem);
  const theme = useTheme() as Theme;

  return (
    <CartItemDiv>
      <RemoveButton idx={idx} />
      <CartItemHeaderContainer>
        <MediaQuery maxWidth={1223}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PlatformIcon src={PlatformIcons[cartItem.product.platform]} />
            <DataRow>
              <WhiteText>
                <Weighted weight={'900'}>{underlyingAssetDetails.symbol}</Weighted> / {strikeAssetDetails.symbol}
              </WhiteText>
              <WhiteText style={{ fontSize: 14 }}>
                {underlyingAssetDetails.decimals
                  ? BNToString(cartItem.product.amount as BigNumber, underlyingAssetDetails.decimals)
                  : '...'}{' '}
                {underlyingAssetDetails.symbol} available
              </WhiteText>
            </DataRow>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1224}>
          <PlatformIcon src={PlatformIcons[cartItem.product.platform]} />
          <DataRow>
            <WhiteText>
              <Weighted weight={'900'}>{underlyingAssetDetails.symbol}</Weighted> / {strikeAssetDetails.symbol}
            </WhiteText>
            <WhiteText style={{ fontSize: 14 }}>
              {underlyingAssetDetails.decimals
                ? BNToString(cartItem.product.amount as BigNumber, underlyingAssetDetails.decimals)
                : '...'}{' '}
              {underlyingAssetDetails.symbol} available
            </WhiteText>
          </DataRow>
        </MediaQuery>
        <WhiteText>
          {!_.isArray(cartItem.product.strike)
            ? cartItem.product.strike.div(`1${'0'.repeat(cartItem.product.strikeDecimals)}`).toString() + ' '
            : ''}
          {!_.isArray(cartItem.product.expiry)
            ? `${addZ(cartItem.product.expiry.getDate())}/${addZ(
                cartItem.product.expiry.getMonth() + 1
              )}/${cartItem.product.expiry.getFullYear()} `
            : ''}
          {cartItem.product.type} {cartItem.product.nature === 'EU' ? '🇪🇺' : '🇺🇸'}
        </WhiteText>
        <MediaQuery minWidth={1224}>
          <DataRow>
            <WhiteText style={{ color: configStatus ? 'white' : theme.error }}>
              <Weighted weight={'900'}>{configTitle}</Weighted>
            </WhiteText>
            <WhiteText style={{ fontSize: 14 }}>{getConfigurationWarningOrError(cartItem)}</WhiteText>
          </DataRow>
        </MediaQuery>
      </CartItemHeaderContainer>
      <Separator />
      <PickersDiv
        style={{
          padding: 12,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
          flexWrap: 'wrap',
        }}
      >
        {cartItem.requiredParams.map((_, configIdx) => (
          <ConfigurationSection idx={idx} configIdx={configIdx} key={configIdx} pricings={pricings} />
        ))}
        <AmountPick
          error={cartItem.amountError}
          underlyingAsset={cartItem.product.underlyingAsset}
          amount={cartItem.product.amount}
          idx={idx}
        />
      </PickersDiv>
      <Separator />
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {cartItem.totalCostLoading ? (
          <WhiteText>Loading</WhiteText>
        ) : cartItem.totalCosts !== null && !cartItem.totalCostLoading && !cartItem.amountError ? (
          <CostLineContainer>
            {cartItem.totalCosts.map((v: BigNumber, idx: number) => (
              <CartItemCostLine
                description={cartItem.costDescriptions[idx]}
                currency={cartItem.costCurrencies[idx]}
                amount={v}
                key={idx}
              />
            ))}
          </CostLineContainer>
        ) : (
          <WhiteText>Configuration required</WhiteText>
        )}
      </div>
      <Separator />
      <br />
    </CartItemDiv>
  );
};
