import React, { Dispatch } from 'react';
import { useReducer, useContext, useEffect } from 'react';

export type Network =
  | 'ethereum'
  | 'ethereum_fork'
  | 'arbitrum'
  | 'arbitrum_fork'
  | 'optimism'
  | 'optimism_fork'
  | 'kovan'
  | 'kovan_fork';
export const NetworkToNetworkId = {
  ethereum: 1,
  ethereum_fork: 1,
  arbitrum: 42161,
  arbitrum_fork: 42161,
  optimism: 10,
  optimism_fork: 10,
  kovan: 42,
  kovan_fork: 42,
};
export type Gas = 'slow' | 'regular' | 'fast';

export interface NetworkState {
  network: Network;
}

export type NetworkStateReducer = (state: NetworkState, _action: Action) => NetworkState;

const defaultNetworkState: NetworkState = {
  network: 'ethereum',
};

export interface SetNetworkAction {
  type: 'SetNetwork';
  network: Network;
}

export type Action = SetNetworkAction;

const appStateReducer: NetworkStateReducer = (state: NetworkState, _action: Action): NetworkState => {
  if (!_action || !_action.type) {
    return state;
  }

  switch (_action.type) {
    case 'SetNetwork': {
      const action: SetNetworkAction = _action;
      return {
        ...state,
        network: action.network,
      };
    }
    default: {
      return state;
    }
  }
};

export const NetworkStateContext = React.createContext<[NetworkState, Dispatch<Action>]>([
  defaultNetworkState,
  () => ({}),
]);

const getFromStorage = (version: string): NetworkState => {
  const storedState = localStorage.getItem(`network-state-${version}`);
  if (storedState) {
    try {
      return JSON.parse(storedState);
    } catch (e) {
      //
    }
  }
  return defaultNetworkState;
};

export const NetworkStateProvider: React.FC<React.PropsWithChildren<{ version: string }>> = ({
  children,
  version,
}: React.PropsWithChildren<{ version: string }>): JSX.Element => {
  const [appState, reducer] = useReducer(appStateReducer, getFromStorage(version));

  useEffect(() => {
    localStorage.setItem(`network-state-${version}`, JSON.stringify(appState));
  }, [appState, version]);

  return <NetworkStateContext.Provider value={[appState, reducer]}>{children}</NetworkStateContext.Provider>;
};

export const useNetworkState = (): [NetworkState, Dispatch<Action>] => {
  return useContext(NetworkStateContext);
};
