import { useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { Cart, CartItem, CartStateContext } from './CartStateProvider';
import { styled, Theme } from '../ThemeProvider';
import {
  useContractCall,
  useContractCalls,
  useEtherBalance,
  useEthers,
  useTokenAllowance,
  useTokenBalance,
  useTransactions,
} from '@usedapp/core';
import { Weighted } from '../Weighted';
import { NetworkState, NetworkStateContext, NetworkToNetworkId } from '../NetworkProvider';
import _ from 'lodash';
import { CartItemComponent } from './CartItemComponent';
import { OptionsContext, useTBD } from '@tbd/react';
import { useEthereumPricings } from '../../ethereum/useEthereumPricing';
import { Badge, Button, Modal, notification } from 'antd';
import { BigNumber, constants, ethers } from 'ethers';
import { useERC20Details, ERC20Details } from '../../ethereum/useERC20Details';
import Decimal from 'decimal.js';
import { useTheme, createGlobalStyle } from 'styled-components';
import { useArtifacts, ContractArtifact } from '../../ethereum/useArtifacts';
import { ERC20Abi } from '../../ethereum/ERC20Abi';
import { Contract } from '@usedapp/core/node_modules/ethers';
import { OptionConfiguration, ETH_ADDRESS, GatewayAction } from '@tbd/sdk';
import { useContractFunction__fix } from '../../fixes/useContractFunction__fix';
import { useAppState } from '../AppStateProvider';
import MediaQuery from 'react-responsive';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { OptionPositionsContext } from '@tbd/react';
import { useHistory } from 'react-router-dom';
import { glassmorphism } from '../../utils/glassmorphism';
import { WalletModalContext } from '../WalletModalProvider';

const ModalContainer = styled.div<{ open: boolean }>`
  @media (max-width: 1223px) {
    width: 100vw;
    top: 0;
    height: calc(100vh - 70px - env(safe-area-inset-bottom));
    right: ${(props) => (props.open ? '0' : '-100vw')};
  }

  @media (min-width: 1224px) {
    width: 800px;
    top: 80px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    height: calc(100vh - 80px - 40px);
    right: ${(props) => (props.open ? '0' : '-800px')};
  }

  ${(props) => glassmorphism('#000000cc', '#000000cc', 0.9)}
  position: fixed;
  z-index: 20;
  transition: all 400ms ease-in-out;
`;

const ConnectAccountDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConnectAccountText = styled.span`
  color: ${(props) => props.theme.bg};
  font-size: 16px;
  font-weight: 200;
  text-transform: uppercase;
`;

const InvalidNetworkDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InvalidNetworkText = styled.span`
  color: ${(props) => props.theme.bg};
  font-size: 20px;
  font-weight: 200;
`;

const CartItemContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;

  @media (max-width: 1223px) {
    padding: 32px;
    padding-top: 0;
  }

  @media (min-width: 1224px) {
    padding: 32px;
  }
`;

const WhiteText = styled.span`
  color: #ffffff;
  font-size: 18px;
`;

const Separator = styled.div`
  background-color: #ffffff66;
  height: 2px;
  margin-top: 12px;
  margin-bottom: 12px;
  @media (max-width: 1223px) {
    width: 100%;
  }

  @media (min-width: 1224px) {
    width: 75%;
  }
`;

interface SummedToken {
  token: string;
  amount: BigNumber;
}

const getSummedTokens = (cartState: Cart): SummedToken[] => {
  const ret: { [key: string]: SummedToken } = {};

  for (const item of cartState.items) {
    if (item.totalCosts && item.valid) {
      for (let idx = 0; idx < item.totalCosts.length; ++idx) {
        const totalCost = item.totalCosts[idx];
        const currency = item.costCurrencies[idx];

        if (!ret[currency]) {
          ret[currency] = {
            token: currency,
            amount: BigNumber.from(0),
          };
        }

        ret[currency].amount = ret[currency].amount.add(totalCost);
      }
    }
  }

  return Object.keys(ret)
    .sort()
    .map((k) => ret[k]);
};

const requiresApproval = (token: string): boolean => {
  switch (token) {
    case '0xETH':
      return false;
    default:
      return true;
  }
};

const isERC20 = (token: string): boolean => {
  switch (token) {
    case '0xETH':
      return false;
    default:
      return true;
  }
};

const ApproveButton = ({ asset }: { asset: ERC20Details }) => {
  const artifacts = useArtifacts();
  const erc20Contract = useMemo(() => {
    return new Contract(asset.address, ERC20Abi);
  }, [asset.address]);
  const { state, send } = useContractFunction__fix(erc20Contract, 'approve', {
    transactionName: `Approve spending $${asset.symbol}`,
  });

  return (
    <Button
      onClick={() => send(artifacts.Gateway.address, ethers.constants.MaxUint256)}
      loading={['Mining', 'Success'].includes(state.status)}
    >
      Approve
    </Button>
  );
};

const TokenDisplay = ({
  summedToken,
  setReady,
}: {
  summedToken: SummedToken;
  setReady: (v: boolean) => void;
  ready: boolean;
}) => {
  const theme = useTheme() as Theme;
  const { account } = useEthers();
  const artifacts = useArtifacts();
  const assetDetails = useERC20Details(summedToken.token);
  const requiresApprovalForToken = useMemo(() => requiresApproval(summedToken.token), [summedToken.token]);
  const isTokenERC20 = useMemo(() => isERC20(summedToken.token), [summedToken.token]);
  const assetAllowance = useTokenAllowance(
    requiresApprovalForToken ? summedToken.token : false,
    requiresApprovalForToken ? account : false,
    requiresApprovalForToken ? artifacts.Gateway.address : false
  );
  const ERC20AssetBalance = useTokenBalance(isTokenERC20 ? summedToken.token : false, isTokenERC20 ? account : false);
  const nativeAssetBalance = useEtherBalance(!isTokenERC20 ? account : false);
  const balance = useMemo(() => ERC20AssetBalance || nativeAssetBalance, [ERC20AssetBalance, nativeAssetBalance]);

  const ready = useMemo(() => {
    if (requiresApprovalForToken) {
      if (
        ERC20AssetBalance &&
        assetAllowance &&
        ERC20AssetBalance.gte(summedToken.amount) &&
        assetAllowance.gte(summedToken.amount)
      ) {
        return true;
      }
    } else {
      if (nativeAssetBalance && nativeAssetBalance.gte(summedToken.amount)) {
        return true;
      }
    }
    return false;
  }, [ERC20AssetBalance, assetAllowance, nativeAssetBalance, requiresApprovalForToken, summedToken.amount]);

  useEffect(() => {
    setReady(ready);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#00000033',
        padding: '12px 24px',
        borderRadius: 6,
      }}
    >
      {assetDetails ? (
        <>
          <WhiteText
            style={{
              fontSize: 20,
            }}
          >
            {!_.isNil(assetDetails?.decimals)
              ? new Decimal(summedToken.amount.toString())
                  .div(`1e${assetDetails.decimals}`)
                  .toFixed(
                    summedToken.amount.eq(0)
                      ? 0
                      : Math.max(3, assetDetails.decimals - summedToken.amount.toString().length)
                  )
              : '...'}{' '}
            <Weighted weight={'900'}>${assetDetails.symbol}</Weighted>
          </WhiteText>
          {balance ? (
            balance.gte(summedToken.amount) ? (
              requiresApprovalForToken ? (
                assetAllowance !== undefined ? (
                  assetAllowance.gte(summedToken.amount) ? (
                    <WhiteText style={{ fontSize: 14 }}>Approved</WhiteText>
                  ) : (
                    <ApproveButton asset={assetDetails} />
                  )
                ) : null
              ) : null
            ) : (
              <WhiteText style={{ fontSize: 14, color: theme.error }}>Insufficient funds</WhiteText>
            )
          ) : null}
        </>
      ) : null}
    </div>
  );
};

const PurchaseButton = ({
  gateway,
  readyState,
}: {
  gateway: ContractArtifact;
  readyState: boolean[];
  cartState: Cart;
}) => {
  const { account, chainId } = useEthers();
  const tbd = useTBD();
  const { send, state } = useContractFunction__fix(gateway.instance as unknown as Contract, 'execute', {
    transactionName: 'Purchase Options',
  });
  const [gatewayFee] =
    useContractCall({
      address: gateway.address,
      abi: gateway.interface,
      method: 'fee',
      args: [],
    }) || [];
  const [cartState, dispatch] = useContext(CartStateContext);
  const [appState] = useAppState();
  const warnings = useMemo(
    () =>
      cartState.items
        .map((ci: CartItem) => ci.product.warnings || [])
        .reduce((agg: string[], curr: string[]) => agg.concat(curr), [])
        .filter((v, i, a) => a.indexOf(v) === i),
    [cartState]
  );
  const history = useHistory();
  const refreshPositions = useContext(OptionPositionsContext)[1];

  const onPurchase = useCallback(() => {
    tbd
      .retrieveGatewayActions(
        account,
        gatewayFee,
        appState.gasSettings,
        cartState.items.map(
          (cartItem: CartItem): OptionConfiguration => ({
            option: cartItem.product,
            amount: cartItem.amount,
            providedParams: cartItem.providedParams,
            requiredParams: cartItem.requiredParams,
          })
        )
      )
      .then((gas: GatewayAction[]) => {
        let ethAmount = ethers.BigNumber.from(0);
        const encodedActions: unknown[] = [];
        for (const ga of gas) {
          const currencies = [];
          for (let idx = 0; idx < ga.currencies.length; ++idx) {
            const currency = ga.currencies[idx];
            if (currency === ETH_ADDRESS) {
              ethAmount = ethAmount.add(ga.amounts[idx]);
              currencies.push('0x0000000000000000000000000000000000000000');
            } else {
              currencies.push(currency);
            }
          }
          encodedActions.push([ga.actionType, currencies, ga.amounts, ga.data]);
        }
        if (appState.gasSettings.gasPrice) {
          send(encodedActions, {
            value: ethAmount,
            gasPrice: appState.gasSettings.gasPrice,
          }).catch((e) => console.warn(e));
        } else if (appState.gasSettings.maxFeePerGas) {
          send(encodedActions, {
            value: ethAmount,
            maxFeePerGas: appState.gasSettings.maxFeePerGas,
            maxPriorityFeePerGas: appState.gasSettings.maxPriorityFeePerGas,
          }).catch((e) => console.warn(e));
        } else {
          send(encodedActions, {
            value: ethAmount,
          }).catch((e) => console.warn(e));
        }
      });
  }, [tbd, account, cartState.items, send, gatewayFee, appState.gasSettings]);

  const onWarning = useCallback(() => {
    Modal.warning({
      style: {
        minWidth: '50vw',
      },
      title: <span style={{ textTransform: 'uppercase' }}>Some products have warnings</span>,
      content: (
        <ul
          style={{
            marginTop: 32,
            listStyle: 'square outside',
          }}
        >
          {warnings.map((v, idx) => (
            <li key={idx}>
              <span>{v}</span>
            </li>
          ))}
        </ul>
      ),
      closable: true,
      onOk: onPurchase,
      okText: 'I understand, proceed to checkout',
    });
  }, [warnings, onPurchase]);

  useEffect(() => {
    if (state.status === 'Exception' || state.status === 'Fail') {
      notification.error({ message: state.errorMessage });
    }
  }, [state]);

  useEffect(() => {
    if (state.status === 'Success' && cartState.items.length) {
      dispatch({
        type: 'clearCart',
      });
      refreshPositions();
      history.push('/positions');
    }
  }, [state, dispatch, cartState, history, refreshPositions]);

  return (
    <Button
      type={'primary'}
      style={{
        width: '33%',
      }}
      disabled={readyState.length === 0 || readyState.includes(false) || gatewayFee === undefined}
      loading={state && ['Mining'].includes(state.status)}
      onClick={warnings.length > 0 ? onWarning : onPurchase}
    >
      Purchase
    </Button>
  );
};

const SubtotalContainer = styled.div`
  @media (max-width: 1223px) {
    width: 100%;
  }

  @media (min-width: 1224px) {
    width: 75%;
  }
`;

const SummedTokenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1223px) {
    width: 100%;
  }

  @media (min-width: 1224px) {
    width: 75%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1223px) {
    width: 100%;
    margin-bottom: 32px;
  }

  @media (min-width: 1224px) {
    width: 75%;
  }
`;

const CartPriceSummaryContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 25px;

  @media (max-width: 1223px) {
    margin-bottom: 100px;
  }

  @media (min-width: 1224px) {
    margin-bottom: 25px;
  }
`;

const CartCTA = ({ cartState }: { cartState: Cart }) => {
  const summedTokens = getSummedTokens(cartState);
  const [readyState, setReadyState] = useState(summedTokens.map((v) => false));
  const loadingPrices = useMemo(() => cartState.items.map((ci) => ci.totalCostLoading).includes(true), [cartState]);
  const artifacts = useArtifacts();

  useEffect(() => {
    if (readyState.length !== summedTokens.length) {
      setReadyState(summedTokens.map((v) => false));
    }
  }, [readyState, summedTokens]);

  if (loadingPrices) {
    return (
      <CartPriceSummaryContainer>
        <WhiteText style={{ textTransform: 'uppercase', fontSize: 22 }}>Loading</WhiteText>
      </CartPriceSummaryContainer>
    );
  }

  if (cartState.valid && readyState.length > 0) {
    return (
      <CartPriceSummaryContainer>
        <SubtotalContainer>
          <WhiteText style={{ textTransform: 'uppercase', fontSize: 22 }}>Subtotal</WhiteText>
        </SubtotalContainer>
        <Separator />
        {summedTokens.map((v: SummedToken, idx: number) => (
          <SummedTokenContainer key={idx}>
            {idx > 0 ? (
              <div>
                <WhiteText>+</WhiteText>
              </div>
            ) : null}
            <TokenDisplay
              key={idx}
              summedToken={v}
              ready={readyState[idx]}
              setReady={(v) => {
                readyState[idx] = v;
                setReadyState([...readyState]);
              }}
            />
          </SummedTokenContainer>
        ))}
        <Separator />
        <ButtonContainer>
          {artifacts.Gateway ? (
            <PurchaseButton gateway={artifacts.Gateway} readyState={readyState} cartState={cartState} />
          ) : (
            <Button
              type={'primary'}
              style={{
                width: '33%',
              }}
              disabled={true}
            >
              Purchase
            </Button>
          )}
        </ButtonContainer>
      </CartPriceSummaryContainer>
    );
  } else {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 25,
          marginBottom: 25,
        }}
      >
        <WhiteText>Finish configuring your options</WhiteText>
      </div>
    );
    return null;
  }
};

const CartCTAContainer = styled.div`
  @media (max-width: 1223px) {
    width: 100%;
  }

  @media (min-width: 1224px) {
    width: 80%;
    margin-left: 10%;
  }
`;

const CartCheckNetwork = () => {
  const { chainId } = useEthers();
  const [networkState] = useContext(NetworkStateContext);
  const [cartState, cartDispatch] = useContext(CartStateContext);
  const rawOptions = useContext(OptionsContext);
  const pricings = useEthereumPricings(
    rawOptions
      .map((opt) => opt.premiumAsset)
      .concat(rawOptions.map((opt) => opt.underlyingAsset))
      .concat(rawOptions.map((opt) => opt.strikeAsset))
      .concat(rawOptions.map((opt) => opt.collateralAsset))
      .filter((v: string, idx: number, arr: string[]): boolean => !!v && arr.indexOf(v) === idx)
  );
  const artifacts = useArtifacts();

  if (
    !_.isNil(chainId) &&
    !_.isNil(networkState.network) &&
    NetworkToNetworkId[networkState.network] === chainId &&
    artifacts &&
    artifacts.Gateway
  ) {
    return (
      <CartItemContainer>
        {cartState.items.map((_, idx: number) => (
          <div
            key={idx}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {idx > 0 ? <WhiteText>+</WhiteText> : null}
            <CartItemComponent idx={idx} pricings={pricings} />
          </div>
        ))}
        <CartCTAContainer>
          <br />
          <CartCTA cartState={cartState} />
        </CartCTAContainer>
      </CartItemContainer>
    );
  } else {
    return (
      <InvalidNetworkDiv>
        <InvalidNetworkText>Please switch to the proper network.</InvalidNetworkText>
      </InvalidNetworkDiv>
    );
  }
};

const MobileCloseCart = styled.span`
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  margin-right: 12px;
`;

const MobileCartButton = styled.div<{ show: boolean }>`
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: calc(94px + env(safe-area-inset-bottom));
  right: ${(props) => (props.show ? 24 : -74)}px;
  ${(props) => glassmorphism('#000000', '#000000', 0.9)}
  z-index: 9;
  border-radius: 100%;
  transition: all 200ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FixBody = createGlobalStyle<{ open: boolean }>`
body {
  ${(props) => (props.open ? 'overflow: hidden; height: 100%;' : '')}
}
`;

export const CartModal = () => {
  const [cartState, cartDispatch] = useContext(CartStateContext);
  const { account } = useEthers();
  const [, setActive] = useContext(WalletModalContext);

  return (
    <>
      <FixBody open={cartState.open} />
      <MediaQuery maxWidth={1223}>
        <MobileCartButton
          show={!cartState.open && cartState.items.length > 0}
          onClick={() => cartDispatch({ type: 'openCart' })}
        >
          {cartState.items.length > 0 ? (
            <Badge
              count={cartState.items.length}
              style={{
                position: 'absolute',
                right: -43,
                top: -27,
                boxShadow: '0 0 0 1px #bbbbbb inset',
                backgroundColor: '#bbbbbb',
                color: '#000000',
              }}
            />
          ) : null}
          <ShoppingCartOutlined style={{ color: '#ffffffaa', fontSize: 28 }} />
        </MobileCartButton>
      </MediaQuery>
      <ModalContainer open={cartState.open}>
        <MediaQuery maxWidth={1223}>
          <div
            style={{
              width: '100%',
              height: 50,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              cursor: 'pointer',
            }}
            onClick={() => cartDispatch({ type: 'closeCart' })}
          >
            <MobileCloseCart>CLOSE CART</MobileCloseCart>
          </div>
        </MediaQuery>

        {!account ? (
          <ConnectAccountDiv>
            <ConnectAccountText>
              <Weighted weight={'900'}>
                <Button
                  onClick={() => setActive(true)}
                  type={'primary'}
                  size={'large'}
                  style={{ textTransform: 'uppercase', fontWeight: 900 }}
                >
                  Connect
                </Button>
              </Weighted>{' '}
              your Wallet
            </ConnectAccountText>
          </ConnectAccountDiv>
        ) : (
          <CartCheckNetwork />
        )}
      </ModalContainer>
    </>
  );
};
