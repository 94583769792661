import { PopulatedTransaction, providers } from 'ethers';
import { TBDSDKNetworkBuildError, TBDSDKNetworkConfigurationError } from './errors';
import { Logger, LogLevel } from './logger';
import { DefaultProviderBuilder, ProviderBuilder } from './provider';
import { Platform, PlatformConfiguration, OptionPriceAndAmount } from './platform';
import { FetchOptions, Option, OptionType, FilterOptions, OptionAsset, OptionPosition } from './option';
import { merge, Observable } from 'rxjs';
import { map, scan } from 'rxjs/operators';
import _ from 'lodash';
import { isAddress } from 'ethers/lib/utils';
import { ethers } from 'ethers';
import { GatewayAction } from './gateway';

const tlu = (s: string) => (s && isAddress(s) ? s.toLowerCase() : s);

interface NetworkConfiguration {
  rpc?: string;
  provider?: providers.BaseProvider;
  wr?: boolean;
}

export interface MultiNetworkConfiguration {
  [key: number]: NetworkConfiguration;
}

interface LoadedNetworkProvider {
  provider: providers.BaseProvider;
  wr: boolean;
}

export interface MultiNetworkProviders {
  [key: number]: LoadedNetworkProvider;
}

export interface OptionConfiguration {
  option: Option;
  amount: ethers.BigNumber;
  requiredParams: string[];
  providedParams: unknown[];
}

export type GasSpeed = 'slow' | 'regular' | 'fast' | 'faster';

export interface GasSettings {
  type: 'deprecated' | 'eip1559';
  gasPrice: ethers.BigNumber;
  maxFeePerGas: ethers.BigNumber;
  maxPriorityFeePerGas: ethers.BigNumber;
  speed: GasSpeed;
}

const feeMultipliers = {
  slow: 105,
  regular: 110,
  fast: 115,
  faster: 120,
};

export class TBDSDK {
  public providers: MultiNetworkProviders = {};
  public readonly platforms: { [key: string]: Platform } = {};
  public readonly configByPlatform: { [key: string]: PlatformConfiguration } = {};
  private readonly logger: Logger;

  constructor(
    private readonly mnc: MultiNetworkConfiguration,
    logLevel: LogLevel = LogLevel.OFF,
    private readonly providerBuilder: ProviderBuilder = new DefaultProviderBuilder()
  ) {
    this.logger = new Logger(logLevel);
    this.loadProviders();
  }

  public get platformsList(): string[] {
    return Object.keys(this.platforms);
  }

  public async getGasSettings(chainId: number, speed: GasSpeed): Promise<GasSettings> {
    const provider: providers.BaseProvider = this.providers[chainId].provider;
    if (!provider) {
      throw new Error(`Missing provider for network ${chainId}`);
    }

    const feeData = await provider.getFeeData();
    if (feeData.gasPrice) {
      return {
        type: 'eip1559',
        gasPrice: null,
        maxFeePerGas: feeData.gasPrice.mul(feeMultipliers[speed]).div(100),
        maxPriorityFeePerGas: feeData.gasPrice.mul(feeMultipliers[speed]).div(100),
        speed,
      };
      return {
        type: 'deprecated',
        gasPrice: feeData.gasPrice.mul(feeMultipliers[speed]).div(100),
        maxFeePerGas: null,
        maxPriorityFeePerGas: null,
        speed,
      };
    } else if (feeData.maxFeePerGas && feeData.maxPriorityFeePerGas) {
      return {
        type: 'eip1559',
        gasPrice: null,
        maxFeePerGas: feeData.maxFeePerGas,
        maxPriorityFeePerGas: feeData.maxPriorityFeePerGas,
        speed,
      };
    } else {
      return null;
    }
  }

  public supportsAction(platformName: string, action: string): boolean {
    const platform: Platform = this.platforms[platformName];
    if (!platform) {
      throw new Error(`Unknown platform ${platformName}`);
    }
    return platform.supportsPlatformAction(action);
  }

  public async executeAction(
    platformName: string,
    chainId: number,
    action: string,
    optionPosition: OptionPosition
  ): Promise<PopulatedTransaction> {
    const platform: Platform = this.platforms[platformName];
    if (!platform) {
      throw new Error(`Unknown platform ${platformName}`);
    }
    const config: unknown = this.configByPlatform[platformName][chainId];
    if (!config) {
      throw new Error(`Missing config for platform ${platformName} on network ${chainId}`);
    }
    const provider: providers.BaseProvider = this.providers[chainId].provider;
    if (!provider) {
      throw new Error(`Missing provider for network ${chainId}`);
    }
    if (!platform.supportsPlatformAction(action)) {
      throw new Error(`Unsupported action ${action} by platform ${platformName}`);
    }
    return platform.generatePlatformActionPayload(action, config, provider, optionPosition);
  }

  public async retrieveGatewayActions(
    caller: string,
    fee: ethers.BigNumber,
    gas: GasSettings,
    optionConfigurations: OptionConfiguration[]
  ): Promise<GatewayAction[]> {
    const ret: GatewayAction[] = [];

    for (const optionConfiguration of optionConfigurations) {
      const platform: Platform = this.platforms[optionConfiguration.option.platform];
      if (!platform) {
        throw new Error(`Unknown platform ${optionConfiguration.option.platform}`);
      }
      const config: unknown =
        this.configByPlatform[optionConfiguration.option.platform][optionConfiguration.option.network];
      if (!config) {
        throw new Error(
          `Missing config for platform ${optionConfiguration.option.platform} on network ${optionConfiguration.option.network}`
        );
      }
      const provider: providers.BaseProvider = this.providers[optionConfiguration.option.network].provider;
      if (!provider) {
        throw new Error(`Missing provider for network ${optionConfiguration.option.network}`);
      }
      ret.push(
        await platform.generateGatewayPayload(
          config,
          provider,
          optionConfiguration.option,
          optionConfiguration.amount,
          optionConfiguration.requiredParams,
          optionConfiguration.providedParams,
          fee,
          gas,
          caller
        )
      );
    }

    return ret;
  }

  public async retrievePriceAndAmount(
    option: Option,
    amount: ethers.BigNumber,
    requiredParams: string[],
    providedParams: unknown[],
    fee: ethers.BigNumber,
    gas: GasSettings
  ): Promise<OptionPriceAndAmount> {
    const platform: Platform = this.platforms[option.platform];
    if (!platform) {
      throw new Error(`Unknown platform ${option.platform}`);
    }
    const config: unknown = this.configByPlatform[option.platform][option.network];
    if (!config) {
      throw new Error(`Missing config for platform ${option.platform} on network ${option.network}`);
    }
    const provider: providers.BaseProvider = this.providers[option.network].provider;
    if (!provider) {
      throw new Error(`Missing provider for network ${option.network}`);
    }

    return await platform.retrieveOptionPriceAndAmount(
      config,
      provider,
      option,
      amount,
      requiredParams,
      providedParams,
      fee,
      gas
    );
  }

  public async loadPlatform(platform: Platform): Promise<void> {
    const name = platform.name();
    this.platforms[name] = platform;
    this.configByPlatform[name] = await platform.configs();
    this.logger.info(`loaded platform ${name}`);
  }

  private static filterByType(filterOptions: FilterOptions, option: Option): boolean {
    if (_.isNil(filterOptions.type) || filterOptions.type.length === 0) {
      return true;
    }

    return _.some(filterOptions.type, (t: OptionType) => t === option.type);
  }

  private static filterByUnderlyingAsset(filterOptions: FilterOptions, option: Option): boolean {
    if (_.isNil(filterOptions.underlyingAsset) || filterOptions.underlyingAsset.length === 0) {
      return true;
    }

    return _.some(
      filterOptions.underlyingAsset,
      (u: OptionAsset) => u.toLowerCase() === option.underlyingAsset?.toLowerCase()
    );
  }

  private static filterByPlatform(filterOptions: FilterOptions, option: Option): boolean {
    if (filterOptions.platforms === null) {
      return true;
    }
    return filterOptions.platforms.includes(option.platform);
  }

  private static filterByDisabled(filterOptions: FilterOptions, option: Option): boolean {
    return !option.disabled;
  }

  public static filterOptions(options: Option[], filterOptions: FilterOptions): Option[] {
    return options
      .filter(TBDSDK.filterByDisabled.bind(null, filterOptions))
      .filter(TBDSDK.filterByType.bind(null, filterOptions))
      .filter(TBDSDK.filterByUnderlyingAsset.bind(null, filterOptions))
      .filter(TBDSDK.filterByPlatform.bind(null, filterOptions));
  }

  public fetchOptionPositions(account: string): Observable<OptionPosition[]> {
    return merge(
      ...Object.values(this.platforms).map((p: Platform): Observable<OptionPosition> => {
        return merge(
          ...Object.keys(this.configByPlatform[p.name()])
            .map((netIdStr: string): number => parseInt(netIdStr))
            .map((netId: number): Observable<OptionPosition> => {
              if (this.providers[netId]) {
                return p
                  .streamOptionPositions(
                    netId,
                    this.providers[netId].provider,
                    this.configByPlatform[p.name()][netId],
                    account
                  )
                  .pipe(
                    map(
                      (v: OptionPosition): OptionPosition => ({
                        ...v,
                        option: {
                          ...v.option,
                          underlyingAsset: tlu(v.option.underlyingAsset),
                          premiumAsset: tlu(v.option.premiumAsset),
                          collateralAsset: tlu(v.option.collateralAsset),
                          strikeAsset: tlu(v.option.strikeAsset),
                        },
                      })
                    )
                  )
                  .pipe(
                    map(
                      (v: OptionPosition): OptionPosition => ({
                        ...v,
                        option: {
                          ...v.option,
                          network: netId,
                          platform: p.name(),
                          id: `${p.name()}::${netId}::${v.option.id}`,
                        },
                      })
                    )
                  );
              } else {
                return null;
              }
            })
            .filter((o: Observable<OptionPosition>) => o !== null)
        );
      })
    ).pipe(
      scan((acc: OptionPosition[], value: OptionPosition): OptionPosition[] => [...acc, value], []),
      map((value: OptionPosition[]): OptionPosition[] =>
        value
          .filter((_value: OptionPosition, idx: number, arr: OptionPosition[]): boolean => {
            if (_value.option.loaded === false) {
              return (
                idx === arr.findIndex((__value: OptionPosition): boolean => __value.positionId === _value.positionId)
              );
            } else {
              return true;
            }
          })
          .filter(
            (_value: OptionPosition, idx: number, arr: OptionPosition[]): boolean =>
              arr.map((v) => v.positionId).lastIndexOf(_value.positionId) === idx
          )
      )
    );
  }

  public fetchOptions(fetchOpt?: FetchOptions): Observable<Option[]> {
    return merge(
      ...Object.values(this.platforms).map((p: Platform): Observable<Option> => {
        return merge(
          ...Object.keys(this.configByPlatform[p.name()])
            .map((netIdStr: string): number => parseInt(netIdStr))
            .map((netId: number): Observable<Option> => {
              if (this.providers[netId]) {
                return p
                  .streamOptions(
                    netId,
                    this.providers[netId].provider,
                    this.configByPlatform[p.name()][netId],
                    fetchOpt
                  )
                  .pipe(
                    map(
                      (v: Option): Option => ({
                        ...v,
                        underlyingAsset: tlu(v.underlyingAsset),
                        premiumAsset: tlu(v.premiumAsset),
                        collateralAsset: tlu(v.collateralAsset),
                        strikeAsset: tlu(v.strikeAsset),
                      })
                    )
                  )
                  .pipe(
                    map(
                      (v: Option): Option => ({
                        ...v,
                        network: netId,
                        platform: p.name(),
                        id: `${p.name()}::${netId}::${v.id}`,
                      })
                    )
                  );
              } else {
                return null;
              }
            })
            .filter((o: Observable<Option>) => o !== null)
        );
      })
    ).pipe(
      scan((acc: Option[], value: Option): Option[] => [...acc, value], []),
      map((value: Option[]): Option[] =>
        value
          .filter((_value: Option, idx: number, arr: Option[]): boolean => {
            if (_value.loaded === false) {
              return idx === arr.findIndex((__value: Option): boolean => __value.id === _value.id);
            } else {
              return true;
            }
          })
          .filter(
            (_value: Option, idx: number, arr: Option[]): boolean => arr.map((v) => v.id).lastIndexOf(_value.id) === idx
          )
      )
    );
  }

  private loadProviders() {
    for (const _netId of Object.keys(this.mnc)) {
      const netId = parseInt(_netId);

      if (!this.mnc[netId].provider && !this.mnc[netId].rpc) {
        throw new TBDSDKNetworkConfigurationError(netId);
      }

      try {
        this.providers[netId] = {
          provider: this.mnc[netId].provider || this.providerBuilder.build(this.mnc[netId].rpc),
          wr: this.mnc[netId].wr || false,
        };

        this.logger.info(`loaded network ${netId}`);
      } catch (e) {
        throw new TBDSDKNetworkBuildError(netId);
      }
    }
  }
}
