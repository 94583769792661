import { BigNumber } from '@ethersproject/bignumber';
import { Option, OptionPriceAndAmount } from '@tbd/sdk';
import React from 'react';
import { useReducer, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTBD } from '@tbd/react';
import { useArtifacts } from '../../ethereum/useArtifacts';
import { useContractCall } from '@usedapp/core';
import { useAppState } from '../AppStateProvider';

type Product = Option;

type Params = 'expiry' | 'strike';

export interface ParamError {
  error: string;
}

interface NetworkError {
  current: number;
  required: number;
}

export interface CartItem {
  product: Product;
  requiredParams: Params[];
  providedParams: unknown[];
  paramErrors: ParamError[];
  networkError: NetworkError;
  valid: boolean;
  paymentToken: string;
  amount: BigNumber;
  amountError: ParamError;
  totalCosts: BigNumber[];
  costCurrencies: string[];
  costDescriptions: string[];
  totalCostLoading: boolean;
  totalCostHash: string;
}

export interface Cart {
  items: CartItem[];
  valid: boolean;
  open: boolean;
}

const defaultCartState: Cart = {
  items: [],
  valid: false,
  open: false,
};

class Action {
  type: string;
}

class AddCartItem implements Action {
  type: 'addCartItem';
  product: Product;
  arguments?: { [key: string]: unknown };
}

class OpenCart implements Action {
  type: 'openCart';
}

class CloseCart implements Action {
  type: 'closeCart';
}

class SetParameter implements Action {
  type: 'setParameter';
  item: number;
  idx: number;
  value: unknown;
}

class SetAmount implements Action {
  type: 'setAmount';
  item: number;
  value: BigNumber;
}

class SetCostFetching implements Action {
  type: 'setCostFetching';
  item: number;
  hash: string;
}

class SetCost implements Action {
  type: 'setCostAndAmount';
  item: number;
  cost: OptionPriceAndAmount;
}

class SetItemValidity implements Action {
  type: 'setItemValidity';
  item: number;
  validity: boolean;
}

class SetItemParamError implements Action {
  type: 'setItemParamError';
  item: number;
  param: number;
  error: ParamError;
}

class SetAmountError implements Action {
  type: 'setAmountError';
  item: number;
  error: ParamError;
}

class ClearCart implements Action {
  type: 'clearCart';
}

class RemoveCartItem implements Action {
  type: 'removeCartItem';
  item: number;
}

type CartAction =
  | AddCartItem
  | OpenCart
  | CloseCart
  | SetParameter
  | SetAmount
  | SetCostFetching
  | SetCost
  | SetItemValidity
  | SetItemParamError
  | SetAmountError
  | ClearCart
  | RemoveCartItem;

export const CartStateContext = React.createContext<[Cart, React.Dispatch<CartAction>]>([defaultCartState, () => ({})]);

const CartStateReducer = (cart: Cart, action: CartAction): Cart => {
  switch (action.type) {
    case 'clearCart': {
      return { ...defaultCartState };
    }
    case 'setAmountError': {
      const act: SetAmountError = action;
      if (!cart.items[act.item]) {
        return cart;
      }
      cart.items[act.item].amountError = act.error;
      return {
        ...cart,
        items: [...cart.items],
      };
      break;
    }
    case 'setItemParamError': {
      const act: SetItemParamError = action;
      if (!cart.items[act.item]) {
        return cart;
      }
      cart.items[act.item].paramErrors[act.param] = act.error;
      return {
        ...cart,
        items: [...cart.items],
      };
    }
    case 'setItemValidity': {
      const act: SetItemValidity = action;
      if (!cart.items[act.item]) {
        return cart;
      }
      cart.items[act.item].valid = act.validity;
      const validCount = cart.items.map((ci) => (ci.valid ? 1 : 0)).reduce((a, b) => a + b, 0);
      return {
        ...cart,
        items: [...cart.items],
        valid: validCount === cart.items.length,
      };
    }
    case 'setCostAndAmount': {
      const act: SetCost = action;
      if (!cart.items[act.item]) {
        return cart;
      }
      cart.items[act.item].totalCosts = act.cost.prices;
      cart.items[act.item].costCurrencies = act.cost.currencies;
      cart.items[act.item].costDescriptions = act.cost.descriptions;
      cart.items[act.item].product = {
        ...act.cost.option,
      };
      cart.items[act.item].totalCostLoading = false;
      return {
        ...cart,
        items: [...cart.items],
      };
    }
    case 'setCostFetching': {
      const act: SetCostFetching = action;
      if (!cart.items[act.item]) {
        return cart;
      }
      cart.items[act.item].totalCostLoading = true;
      cart.items[act.item].totalCostHash = act.hash;
      return {
        ...cart,
        items: [...cart.items],
      };
    }
    case 'setAmount': {
      const act: SetAmount = action;
      if (!cart.items[act.item]) {
        return cart;
      }
      cart.items[act.item].amount = act.value;
      if (act.value.gt(0)) {
        cart.items[act.item].totalCostLoading = true;
      }
      cart.items[act.item].totalCosts = null;
      cart.items[act.item].costCurrencies = null;
      cart.items[act.item].costDescriptions = null;
      return {
        ...cart,
        items: [...cart.items],
      };
    }
    case 'setParameter': {
      const act: SetParameter = action;
      if (!cart.items[act.item]) {
        return cart;
      }
      cart.items[act.item].providedParams[act.idx] = act.value;
      return {
        ...cart,
        items: [...cart.items],
      };
    }
    case 'removeCartItem': {
      const act: RemoveCartItem = action;
      if (!cart.items[act.item]) {
        return cart;
      }
      const items = [...cart.items];
      items.splice(action.item, 1);
      return {
        ...cart,
        open: items.length > 0,
        items: [...items],
      };
    }
    case 'addCartItem': {
      const requiredParams = [];
      const providedParams = [];
      const paramErrors = [];

      if (_.isArray(action.product.strike)) {
        requiredParams.push('strike');
        providedParams.push(action.arguments ? action.arguments['strike'] || null : null);
        paramErrors.push(null);
      }

      if (_.isArray(action.product.expiry)) {
        requiredParams.push('expiry');
        providedParams.push(action.arguments ? action.arguments['expiry'] || null : null);
        paramErrors.push(null);
      }

      return {
        ...cart,
        open: true,
        items: [
          ...cart.items,
          {
            product: {
              ...action.product,
            },
            requiredParams,
            providedParams,
            paramErrors,
            networkError: null,
            valid: false,
            paymentToken: action.product.premiumAsset,
            amount: action.arguments ? (action.arguments['amount'] as BigNumber) || null : null,
            totalCosts: null,
            costCurrencies: null,
            totalCostLoading: false,
            totalCostHash: null,
            costDescriptions: null,
            amountError: null,
          },
        ],
      };
    }
    case 'openCart': {
      return {
        ...cart,
        open: cart.items.length ? true : false,
      };
    }
    case 'closeCart': {
      return {
        ...cart,
        open: false,
      };
    }
  }

  return cart;
};

const getOptionHash = (
  option: Option,
  amount: BigNumber,
  requiredParams: string[],
  providedParams: unknown[]
): string => {
  let res = '';

  const keys = Object.keys(option).sort();
  for (const key of keys) {
    res = `${res}:${key}=${option[key] ? option[key].toString() : option[key]}`;
  }
  for (let idx = 0; idx < requiredParams.length; ++idx) {
    res = `${res}:${requiredParams[idx]}=${providedParams[idx]}`;
  }
  res = `${res}:amount=${amount.toString()}`;

  return res;
};

export const CartStateProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const [cartState, cartDispatch] = useReducer(CartStateReducer, defaultCartState);
  const artifacts = useArtifacts();
  const [gatewayFee] =
    useContractCall(
      artifacts.Gateway
        ? {
            address: artifacts.Gateway.address,
            abi: artifacts.Gateway.interface,
            method: 'fee',
            args: [],
          }
        : false
    ) || [];
  const sdk = useTBD();
  const [appState] = useAppState();
  const [lastTimestamp, setLastTimestamp] = useState(0);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const tid = setTimeout(() => setTimer(Date.now()), 1000);
    return () => {
      clearTimeout(tid);
    };
  }, [timer]);

  useEffect(() => {
    const tid = setTimeout(async () => {
      for (let idx = 0; idx < cartState.items.length; ++idx) {
        const item = cartState.items[idx];
        if (
          item.providedParams.includes(null) ||
          item.paramErrors.filter((v) => !!v).length ||
          !item.amount ||
          item.amount.eq(0)
        ) {
          continue;
        }
        const optionHash = getOptionHash(item.product, item.amount, item.requiredParams, item.providedParams);
        if (item.totalCostHash !== optionHash) {
          cartDispatch({
            type: 'setCostFetching',
            item: idx,
            hash: optionHash,
          });
          const cost = await sdk.retrievePriceAndAmount(
            item.product,
            item.amount,
            item.requiredParams,
            item.providedParams,
            gatewayFee,
            appState.gasSettings
          );
          cartDispatch({
            type: 'setCostAndAmount',
            item: idx,
            cost,
          });
        }
      }
    }, 200);
    return () => {
      clearTimeout(tid);
    };
  }, [cartState, sdk, gatewayFee, appState.gasSettings]);

  useEffect(() => {
    if ((timer - lastTimestamp) / 1000 >= 5) {
      const tid = setTimeout(async () => {
        for (let idx = 0; idx < cartState.items.length; ++idx) {
          const item = cartState.items[idx];
          if (
            item.providedParams.includes(null) ||
            item.paramErrors.filter((v) => !!v).length ||
            !item.amount ||
            item.amount.eq(0)
          ) {
            continue;
          }
          const cost = await sdk.retrievePriceAndAmount(
            item.product,
            item.amount,
            item.requiredParams,
            item.providedParams,
            gatewayFee,
            appState.gasSettings
          );
          cartDispatch({
            type: 'setCostAndAmount',
            item: idx,
            cost,
          });
        }
        setLastTimestamp(timer);
      }, 250);
      return () => {
        clearTimeout(tid);
      };
    }
    return null;
  }, [cartState, appState.gasSettings, gatewayFee, sdk, lastTimestamp, timer]);

  useEffect(() => {
    for (let itemIdx = 0; itemIdx < cartState.items.length; ++itemIdx) {
      let ready = true;
      const cartItem = cartState.items[itemIdx];
      for (let paramIdx = 0; paramIdx < cartItem.requiredParams.length; ++paramIdx) {
        let error: ParamError = null;
        if (cartItem.providedParams[paramIdx] === null) {
          ready = false;
        } else {
          switch (cartItem.requiredParams[paramIdx]) {
            case 'expiry': {
              const providedParam: Date = cartItem.providedParams[paramIdx] as Date;
              const dateLimits: [Date, Date] = cartItem.product.expiry as [Date, Date];
              if (
                providedParam.getTime() < dateLimits[0].getTime() ||
                providedParam.getTime() > dateLimits[1].getTime()
              ) {
                ready = false;
                error = {
                  error: 'Out of bounds',
                };
              }
              break;
            }
            case 'strike': {
              break;
            }
          }
        }

        if (!_.isEqual(error, cartItem.paramErrors[paramIdx])) {
          cartDispatch({
            type: 'setItemParamError',
            item: itemIdx,
            param: paramIdx,
            error,
          });
        }
      }

      if (cartItem.amount === null) {
        ready = null;
      } else if (cartItem.amount.eq(0)) {
        ready = false;
        const err: ParamError = {
          error: 'Null amount',
        };

        if (!_.isEqual(err, cartItem.amountError)) {
          cartDispatch({
            type: 'setAmountError',
            item: itemIdx,
            error: {
              error: err.error,
            },
          });
        }
      } else if (cartItem.amount.gt(cartItem.product.amount as BigNumber)) {
        ready = false;
        const err: ParamError = {
          error: 'Insufficient liquidity',
        };
        if (!_.isEqual(err, cartItem.amountError)) {
          cartDispatch({
            type: 'setAmountError',
            item: itemIdx,
            error: {
              error: err.error,
            },
          });
        }
      } else {
        if (!_.isEqual(null, cartItem.amountError)) {
          cartDispatch({
            type: 'setAmountError',
            item: itemIdx,
            error: null,
          });
        }
      }

      if (cartItem.valid !== ready) {
        cartDispatch({
          type: 'setItemValidity',
          item: itemIdx,
          validity: ready,
        });
      }
    }
  }, [cartState]);

  return <CartStateContext.Provider value={[cartState, cartDispatch]}>{children}</CartStateContext.Provider>;
};
