import { styled } from '../ThemeProvider';
import MetamaskIcon from './icons/metamask.png';
import WalletConnectIcon from './icons/walletconnect.png';
import { CloseCircleOutlined } from '@ant-design/icons';
import { WalletConnectConnectorBuilder } from '../../utils/connectors/walletconnect';
import { InjectedConnectorBuilder } from '../../utils/connectors/injected';
import { useCallback, useContext } from 'react';
import { useEthers } from '@usedapp/core';
import { WalletModalContext } from '../WalletModalProvider';
import { glassmorphism } from '../../utils/glassmorphism';

const WalletSelectionContainer = styled.div`
  background-color: #00000033;
  border-radius: 6px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #00000066;
  }
`;

const Icon = styled.img`
  width: 50px;
  height: 50px;
  margin: 12px;
`;

const WalletName = styled.span`
  margin: 12px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
`;

const WalletSelection = ({ name, icon, onClick }: { name: string; icon: string; onClick: () => void }) => {
  return (
    <div
      style={{
        height: 75,
        padding: 12,
        paddingBottom: 0,
        width: '100%',
      }}
    >
      <WalletSelectionContainer onClick={onClick}>
        <WalletName>{name}</WalletName>
        <Icon src={icon} />
      </WalletSelectionContainer>
    </div>
  );
};

const SelectWallet = styled.span`
  font-size: 14px;
  color: #ffffff;
`;

const WalletSelectionTitle = () => {
  return (
    <div
      style={{
        height: 75,
        padding: 12,
        paddingBottom: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <SelectWallet>Select your wallet</SelectWallet>
    </div>
  );
};

const CloseIcon = styled(CloseCircleOutlined)`
  color: #ffffff88;
  font-size: 20px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  z-index: 10;

  &:hover {
    color: #ffffff;
  }
`;

const WalletContainerCard = styled.div`
  ${(props) => glassmorphism('#000000cc', '#000000cc', 0.75)}
  position: relative;
  overflow: scroll;
  padding-bottom: 12px;
  z-index: 1;

  @media (max-width: 1223px) {
    height: 100vh;
    width: 100vw;
  }

  @media (min-width: 1224px) {
    border-radius: 6px;
    width: 400px;
    height: 50%;
  }
`;

export const WalletModal = () => {
  const { activate, account } = useEthers();
  const [active, setActive] = useContext(WalletModalContext);

  const close = useCallback(() => setActive(false), [setActive]);

  const onInjected = useCallback(() => {
    activate(InjectedConnectorBuilder());
    close();
  }, [activate, close]);

  const onWalletConnect = useCallback(() => {
    activate(WalletConnectConnectorBuilder());
    close();
  }, [activate, close]);

  if (active && !account) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 30,
          backgroundColor: '#00000000',
        }}
      >
        <WalletContainerCard>
          <CloseIcon onClick={close} />
          <WalletSelectionTitle />
          <WalletSelection name={'metamask'} icon={MetamaskIcon} onClick={onInjected} />
          <WalletSelection name={'wallet connect'} icon={WalletConnectIcon} onClick={onWalletConnect} />
        </WalletContainerCard>
      </div>
    );
  } else {
    return null;
  }
};
