import { notification, Select } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import { Network, useNetworkState } from '../NetworkProvider';
import { useCallback, useEffect, useState } from 'react';
import { GasSettings, GasSpeed } from '@tbd/sdk';
import { useBlockNumber, useEthers } from '@usedapp/core';
import { useTBD } from '@tbd/react';
import { useAppState } from '../AppStateProvider';
import Decimal from 'decimal.js';
import { styled } from '../ThemeProvider';
import { glassmorphism } from '../../utils/glassmorphism';
import { getFeeAsString } from '../../utils/getFeeAsString';

const SelectOption = styled(Select.Option)`
  font-weight: 700;
`;

const BottomBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  z-index: 10;

  ${(props) => glassmorphism(`${props.theme.fg}10`, props.theme.bg)}
`;

const BlockIcon = styled.span`
  font-size: 10px;
  margin: ${(props) => props.theme.spacing.s};
  margin-right: 0;
`;

const BlockText = styled.span`
  font-weight: 300;
  font-size: 14px;
  margin: ${(props) => props.theme.spacing.s};
`;

const Separation = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${(props) => props.theme.fg}30;
  margin: ${(props) => props.theme.spacing.s};
`;

const BottomBarSectionContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const SocialLink = styled.span`
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  margin: ${(props) => props.theme.spacing.s};
`;

const NetworkText = styled.span`
  font-weight: 300;
  cursor: pointer;
  text-transform: uppercase;
  margin: ${(props) => props.theme.spacing.s};
`;

const GasText = styled.span`
  font-weight: 300;
  cursor: pointer;
  text-transform: uppercase;
  margin: ${(props) => props.theme.spacing.s};
`;

export const BottomBar = () => {
  const blocknumber = useBlockNumber();
  const [appState, dispatchAppState] = useAppState();
  const [networkState, dispatchNetworkState] = useNetworkState();
  const { chainId } = useEthers();
  const tbd = useTBD();
  const currentBlockNumber = useBlockNumber();
  const [lastBlockNumber, setLastBlockNumber] = useState(0);

  useEffect(() => {
    (async () => {
      if (
        tbd &&
        (!appState.gasSettings || appState.gasSettings.speed !== appState.gas || lastBlockNumber < currentBlockNumber)
      ) {
        const gasSettings = await tbd.getGasSettings(chainId, appState.gas);
        dispatchAppState({
          type: 'SetGasSettings',
          gasSettings,
        });
        setLastBlockNumber(currentBlockNumber);
      }
    })();
  }, [tbd, appState, chainId, dispatchAppState, currentBlockNumber, lastBlockNumber]);

  const setGasCallback = useCallback(
    (gas: GasSpeed): void => dispatchAppState({ type: 'SetGas', gas }),
    [dispatchAppState]
  );
  const setNetworkCallback = useCallback(
    (network: Network): void => dispatchNetworkState({ type: 'SetNetwork', network }),
    [dispatchNetworkState]
  );

  return (
    <BottomBarContainer>
      <BottomBarSectionContainer>
        <SocialLink onClick={() => notification.info({ message: 'Coming soon !' })}>twitter</SocialLink>
        <Separation />
        <SocialLink onClick={() => notification.info({ message: 'Coming soon !' })}>discord</SocialLink>
        <Separation />
        <SocialLink
          onClick={() => {
            notification.success({ message: 'Copied feedback@tbd.money to clipboard' });
            navigator.clipboard.writeText('feedback@tbd.money');
          }}
        >
          feedback
        </SocialLink>
      </BottomBarSectionContainer>
      <BottomBarSectionContainer>
        <GasText>
          Gas
          <Select
            style={{ fontWeight: 700 }}
            bordered={false}
            onChange={setGasCallback}
            value={appState.gas}
            suffixIcon={() => <UpOutlined />}
            dropdownAlign={{
              points: ['bl', 'tl'],
              offset: [0, 0],
              overflow: {
                adjustX: 0,
                adjustY: 1,
              },
            }}
          >
            <SelectOption value="slow">
              Slow {appState.gas === 'slow' ? `(${getFeeAsString(appState.gasSettings)})` : ''}
            </SelectOption>
            <SelectOption value="regular">
              Regular {appState.gas === 'regular' ? `(${getFeeAsString(appState.gasSettings)})` : ''}
            </SelectOption>
            <SelectOption value="fast">
              Fast {appState.gas === 'fast' ? `(${getFeeAsString(appState.gasSettings)})` : ''}
            </SelectOption>
            <SelectOption value="faster">
              Faster {appState.gas === 'faster' ? `(${getFeeAsString(appState.gasSettings)})` : ''}
            </SelectOption>
          </Select>
        </GasText>
        <Separation />
        <NetworkText>
          Network
          <Select
            style={{ fontWeight: 700 }}
            bordered={false}
            onChange={setNetworkCallback}
            value={networkState.network}
            suffixIcon={() => <UpOutlined />}
            dropdownAlign={{
              points: ['bl', 'tl'],
              offset: [0, 0],
              overflow: {
                adjustX: 0,
                adjustY: 1,
              },
            }}
          >
            {process.env.NX_LIVE === 'true' ? (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                <SelectOption value="ethereum">Ethereum</SelectOption>
              </>
            ) : (
              <>
                <SelectOption value="ethereum">Ethereum</SelectOption>
                <SelectOption value="ethereum_fork">Ethereum Fork</SelectOption>
                <SelectOption value="arbitrum">Arbitrum</SelectOption>
                <SelectOption value="arbitrum_fork">Arbitrum Fork</SelectOption>
                <SelectOption value="optimism">Optimism</SelectOption>
                <SelectOption value="optimism_fork">Optimism Fork</SelectOption>
                <SelectOption value="kovan">Kovan</SelectOption>
              </>
            )}
          </Select>
        </NetworkText>
        <Separation />
        <BlockIcon>❒</BlockIcon>
        <BlockText>{blocknumber?.toLocaleString()}</BlockText>
      </BottomBarSectionContainer>
    </BottomBarContainer>
  );
};
