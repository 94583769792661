import { BigNumber } from 'ethers';

export type OptionType = 'PUT' | 'CALL';
export type OptionNature = 'EU' | 'US';
export type OptionExpiry = Date | [Date, Date];
export type OptionStrike = BigNumber | [BigNumber, BigNumber];
export type OptionAmount = BigNumber | [BigNumber, BigNumber];
export type OptionPrice = BigNumber | [BigNumber, BigNumber];
export type OptionAsset = string;

export const ETH_ADDRESS = '0xETH';

export interface Option {
  id: string;
  address: string;
  loaded: boolean;
  network: number;
  platform: string;
  disabled: boolean;
  type: OptionType;
  nature: OptionNature;
  expiry: OptionExpiry;
  strike: OptionStrike;
  strikeDecimals: number;
  amount: OptionAmount;
  price: OptionPrice;
  premiumAsset: OptionAsset;
  underlyingAsset: OptionAsset;
  strikeAsset: OptionAsset;
  collateralAsset: OptionAsset;
  warnings?: string[];
  extraData: unknown;
}

export type OptionPositionStatus = 'inactive' | 'active' | 'expired' | 'exercised' | 'exercisable' | 'profitable';

export interface OptionPosition {
  option: Partial<Option>;
  fungible: boolean;
  amount: BigNumber;
  expired: boolean;
  exercised: boolean;
  exercisable: boolean;
  profits: BigNumber;
  profitAsset: OptionAsset;
  creationDate: Date;
  positionId: string;
  status: OptionPositionStatus;
}

export const EMPTY_OPTION: Option = {
  id: null,
  address: null,
  loaded: null,
  network: null,
  platform: null,
  disabled: null,
  type: null,
  nature: null,
  expiry: null,
  strike: null,
  strikeDecimals: null,
  amount: null,
  price: null,
  premiumAsset: null,
  underlyingAsset: null,
  strikeAsset: null,
  collateralAsset: null,
  extraData: null,
};

export interface FetchOptions {
  expiry?: OptionExpiry;
  strike?: OptionStrike;
}

export interface FilterOptions {
  type: OptionType[];
  nature: OptionNature[];
  amount: OptionAmount;
  price: OptionPrice;
  premiumAsset: string[];
  underlyingAsset: string[];
  strikeAsset: string[];
  collateralAsset: string[];
  platforms: string[];
}
