import { Weighted } from '../Weighted';
import { useLocation, useHistory } from 'react-router-dom';
import { styled } from '../ThemeProvider';
import { Badge } from 'antd';
import { ShoppingCartOutlined, CloseOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import { CartStateContext } from '../cart/CartStateProvider';
import { WalletModalContext } from '../WalletModalProvider';
import { useEthers } from '@usedapp/core';
import { glassmorphism } from '../../utils/glassmorphism';

const TopBarContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 80px;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  z-index: 10;

  ${(props) => glassmorphism(`${props.theme.fg}10`, props.theme.fg)};
`;

const TBDMONEYTitle = styled.span`
  font-size: 30px;
  text-transform: uppercase;
  margin: ${(props) => props.theme.spacing.l};
`;

const Section = styled.span`
  font-size: 18px;
  text-transform: uppercase;
  margin: ${(props) => props.theme.spacing.l};
  cursor: pointer;
`;

const EndSection = styled.div`
  position: absolute;
  right: 12px;
`;

const ConnectWalletDiv = styled.div<{ filled: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-color: ${(props) => (props.filled ? '#000000' : '#00000000')};
  color: ${(props) => (props.filled ? '#ffffff' : '#000000')};
  padding: 12px;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: ${(props) => (props.filled ? '#00000000' : '#000000')};
    color: ${(props) => (props.filled ? '#000000' : '#ffffff')};
  }
`;

const ConnectWalletButton = () => {
  const { account, deactivate } = useEthers();
  const [, setActive] = useContext(WalletModalContext);
  const [cartState, cartDispatch] = useContext(CartStateContext);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <ConnectWalletDiv
        filled={!!account}
        onClick={account ? deactivate : () => setActive(true)}
        style={{
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          borderRightWidth: 0,
        }}
      >
        {account ? (
          <>
            <Weighted weight={'900'}>0x</Weighted>
            <Weighted weight={'300'}>{account.slice(2, 8)}</Weighted>
            <Weighted weight={'300'}>...</Weighted>
            <Weighted weight={'300'}>{account.slice(-6)}</Weighted>
          </>
        ) : (
          <>
            <Weighted weight={'900'} style={{ marginRight: 4 }}>
              CONNECT
            </Weighted>
            <Weighted weight={'300'}>WALLET</Weighted>
          </>
        )}
      </ConnectWalletDiv>
      <ConnectWalletDiv
        onClick={cartState.open ? () => cartDispatch({ type: 'closeCart' }) : () => cartDispatch({ type: 'openCart' })}
        filled={cartState.open}
        style={{
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
        }}
      >
        {cartState.open ? (
          <CloseOutlined
            style={{
              fontSize: 22,
              margin: 0,
              padding: 0,
            }}
          />
        ) : (
          <ShoppingCartOutlined
            style={{
              fontSize: 22,
              margin: 0,
              padding: 0,
            }}
          />
        )}

        {cartState.items.length ? (
          <Badge
            count={cartState.items.length}
            style={{
              position: 'absolute',
              right: -23,
              top: -33,
              boxShadow: '0 0 0 1px #bbbbbb inset',
              backgroundColor: '#bbbbbb',
              color: '#000000',
            }}
          />
        ) : null}
      </ConnectWalletDiv>
    </div>
  );
};

const Underline = styled.div<{ selected: boolean }>`
  height: 2px;
  width: ${(props) => (props.selected ? '100%' : '0%')};
  transition: all 400ms ease-in-out;
  background-color: black;
`;

export const TopBar = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <TopBarContainer>
      <TBDMONEYTitle>
        <Weighted weight={'900'}>TBD</Weighted>
        <Weighted weight={'300'}>MONEY</Weighted>
      </TBDMONEYTitle>
      <Section onClick={() => history.push('/buy')}>
        <Weighted weight={'700'}>Buy</Weighted> Options
        <Underline selected={location.pathname === '/buy'} />
      </Section>
      <Section onClick={() => history.push('/sell')}>
        <Weighted weight={'700'}>Sell</Weighted> Options
        <Underline selected={location.pathname === '/sell'} />
      </Section>
      <Section onClick={() => history.push('/positions')}>
        <Weighted weight={'700'}>My</Weighted> Positions
        <Underline selected={location.pathname === '/positions'} />
      </Section>
      <EndSection>
        <ConnectWalletButton />
      </EndSection>
    </TopBarContainer>
  );
};
