import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

body, html, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

* {
 font-size: 100%;
 font-family: 'Noto Sans KR';
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: white !important;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: white !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: white !important;
}

`;
