
export class TBDSDKNetworkConfigurationError extends Error {
  constructor(net: number) {
    super(`Missing rpc & provider arguments for network id ${net}. Define at least one.`);
  }
}

export class TBDSDKNetworkBuildError extends Error {
  constructor(net: number) {
    super(`An error occured while building ethers provider for network id ${net}.`);
  }
}
