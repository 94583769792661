import { GasSettings, GasSpeed } from '@tbd/sdk';
import { BigNumber } from 'ethers';
import React, { Dispatch } from 'react';
import { useReducer, useContext, useEffect } from 'react';

export interface AppState {
  gas: GasSpeed;
  gasSettings: GasSettings;
  selectedOption: string;
}

export type AppStateReducer = (state: AppState, _action: Action) => AppState;

const defaultAppState: AppState = {
  gas: 'regular',
  gasSettings: null,
  selectedOption: null,
};

export interface SetGasAction {
  type: 'SetGas';
  gas: GasSpeed;
}

export interface SetGasSettingsAction {
  type: 'SetGasSettings';
  gasSettings: GasSettings;
}

export interface SetSelectedOptionAction {
  type: 'SetSelectedOption';
  option: string;
}

export type Action = SetGasAction | SetGasSettingsAction | SetSelectedOptionAction;

const appStateReducer: AppStateReducer = (state: AppState, _action: Action): AppState => {
  if (!_action || !_action.type) {
    return state;
  }

  switch (_action.type) {
    case 'SetSelectedOption': {
      const action: SetSelectedOptionAction = _action;
      return {
        ...state,
        selectedOption: action.option,
      };
    }
    case 'SetGasSettings': {
      const action: SetGasSettingsAction = _action;
      return {
        ...state,
        gasSettings: action.gasSettings,
      };
    }
    case 'SetGas': {
      const action: SetGasAction = _action;
      return {
        ...state,
        gas: action.gas,
      };
    }
    default: {
      return state;
    }
  }
};

const AppStateContext = React.createContext<[AppState, Dispatch<Action>]>([defaultAppState, () => ({})]);

const getFromStorage = (version: string): AppState => {
  const storedState = localStorage.getItem(`state-${version}`);
  if (storedState) {
    try {
      const parsedJson = JSON.parse(storedState);
      if (parsedJson.gasSettings) {
        parsedJson.gasSettings = {
          type: parsedJson.gasSettings.type,
          gasPrice: parsedJson.gasSettings.gasPrice ? BigNumber.from(parsedJson.gasSettings.gasPrice) : undefined,
          maxFeePerGas: parsedJson.gasSettings.maxFeePerGas
            ? BigNumber.from(parsedJson.gasSettings.maxFeePerGas)
            : undefined,
          maxPriorityFeePerGas: parsedJson.gasSettings.maxPriorityFeePerGas
            ? BigNumber.from(parsedJson.gasSettings.maxPriorityFeePerGas)
            : undefined,
          speed: parsedJson.gasSettings.speed,
        };
      }
      return parsedJson;
    } catch (e) {
      //
    }
  }
  return defaultAppState;
};

export const AppStateProvider: React.FC<React.PropsWithChildren<{ version: string }>> = ({
  children,
  version,
}: React.PropsWithChildren<{ version: string }>): JSX.Element => {
  const [appState, reducer] = useReducer(appStateReducer, defaultAppState /*getFromStorage(version)*/);

  // useEffect(() => {
  //   localStorage.setItem(`state-${version}`, JSON.stringify(appState));
  // }, [appState, version]);

  return <AppStateContext.Provider value={[appState, reducer]}>{children}</AppStateContext.Provider>;
};

export const useAppState = (): [AppState, Dispatch<Action>] => {
  return useContext(AppStateContext);
};
