import { providers } from "ethers";

export interface ProviderBuilder {
 build(rpc: string): providers.BaseProvider;
}

export class DefaultProviderBuilder implements ProviderBuilder {
 public build(rpc: string): providers.BaseProvider {
  return new providers.JsonRpcProvider(rpc);
 }
}

