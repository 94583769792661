import {
  MultiNetworkConfiguration,
  TBDSDK,
  LogLevel,
  Option,
  Platform,
  FetchOptions,
  FilterOptions,
  OptionPosition,
} from '@tbd/sdk';
import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { Observable } from 'rxjs';
import { bind, Subscribe } from '@react-rxjs/core';
import _ from 'lodash';
import Decimal from 'decimal.js';
import { useCallback } from 'react';
import { PlatformHegic8888 } from '@tbd/platform-hegic-8888';
import { PlatformHegic } from '@tbd/platform-hegic';
import { PlatformOpyn } from '@tbd/platform-opyn';
import { ethers } from 'ethers';

interface TBDProviderProps {
  config: MultiNetworkConfiguration;
  logLevel?: LogLevel;
  testMode?: boolean;
  paused?: boolean;
}

const TBDContext = React.createContext<[boolean, TBDSDK]>([false, null]);

export const TBDProvider: React.FC<React.PropsWithChildren<TBDProviderProps>> = ({
  config,
  logLevel,
  children,
  testMode,
  paused,
}: React.PropsWithChildren<TBDProviderProps>): JSX.Element => {
  const tbd = useMemo(() => new TBDSDK(config, logLevel), [config, logLevel]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(false);
  }, [tbd]);

  useEffect(() => {
    if (!ready) {
      (async () => {
        await tbd.loadPlatform(new PlatformOpyn(testMode));
        await tbd.loadPlatform(new PlatformHegic8888(testMode));

        // await tbd.loadPlatform(new PlatformHegic(testMode)); RIP https://etherscan.io/tx/0xe42b7bbd58d6d31a9fae71038d2c3e097f3d25ecb4352cf44d890023067e3ea3
      })().then(() => {
        setReady(true);
      });
    }
  }, [tbd, setReady, ready, testMode]);

  if (!ready) {
    return <p>LOADING</p>;
  }

  return <TBDContext.Provider value={paused ? [false, null] : [ready, tbd]}>{children}</TBDContext.Provider>;
};

export const useTBD = (): TBDSDK => {
  const [ready, tbd] = useContext(TBDContext);

  if (!ready) {
    return null;
  }

  return tbd;
};

// const generateFetchOptionsSignature

export const useOptionsObservable = (fetchOpt?: FetchOptions): Observable<Option[]> => {
  const tbd = useTBD();
  const [fetchOptionsSignature, setFetchOptionsSignature] = useState();

  const observable = useMemo(() => {
    if (tbd) {
      return tbd.fetchOptions(fetchOpt);
    }
    return new Observable<Option[]>();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOpt]);

  return observable;
};

export const useOptionPositionsObservable = (account: string): [Observable<OptionPosition[]>, () => void] => {
  const tbd = useTBD();
  const [init, setInit] = useState(false);
  const [observable, setObservable] = useState(new Observable<OptionPosition[]>());

  useEffect(() => {
    if (!init && tbd && account) {
      setObservable(tbd.fetchOptionPositions(account));
      setInit(true);
    }
  }, [tbd, account, init]);

  const reset = useCallback(() => {
    setInit(false);
  }, [setInit]);

  return [observable, reset];
};

export const useOptionsFilter = (
  options: Option[],
  filterOptions: FilterOptions,
  removeNullLiquidity: boolean
): Option[] => {
  const filteredOptions = TBDSDK.filterOptions(options, filterOptions);
  if (removeNullLiquidity) {
    return filteredOptions.filter((option: Option) => option.amount && (option.amount as ethers.BigNumber).gt(0));
  }
  return filteredOptions;
};

export interface OptionSortParams {
  field: 'expiry' | 'strike' | 'price' | 'liquidity';
  order: 'asc' | 'desc';
}

const getExpiryFromOption = (params: OptionSortParams, opt: Option): Date => {
  if (_.isNil(opt.expiry)) {
    return null;
  }
  if (params.order === 'asc') {
    if (_.isArray(opt.expiry)) {
      return opt.expiry[0];
    } else {
      return opt.expiry as Date;
    }
  } else {
    if (_.isArray(opt.expiry)) {
      return opt.expiry[1];
    } else {
      return opt.expiry as Date;
    }
  }
};

const floorToDay = (d: Date): Date => {
  if (d === null) {
    return null;
  }
  return new Date(d.getTime() - (d.getTime() % (24 * 60 * 60 * 1000)));
};

const sortByExpiry = (params: OptionSortParams, opt1: Option, opt2: Option): number => {
  const opt1Value: Date = floorToDay(getExpiryFromOption(params, opt1));
  const opt2Value: Date = floorToDay(getExpiryFromOption(params, opt2));

  if (opt1Value === opt2Value && opt1Value === null) {
    return opt1.id.localeCompare(opt2.id);
  }

  if (opt1Value === null) {
    return 1;
  }

  if (opt2Value === null) {
    return -1;
  }

  const diff = opt1Value.getTime() - opt2Value.getTime();

  if (diff === 0) {
    return opt1.id.localeCompare(opt2.id);
  }

  return diff * (params.order === 'desc' ? -1 : 1);
};

const getPriceFromOption = (
  params: OptionSortParams,
  pricings: { [key: string]: [number, number] },
  opt: Option
): number => {
  if (_.isNil(opt.price) || _.isNil(opt.premiumAsset) || _.isNil(pricings[opt.premiumAsset])) {
    return null;
  }

  if (params.order === 'asc') {
    if (_.isArray(opt.price)) {
      return new Decimal(opt.price[0].toString())
        .div(`1e${pricings[opt.premiumAsset][0]}`)
        .mul(pricings[opt.premiumAsset][1])
        .toNumber();
    } else {
      return new Decimal(opt.price.toString())
        .div(`1e${pricings[opt.premiumAsset][0]}`)
        .mul(pricings[opt.premiumAsset][1])
        .toNumber();
    }
  } else {
    if (_.isArray(opt.price)) {
      return new Decimal(opt.price[1].toString())
        .div(`1e${pricings[opt.premiumAsset][0]}`)
        .mul(pricings[opt.premiumAsset][1])
        .toNumber();
    } else {
      return new Decimal(opt.price.toString())
        .div(`1e${pricings[opt.premiumAsset][0]}`)
        .mul(pricings[opt.premiumAsset][1])
        .toNumber();
    }
  }
};

const sortByPrice = (
  params: OptionSortParams,
  pricings: { [key: string]: [number, number] },
  opt1: Option,
  opt2: Option
): number => {
  const opt1Value = getPriceFromOption(params, pricings, opt1);
  const opt2Value = getPriceFromOption(params, pricings, opt2);

  if (opt1Value === opt2Value && opt1Value === null) {
    return opt1.id.localeCompare(opt2.id);
  }

  if (opt1Value === null) {
    return 1;
  }

  if (opt2Value === null) {
    return -1;
  }

  if (params.order === 'asc') {
    if (opt1Value === 0) {
      return 1;
    }
    if (opt2Value === 0) {
      return -1;
    }
  }

  const diff = opt1Value - opt2Value;

  if (diff === 0) {
    return opt1.id.localeCompare(opt2.id);
  }

  return diff * (params.order === 'desc' ? -1 : 1);
};

const getLiquidityFromOption = (
  params: OptionSortParams,
  pricings: { [key: string]: [number, number] },
  opt: Option
): number => {
  if (_.isNil(opt.amount) || _.isNil(opt.underlyingAsset) || _.isNil(pricings[opt.underlyingAsset])) {
    return null;
  }

  if (params.order === 'asc') {
    if (_.isArray(opt.amount)) {
      return new Decimal(opt.amount[0].toString())
        .div(`1e${pricings[opt.underlyingAsset][0]}`)
        .mul(pricings[opt.underlyingAsset][1])
        .toNumber();
    } else {
      return new Decimal(opt.amount.toString())
        .div(`1e${pricings[opt.underlyingAsset][0]}`)
        .mul(pricings[opt.underlyingAsset][1])
        .toNumber();
    }
  } else {
    if (_.isArray(opt.amount)) {
      return new Decimal(opt.amount[1].toString())
        .div(`1e${pricings[opt.underlyingAsset][0]}`)
        .mul(pricings[opt.underlyingAsset][1])
        .toNumber();
    } else {
      return new Decimal(opt.amount.toString())
        .div(`1e${pricings[opt.underlyingAsset][0]}`)
        .mul(pricings[opt.underlyingAsset][1])
        .toNumber();
    }
  }
};

const sortByLiquidity = (
  params: OptionSortParams,
  pricings: { [key: string]: [number, number] },
  opt1: Option,
  opt2: Option
): number => {
  const opt1Value = getLiquidityFromOption(params, pricings, opt1);
  const opt2Value = getLiquidityFromOption(params, pricings, opt2);

  if (opt1Value === opt2Value && opt1Value === null) {
    return opt1.id.localeCompare(opt2.id);
  }

  if (opt1Value === null) {
    return 1;
  }

  if (opt2Value === null) {
    return -1;
  }

  if (params.order === 'asc') {
    if (opt1Value === 0) {
      return 1;
    }
    if (opt2Value === 0) {
      return -1;
    }
  }

  const diff = opt1Value - opt2Value;

  if (diff === 0) {
    return opt1.id.localeCompare(opt2.id);
  }

  return diff * (params.order === 'desc' ? -1 : 1);
};

const getStrikeFromOption = (
  params: OptionSortParams,
  pricings: { [key: string]: [number, number] },
  opt: Option
): number => {
  if (
    _.isNil(opt.strike) ||
    _.isNil(opt.strikeAsset) ||
    _.isNil(pricings[opt.strikeAsset]) ||
    _.isNil(opt.strikeDecimals)
  ) {
    return null;
  }

  if (params.order === 'asc') {
    if (_.isArray(opt.strike)) {
      return new Decimal(opt.strike[0].toString())
        .div(`1e${opt.strikeDecimals}`)
        .mul(pricings[opt.strikeAsset][1])
        .toNumber();
    } else {
      return new Decimal(opt.strike.toString())
        .div(`1e${opt.strikeDecimals}`)
        .mul(pricings[opt.strikeAsset][1])
        .toNumber();
    }
  } else {
    if (_.isArray(opt.strike)) {
      return new Decimal(opt.strike[1].toString())
        .div(`1e${opt.strikeDecimals}`)
        .mul(pricings[opt.strikeAsset][1])
        .toNumber();
    } else {
      return new Decimal(opt.strike.toString())
        .div(`1e${opt.strikeDecimals}`)
        .mul(pricings[opt.strikeAsset][1])
        .toNumber();
    }
  }
};

const sortByStrike = (
  params: OptionSortParams,
  pricings: { [key: string]: [number, number] },
  opt1: Option,
  opt2: Option
): number => {
  const opt1Value = getStrikeFromOption(params, pricings, opt1);
  const opt2Value = getStrikeFromOption(params, pricings, opt2);

  if (opt1Value === opt2Value && opt1Value === null) {
    return opt1.id.localeCompare(opt2.id);
  }

  if (opt1Value === null) {
    return 1;
  }

  if (opt2Value === null) {
    return -1;
  }

  if (params.order === 'asc') {
    if (opt1Value === 0) {
      return 1;
    }
    if (opt2Value === 0) {
      return -1;
    }
  }

  const diff = opt1Value - opt2Value;

  if (diff === 0) {
    return opt1.id.localeCompare(opt2.id);
  }

  return diff * (params.order === 'desc' ? -1 : 1);
};

export const useOptionsSort = (
  options: Option[],
  params: OptionSortParams,
  tokenPricings: { [key: string]: [number, number] }
): Option[] => {
  if (params === null) {
    return options.sort((opt1: Option, opt2: Option) => opt1.id.localeCompare(opt2.id));
  }

  switch (params.field) {
    case 'expiry': {
      return options.sort(sortByExpiry.bind(null, params));
    }
    case 'strike': {
      return options.sort(sortByStrike.bind(null, params, tokenPricings));
    }
    case 'liquidity': {
      return options.sort(sortByLiquidity.bind(null, params, tokenPricings));
    }
    case 'price': {
      return options.sort(sortByPrice.bind(null, params, tokenPricings));
    }
    default: {
      return options.sort((opt1: Option, opt2: Option) => opt1.id.localeCompare(opt2.id));
    }
  }
};

export const OptionsContext = React.createContext<Option[]>([]);

interface OptionsConsumerProps {
  useOptions: () => Option[];
}

const OptionsConsumer: React.FC<React.PropsWithChildren<OptionsConsumerProps>> = ({
  useOptions,
  children,
}: React.PropsWithChildren<OptionsConsumerProps>) => {
  const options = useOptions();

  return <OptionsContext.Provider value={options}>{children}</OptionsContext.Provider>;
};

interface OptionsProviderProps {
  fetchOpt?: FetchOptions;
}

export const OptionsProvider: React.FC<React.PropsWithChildren<OptionsProviderProps>> = ({
  children,
  fetchOpt,
}: React.PropsWithChildren<OptionsProviderProps>): JSX.Element => {
  const optionsObservable = useOptionsObservable(fetchOpt);
  const [useOptions, options$] = bind(optionsObservable, []);

  return (
    <Subscribe source$={options$}>
      <Suspense fallback={<p>LOADING</p>}>
        <OptionsConsumer useOptions={useOptions}>{children}</OptionsConsumer>
      </Suspense>
    </Subscribe>
  );
};

export const useOptionPositionSort = (optionPositions: OptionPosition[]): OptionPosition[] => {
  return optionPositions.sort((opt1: OptionPosition, opt2: OptionPosition) =>
    opt1.positionId.localeCompare(opt2.positionId)
  );
};

export const OptionPositionsContext = React.createContext<[OptionPosition[], () => void]>([[], () => null]);

interface OptionPositionsConsumerProps {
  useOptionPositions: () => OptionPosition[];
  reset: () => void;
}

const OptionPositionsConsumer: React.FC<React.PropsWithChildren<OptionPositionsConsumerProps>> = ({
  useOptionPositions,
  reset,
  children,
}: React.PropsWithChildren<OptionPositionsConsumerProps>) => {
  const options = useOptionPositions();

  return <OptionPositionsContext.Provider value={[options, reset]}>{children}</OptionPositionsContext.Provider>;
};

interface OptionPositionsProviderProps {
  account: string;
}

export const OptionPositionsProvider: React.FC<React.PropsWithChildren<OptionPositionsProviderProps>> = ({
  children,
  account,
}: React.PropsWithChildren<OptionPositionsProviderProps>): JSX.Element => {
  const optionPositionsObservable = useOptionPositionsObservable(account);
  const [useOptionPositions, optionPositions$] = bind(optionPositionsObservable[0], []);

  if (account) {
    return (
      <Subscribe source$={optionPositions$}>
        <Suspense fallback={<p>LOADING</p>}>
          <OptionPositionsConsumer useOptionPositions={useOptionPositions} reset={optionPositionsObservable[1]}>
            {children}
          </OptionPositionsConsumer>
        </Suspense>
      </Subscribe>
    );
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
};
