import React from 'react';
import { useState } from 'react';

export const WalletModalContext = React.createContext<[boolean, (active: boolean) => void]>([false, () => ({})]);

export const WalletModalProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const [open, setOpen] = useState(false);

  return <WalletModalContext.Provider value={[open, setOpen]}>{children}</WalletModalContext.Provider>;
};
