import { Contract, ethers } from 'ethers';

const parsePresetNets = (nets: string): { [key: number]: string } => {
  return nets
    .split('+')
    .map((splitted_nets: string) => {
      const [netId, netName] = splitted_nets.split('::');
      return [parseInt(netId), netName];
    })
    .reduce((agg: { [key: number]: string }, val: [number, string]) => ({ ...agg, [val[0]]: val[1] }), {});
};

export const getArtifact = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  artifacts: any,
  chainId: number,
  name: string,
  provider: ethers.providers.BaseProvider
): Contract => {
  const presetNets = process.env.NX_NETWORKS ? parsePresetNets(process.env.NX_NETWORKS) : {};

  if (!artifacts[chainId]) return null;
  const networkNames = Object.keys(artifacts[chainId]).sort((a, b) => {
    if (a === 'localhost') {
      return -1;
    }
    if (b === 'localhost') {
      return 1;
    }
    return a.localeCompare(b);
  });

  if (presetNets[chainId]) {
    networkNames.push(presetNets[chainId]);
  }

  const artifactMerge = {};
  for (const networkName of networkNames) {
    for (const artifactName of Object.keys(artifacts[chainId][networkName].contracts)) {
      artifactMerge[artifactName] = new Contract(
        artifacts[chainId][networkName].contracts[artifactName].address,
        artifacts[chainId][networkName].contracts[artifactName].abi,
        provider
      );
    }
  }

  return artifactMerge[name] || null;
};

export const applyFee = (value: ethers.BigNumber, fee: ethers.BigNumber): ethers.BigNumber => {
  return value.mul(fee.add(1000000)).div(1000000);
};

export const withoutFee = (value: ethers.BigNumber, fee: ethers.BigNumber): ethers.BigNumber => {
  return value.mul(1000000).div(ethers.BigNumber.from(1000000).add(fee)).add(1);
};
