/* eslint-disable react/jsx-no-useless-fragment */
import { Suspense, lazy } from 'react';

const BuyOptions = lazy(() => import('../app/BuyOptions'));

export const LLBuyOptions = (props: React.ComponentProps<typeof BuyOptions>) => {
  return (
    <Suspense fallback={<></>}>
      <BuyOptions {...props} />
    </Suspense>
  );
};
