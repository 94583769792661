export enum LogLevel {
  OFF = 0,
  ERROR,
  WARNING,
  INFO,
}

export class ScopedLogger {
  constructor(
    public readonly scope: string,
    public readonly level: LogLevel = LogLevel.INFO
  ) {}

  public info(msg: string): void {
    if (this.level >= LogLevel.INFO) {
      console.log(`[TBD][${this.scope}] ${msg}`);
    }
  }

  public warning(msg: string): void {
    if (this.level >= LogLevel.WARNING) {
      console.warn(`[TBD][${this.scope}] ${msg}`);
    }
  }

  public error(msg: Error): void {
    if (this.level >= LogLevel.ERROR) {
      if (msg instanceof Error) {
        console.error(`[TBD][${this.scope}] ${msg.message}`);
        console.error(msg.stack);
      } else {
        console.error(`[TBD][${this.scope}] ${msg}`);
      }
    }
  }
}

export class Logger {
  constructor(public readonly level: LogLevel = LogLevel.INFO) {}

  public getScopedLogger(name: string): ScopedLogger {
    return new ScopedLogger(name, this.level);
  }

  public info(msg: string): void {
    if (this.level >= LogLevel.INFO) {
      console.log(`[TBD] ${msg}`);
    }
  }

  public warning(msg: string): void {
    if (this.level >= LogLevel.WARNING) {
      console.warn(`[TBD] ${msg}`);
    }
  }

  public error(msg: Error): void {
    if (this.level >= LogLevel.ERROR) {
      if (msg instanceof Error) {
        console.error(`[TBD] ${msg.message}`);
        console.error(msg.stack);
      } else {
        console.error(`[TBD] ${msg}`);
      }
    }
  }
}
