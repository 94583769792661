import { useMemo, useState, useEffect } from 'react';
import { ContractCall, useContractCalls, useEthers } from '@usedapp/core';
import { ethers, BigNumber } from 'ethers';
import { Falsy } from '@usedapp/core/dist/esm/src/model/types';
import { UniswapV3FactoryAbi } from './UniswapV3Factory';
import { UniswapV3PoolAbi } from './UniswapV3Pool';
import Decimal from 'decimal.js';
import { ERC20Abi } from './ERC20Abi';
import { Interface } from 'ethers/lib/utils';
import { tl } from '../utils/tl';
import { useMediaQuery } from 'react-responsive';

const UniswapV3FactoryAddress = '0x1F98431c8aD98523631AE4a59f267346ea31F984';
const fees = [500, 3000, 10000];

const PricingTokens = [
  tl('0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'), // USDC
  tl('0x6b175474e89094c44da98b954eedeac495271d0f'), // DAI
  tl('0x5f98805A4E8be255a32880FDeC7F6728C6568bA0'), // LUSD
  tl('0xdac17f958d2ee523a2206206994597c13d831ec7'), // TETHER
];

const FeeForTokens = {
  [tl('0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2')]: [tl('0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'), 3000],
  [tl('0x2260fac5e5542a773aa44fbcfedf7c193bc2c599')]: [tl('0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'), 3000],
};

const PricingTokensDecimals = [6, 18, 18, 6];

const exceptions = ['0xETH', '0xUSD'];

const exceptionsOverride = {
  '0xETH': tl('0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'),
};

const isException = (token: string): boolean => exceptions.indexOf(token) !== -1;
const isPricingToken = (token: string): boolean =>
  PricingTokens.findIndex((v) => v.toLowerCase() === token.toLowerCase()) !== -1;
const getPricingTokenDecimals = (token: string): number =>
  PricingTokensDecimals[PricingTokens.findIndex((v) => v.toLowerCase() === token.toLowerCase())];

const getPoolAddress = async (
  univ3f: ethers.Contract,
  token: string,
  provider: ethers.providers.BaseProvider,
  poolAddresses: { [key: string]: [string, number, number, boolean] }
): Promise<[string, string, number, number, boolean]> => {
  if (isException(token) && !exceptionsOverride[token]) {
    return [token, token, 18, 18, false];
  }

  if (isPricingToken(token)) {
    return [token, '0xUSD', getPricingTokenDecimals(token), getPricingTokenDecimals(token), false];
  }

  const _token = exceptionsOverride[token] || token;

  let best = null;
  let bestLiquidity = BigNumber.from(0);

  if (FeeForTokens[tl(_token)]) {
    const usdTokens = FeeForTokens[tl(_token)][0] as string;
    const addr = await univ3f.getPool(_token, ...FeeForTokens[tl(_token)]);
    if (addr !== '0x0000000000000000000000000000000000000000') {
      const tokenContract = new ethers.Contract(_token, ERC20Abi, provider);
      const pool = new ethers.Contract(addr, UniswapV3PoolAbi, provider);
      const token0 = await pool.token0();
      const decimals = await tokenContract.decimals();
      const liquidity = await tokenContract.balanceOf(addr);
      if (liquidity.gt(bestLiquidity)) {
        bestLiquidity = liquidity;
        if (token0.toLowerCase() !== _token.toLowerCase()) {
          best = [token, addr, getPricingTokenDecimals(usdTokens), decimals, true];
        } else {
          best = [token, addr, decimals, getPricingTokenDecimals(usdTokens), false];
        }
      }
    }
  } else {
    for (const usdTokens of PricingTokens) {
      for (const fee of fees) {
        try {
          const addr = await univ3f.getPool(_token, usdTokens, fee);
          if (addr !== '0x0000000000000000000000000000000000000000') {
            const tokenContract = new ethers.Contract(_token, ERC20Abi, provider);
            const pool = new ethers.Contract(addr, UniswapV3PoolAbi, provider);
            const token0 = await pool.token0();
            const decimals = await tokenContract.decimals();
            const liquidity = await tokenContract.balanceOf(addr);
            if (liquidity.gt(bestLiquidity)) {
              bestLiquidity = liquidity;
              if (token0.toLowerCase() !== _token.toLowerCase()) {
                best = [token, addr, getPricingTokenDecimals(usdTokens), decimals, true];
              } else {
                best = [token, addr, decimals, getPricingTokenDecimals(usdTokens), false];
              }
            }
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  }
  return best || [tl(token), null, 0, 0, false];
};

const poolAddressesToContractCalls = (
  abi: Interface,
  poolAddresses: { [key: string]: [string, number, number, boolean] },
  fetching: boolean[]
): (ContractCall | Falsy)[] => {
  return Object.keys(poolAddresses)
    .sort()
    .map((token: string) => {
      switch (poolAddresses[token][0]) {
        case '0xETH':
        case '0xUSD':
        case null:
          return false;
        default:
          return {
            address: poolAddresses[token][0],
            abi,
            method: 'slot0',
            args: [],
          };
      }
    });
};

const processReturnValue = (
  poolAddresses: { [key: string]: [string, number, number, boolean] },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prices: any[]
): { [key: string]: [number, number] } => {
  const ret = {};

  const poolAddressesKeys = Object.keys(poolAddresses).sort();

  for (let idx = 0; idx < poolAddressesKeys.length; ++idx) {
    switch (poolAddresses[poolAddressesKeys[idx]][0]) {
      case '0xUSD': {
        ret[poolAddressesKeys[idx]] = [poolAddresses[poolAddressesKeys[idx]][1], 1];
        break;
      }
      default: {
        if (prices.length > idx && prices[idx] !== undefined) {
          if (poolAddresses[poolAddressesKeys[idx]][3]) {
            ret[poolAddressesKeys[idx]] = [
              poolAddresses[poolAddressesKeys[idx]][2],
              new Decimal(1)
                .div(
                  new Decimal(
                    prices[idx].sqrtPriceX96
                      .mul(prices[idx].sqrtPriceX96)
                      .mul(`1${'0'.repeat(poolAddresses[poolAddressesKeys[idx]][1])}`)
                      .shr(96 * 2)
                      .toString()
                  ).div(`1e${poolAddresses[poolAddressesKeys[idx]][2]}`)
                )
                .toNumber(),
            ];
          } else {
            ret[poolAddressesKeys[idx]] = [
              poolAddresses[poolAddressesKeys[idx]][1],
              new Decimal(
                prices[idx].sqrtPriceX96
                  .mul(prices[idx].sqrtPriceX96)
                  .mul(`1${'0'.repeat(poolAddresses[poolAddressesKeys[idx]][1])}`)
                  .shr(96 * 2)
                  .toString()
              )
                .div(`1e${poolAddresses[poolAddressesKeys[idx]][2]}`)
                .toNumber(),
            ];
          }
        }
        break;
      }
    }
  }

  for (const exceptionKey of Object.keys(exceptionsOverride)) {
    if (ret[exceptionsOverride[exceptionKey]] && !ret[exceptionKey]) {
      ret[exceptionKey] = ret[exceptionsOverride[exceptionKey]];
    }
  }

  return ret;
};

export const useEthereumPricings = (tokens: string[]): { [key: string]: [number, number] } => {
  const { chainId } = useEthers();
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  });
  return _useEthereumPricings(!(isDesktopOrLaptop && chainId === 1), tokens);
};

export const _useEthereumPricings = (disabled: boolean, tokens: string[]): { [key: string]: [number, number] } => {
  const { library } = useEthers();
  const univ3 = useMemo(
    () =>
      new ethers.Contract(
        UniswapV3FactoryAddress,
        UniswapV3FactoryAbi,
        library as unknown as ethers.providers.BaseProvider
      ),
    [library]
  );
  const poolAbi = useMemo(
    () => new ethers.Contract('0x0000000000000000000000000000000000000000', UniswapV3PoolAbi).interface,
    []
  );
  const [poolAddresses, setPoolAddresses] = useState<{
    [key: string]: [string, number, number, boolean];
  }>({});
  const [toFetch, setToFetch] = useState([[], []]);
  const prices = useContractCalls(poolAddressesToContractCalls(poolAbi, poolAddresses, toFetch[1]));

  useEffect(() => {
    if (toFetch[0].length && !disabled) {
      if (toFetch[1][0] === false) {
        setToFetch([[...toFetch[0]], [true, ...toFetch[1].slice(1)]]);
        (async () => {
          const results = await getPoolAddress(
            univ3,
            toFetch[0][0],
            library as unknown as ethers.providers.BaseProvider,
            poolAddresses
          );
          setPoolAddresses({
            ...poolAddresses,
            [results[0]]: [results[1], results[2], results[3], results[4]],
          });
        })()
          .catch((e) => {
            console.error(e);
            setToFetch([[...toFetch[0].slice(1)], [...toFetch[1].slice(1)]]);
          })
          .then(() => {
            setToFetch([[...toFetch[0].slice(1)], [...toFetch[1].slice(1)]]);
          });
      }
    }
  }, [toFetch, poolAddresses, library, univ3, disabled]);

  useEffect(() => {
    if (!disabled) {
      const newFetch = [];
      for (const token of tokens) {
        if (!poolAddresses[tl(token)] && toFetch[0].indexOf(tl(token)) === -1) {
          newFetch.push(tl(token));
        }
      }
      if (newFetch.length) {
        setToFetch([
          [...toFetch[0], ...newFetch],
          [...toFetch[1], ...[...new Array(newFetch.length)].map(() => false)],
        ]);
      }
    }
  }, [tokens, library, univ3, poolAddresses, setPoolAddresses, toFetch, disabled]);

  return disabled ? {} : processReturnValue(poolAddresses, prices);
};
