import { GasSettings } from '@tbd/sdk';
import Decimal from 'decimal.js';

export const getFeeAsString = (gs: GasSettings): string => {
  if (!gs) {
    return '';
  }
  if (gs.gasPrice) {
    return `${new Decimal(gs.gasPrice.toString()).div('1e9').toFixed(2)} GWEI`;
  } else if (gs.maxFeePerGas) {
    return `${new Decimal(gs.maxFeePerGas.toString()).div('1e9').toFixed(2)} GWEI`;
  } else {
    return '';
  }
};
