import artifacts from '@tbd/contract-artifacts';
import { useEthers } from '@usedapp/core';
import { Contract, providers } from 'ethers';
import { Interface } from 'ethers/lib/utils';
import { useMemo } from 'react';

export interface ContractArtifact {
  networkName: string;
  networkId: number;
  instance: Contract;
  interface: Interface;
  address: string;
}

export const useArtifacts = (): { [key: string]: ContractArtifact } => {
  const { library, chainId } = useEthers();

  return useMemo(() => {
    const networkArtifacts = artifacts[chainId];

    if (!networkArtifacts) {
      return {};
    }

    let networkNames;
    if (process.env.NX_NETWORKS) {
      const presetNets = process.env.NX_NETWORKS.split('+')
        .map((splitted_nets: string) => {
          const [netId, netName] = splitted_nets.split('::');
          return [parseInt(netId), netName];
        })
        .reduce((agg: { [key: number]: string }, val: [number, string]) => ({ ...agg, [val[0]]: val[1] }), {});
      if (presetNets[chainId]) {
        networkNames = [presetNets[chainId]];
      }
    }

    if (!networkNames) {
      networkNames = Object.keys(networkArtifacts);
    }

    const ret: { [key: string]: ContractArtifact } = {};

    for (const networkName of networkNames) {
      for (const contractName of Object.keys(networkArtifacts[networkName].contracts)) {
        const contractDetails = networkArtifacts[networkName].contracts[contractName];
        const instance = new Contract(
          contractDetails.address,
          contractDetails.abi,
          library as unknown as providers.BaseProvider
        );

        ret[contractName] = {
          networkName,
          networkId: chainId,
          instance,
          interface: instance.interface,
          address: contractDetails.address,
        };
      }
    }

    return ret;
  }, [library, chainId]);
};
