import { providers, BigNumber, ethers, PopulatedTransaction } from 'ethers';
import { Observable, Subscriber, TeardownLogic } from 'rxjs';
import { FetchOptions, Option, OptionExpiry, OptionStrike, OptionPosition } from './option';
import * as _ from 'lodash';
import { LogLevel, ScopedLogger } from './logger';
import { GatewayAction } from './gateway';
import { GasSettings } from './sdk';

export interface PlatformConfiguration<T = unknown> {
  [key: number]: T;
}

export interface PlatformDetails {
  name: string;
  primaryColor: string;
  secondaryColor: string;
}

export interface OptionPriceAndAmount {
  prices: ethers.BigNumber[];
  currencies: string[];
  descriptions: string[];
  option: Option;
}

export abstract class Platform<Config = unknown> {
  public readonly logger: ScopedLogger;

  constructor(
    private readonly _name: string,
    private readonly _freq: number,
    private readonly _testMode: boolean,
    level: LogLevel = LogLevel.INFO
  ) {
    this.logger = new ScopedLogger(_name, level);
  }

  public name(): string {
    return this._name;
  }

  public freq(): number {
    return this._freq;
  }

  public testMode(): boolean {
    return this._testMode;
  }

  abstract configs(): Promise<PlatformConfiguration<Config>>;

  abstract supportsPlatformAction(action: string): boolean;

  abstract generatePlatformActionPayload(
    action: string,
    config: Config,
    provider: providers.BaseProvider,
    optionPosition: OptionPosition
  ): Promise<PopulatedTransaction>;

  abstract retrieveAllOptionPositions(
    subscriber: Subscriber<OptionPosition>,
    chainId: number,
    provider: ethers.providers.BaseProvider,
    config: Config,
    account: string
  ): Promise<void>;

  abstract generateGatewayPayload(
    configuration: Config,
    provider: providers.BaseProvider,
    option: Option,
    amount: BigNumber,
    requiredParameters: string[],
    providedParameters: unknown[],
    fee: BigNumber,
    gas: GasSettings,
    caller: string
  ): Promise<GatewayAction>;

  abstract retrieveAllOptions(
    subscriber: Subscriber<Option>,
    networkId: number,
    provider: providers.BaseProvider,
    configuration: Config,
    block: number,
    fetchOpt?: FetchOptions
  ): Promise<void>;

  abstract retrieveOptionPriceAndAmount(
    configuration: Config,
    provider: providers.BaseProvider,
    option: Option,
    amount: BigNumber,
    requiredParameters: string[],
    providedParameters: unknown[],
    fee: BigNumber,
    gas: GasSettings
  ): Promise<OptionPriceAndAmount>;

  private static applyStrikeDecimals(strike: OptionStrike, decimals: number): OptionStrike {
    if (_.isNil(strike)) {
      return strike;
    }
    if (_.isArray(strike)) {
      return [strike[0].mul(`1${'0'.repeat(decimals)}`), strike[1].mul(`1${'0'.repeat(decimals)}`)];
    } else {
      return (strike as BigNumber).mul(`1${'0'.repeat(decimals)}`);
    }
  }

  public static getFetchOptions(
    defaultExpiry: OptionExpiry,
    defaultStrike: OptionStrike,
    strikeDecimals: number,
    fetchOpt?: FetchOptions
  ): [OptionExpiry, OptionStrike] {
    if (fetchOpt) {
      return [
        fetchOpt.expiry || defaultExpiry,
        this.applyStrikeDecimals(fetchOpt.strike, strikeDecimals) || defaultStrike,
      ];
    } else {
      return [defaultExpiry, defaultStrike];
    }
  }

  public static async forEachOptions<T = unknown>(
    expiry: OptionExpiry,
    strike: OptionStrike,
    onEach: (expiry: Date, strike: ethers.BigNumber) => Promise<T>
  ): Promise<T[]> {
    const _expiry: Date[] = _.isArray(expiry) ? (expiry as Date[]) : ([expiry] as Date[]);
    const _strike: ethers.BigNumber[] = _.isArray(strike)
      ? (strike as ethers.BigNumber[])
      : ([strike] as ethers.BigNumber[]);
    const ret: T[] = [];

    for (const __strike of _strike) {
      for (const __expiry of _expiry) {
        ret.push(await onEach(__expiry, __strike));
      }
    }

    return ret;
  }

  public fetch(
    networkId: number,
    provider: providers.BaseProvider,
    configuration: Config,
    fetchOpt?: FetchOptions
  ): Observable<Option> {
    return new Observable<Option>((subscriber: Subscriber<Option>): TeardownLogic => {
      this.retrieveAllOptions(subscriber, networkId, provider, configuration, undefined, fetchOpt)
        .then(() => {
          subscriber.complete();
        })
        .catch((e: Error) => {
          subscriber.error(e);
        });
    });
  }

  public streamOptionPositions(
    networkId: number,
    provider: providers.BaseProvider,
    configuration: Config,
    account: string
  ): Observable<OptionPosition> {
    return new Observable<OptionPosition>((subscriber: Subscriber<OptionPosition>): TeardownLogic => {
      let init = null;
      let running = false;
      let off = false;
      const blockCallback = async (blockNumber: number): Promise<void> => {
        const lastBlockNumber = await provider.getBlockNumber();

        if (
          init === null ||
          (blockNumber >= lastBlockNumber && lastBlockNumber - init >= this._freq && running === false && off === false)
        ) {
          running = true;
          try {
            await this.retrieveAllOptionPositions(subscriber, networkId, provider, configuration, account);
          } catch (e) {
            running = false;
            throw e;
          }
          running = false;

          init = lastBlockNumber;
        }
      };

      provider.getBlockNumber().then(async (blockNumber: number) => {
        if (running === false) {
          running = true;
          try {
            await this.retrieveAllOptionPositions(subscriber, networkId, provider, configuration, account);
          } catch (e) {
            running = false;
            throw e;
          }
          running = false;

          if (init === null) {
            init = blockNumber;
          }
        }
      });

      provider.on('block', blockCallback);

      return () => {
        off = true; // this is because provider.off is not working
        provider.off('block', blockCallback);
      };
    });
  }
  public streamOptions(
    networkId: number,
    provider: providers.BaseProvider,
    configuration: Config,
    fetchOpt?: FetchOptions
  ): Observable<Option> {

    return new Observable<Option>((subscriber: Subscriber<Option>): TeardownLogic => {
      let init = null;
      let running = false;

      let cb;
      let off = false;

      provider.getBlockNumber().then(async (currentBlockNumber: number) => {
        await this.retrieveAllOptions(subscriber, networkId, provider, configuration, currentBlockNumber, fetchOpt);

        init = currentBlockNumber;

        cb = async (blockNumber: number): Promise<void> => {
          if (blockNumber - init >= this._freq && blockNumber > currentBlockNumber && !running && !off) {
            running = true;
            try {
              await this.retrieveAllOptions(subscriber, networkId, provider, configuration, blockNumber, fetchOpt);
            } catch (e) {
              running = false;
              throw e;
            }
            running = false;

            init = blockNumber;
          }
        };

        provider.on('block', cb);
      });

      return () => {
        off = true; // this is because provider.off is not working
        provider.off('block', cb);
      };
    });
  }
}
