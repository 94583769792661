import {
  Platform,
  PlatformConfiguration,
  FetchOptions,
  Option,
  EMPTY_OPTION,
  OptionExpiry,
  LogLevel,
  OptionStrike,
  GatewayAction,
  OptionPosition,
  OptionPositionStatus,
  getArtifact,
  OptionPriceAndAmount,
  GasSettings,
  applyFee,
  OptionType,
} from '@tbd/sdk';
import { Subscriber } from 'rxjs';
import { ethers, providers, BigNumber, PopulatedTransaction } from 'ethers';
import { ERC20Abi } from './abis/ERC20';
import _ from 'lodash';
import artifacts from '@tbd/contract-artifacts';
import { PoolAbi } from './abis/Pool';
import { FacadeAbi } from './abis/Facade';
import { PriceProviderAbi } from './abis/PriceProvider';
import { OptionsManagerAbi } from './abis/OptionsManager';

interface PoolDetails {
  address: string;
  underlying: string;
  type: OptionType;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Hegic8888Config {
  hegicFacadeAddress: string;
  hegicPoolDetails: PoolDetails[];
  hegicOptionsManagerAddress: string;
}

const minBN = (a: BigNumber, b: BigNumber): BigNumber => {
  return a.gt(b) ? b : a;
};

const maxBN = (a: BigNumber, b: BigNumber): BigNumber => {
  return a.gt(b) ? a : b;
};

export class PlatformHegic8888 extends Platform<Hegic8888Config> {
  public constructor(testMode: boolean, level: LogLevel = LogLevel.INFO) {
    super('hegic8888', 10, testMode, level);
  }

  public async configs(): Promise<PlatformConfiguration<Hegic8888Config>> {
    return {
      1: {
        hegicFacadeAddress: '0xd56b5a63dac64990e7eccd046ec7119e38e422dc',
        hegicPoolDetails: [
          {
            // ETH CALL
            address: '0xb9ed94c6d594b2517c4296e24A8c517FF133fb6d',
            underlying: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
            type: 'CALL',
          },
          {
            // ETH PUT
            address: '0x790e96e7452c3c2200bbcaa58a468256d482dd8b',
            underlying: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
            type: 'PUT',
          },
          {
            // BTC CALL
            address: '0xfA77f713901a840B3DF8F2Eb093d95fAC61B215A',
            underlying: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
            type: 'CALL',
          },
          {
            // BTC PUT
            address: '0x7a42a60f8ba4843feea1bd4f08450d2053cc1ab6',
            underlying: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
            type: 'PUT',
          },
        ],
        hegicOptionsManagerAddress: '0x1ba4b447d0df64da64024e5ec47da94458c1e97f',
      },
    };
  }

  public name(): string {
    return 'hegic8888';
  }

  private isValidExpiry(expiry: OptionExpiry): boolean {
    const minimum = Date.now() + 24 * 60 * 60 * 1000;
    const maximum = Date.now() + 45 * 24 * 60 * 60 * 1000;
    if (_.isArray(expiry)) {
      return expiry[0].getTime() < maximum && expiry[1].getTime() > minimum;
    } else {
      const _expiry: Date = expiry as Date;
      return _expiry.getTime() <= maximum && _expiry.getTime() >= minimum;
    }
  }

  private convertExpiryLimits(expiry: [Date, Date], minimum: number, maximum: number): [Date, Date] {
    const ret: [Date, Date] = [expiry[0], expiry[1]];

    if (expiry[0].getTime() < minimum) {
      ret[0] = new Date(minimum);
    }

    if (expiry[1].getTime() > maximum) {
      ret[1] = new Date(maximum);
    }

    return ret;
  }

  private getStrikeMinimum(value: ethers.BigNumber, price: ethers.BigNumber): ethers.BigNumber {
    const minimum = price.mul(75).div(100);
    return minimum.gt(value) ? minimum : value;
  }

  private getStrikeMaximum(value: ethers.BigNumber, price: ethers.BigNumber): ethers.BigNumber {
    const maximum = price.mul(125).div(100);
    return maximum.lt(value) ? maximum : value;
  }

  private strikeOutOfBounds(strike: OptionStrike, price: ethers.BigNumber): boolean {
    const minimum = price.mul(75).div(100);
    const maximum = price.mul(125).div(100);
    if (_.isArray(strike)) {
      return strike[1].lt(minimum) || strike[0].gt(maximum);
    } else {
      return (strike as ethers.BigNumber).gt(maximum) || (strike as ethers.BigNumber).lt(minimum);
    }
  }

  public async generateGatewayPayload(
    configuration: Hegic8888Config,
    provider: providers.BaseProvider,
    option: Option,
    amount: BigNumber,
    requiredParameters: string[],
    providedParameters: unknown[],
    fee: BigNumber,
    gas: GasSettings,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    caller: string
  ): Promise<GatewayAction> {
    const cost = await this.retrieveOptionPriceAndAmount(
      configuration,
      provider,
      option,
      amount,
      requiredParameters,
      providedParameters,
      fee,
      gas
    );

    const expiryIdx = requiredParameters.indexOf('expiry');
    const now = Date.now();

    const expiry = ethers.BigNumber.from(Math.floor(((providedParameters[expiryIdx] as Date).getTime() - now) / 1000));

    const data = ethers.utils.defaultAbiCoder.encode(
      ['address', 'uint256', 'uint256', 'uint256'],
      [option.address, BigNumber.from(expiry), amount, ethers.constants.Zero]
    );

    return {
      actionType: 'hegic8888',
      currencies: cost.currencies,
      amounts: cost.prices,
      data,
    };
  }

  public async retrieveOptionPriceAndAmount(
    configuration: Hegic8888Config,
    provider: providers.BaseProvider,
    option: Option,
    amount: ethers.BigNumber,
    requiredParameters: string[],
    providedParameters: unknown[],
    fee: BigNumber,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    gas: GasSettings
  ): Promise<OptionPriceAndAmount> {
    const Facade = new ethers.Contract(configuration.hegicFacadeAddress, FacadeAbi, provider);

    const now = Math.floor(Date.now() / 1000);

    const expiryIdx = requiredParameters.indexOf('expiry');
    const expiry = BigNumber.from(Math.floor((providedParameters[expiryIdx] as Date).getTime() / 1000) - now);

    const poolDetailsIdx = configuration.hegicPoolDetails.findIndex(
      (pd: PoolDetails) => pd.address.toLowerCase() === option.address.toLowerCase()
    );
    if (poolDetailsIdx === -1) {
      return null;
    }
    const poolDetails = configuration.hegicPoolDetails[poolDetailsIdx];

    const Pool = new ethers.Contract(poolDetails.address, PoolAbi, provider);

    // Recover current asset price
    const priceProviderAddress = await Pool.priceProvider();
    const PriceProvider = await new ethers.Contract(priceProviderAddress, PriceProviderAbi, provider);
    const underlyingAssetPrice = (await PriceProvider.latestRoundData()).answer;
    const CollateralAsset = new ethers.Contract(await Pool.token(), ERC20Abi, provider);

    const cost = (await Facade.getOptionPrice(poolDetails.address, expiry, amount, underlyingAssetPrice, [])).total;

    return {
      prices: [applyFee(cost, fee).mul(102).div(100)],
      currencies: [CollateralAsset.address.toLowerCase()],
      descriptions: ['Premium'],
      option: {
        ...option,
        strike: underlyingAssetPrice,
      },
    };
  }

  public async retrieveAllOptions(
    subscriber: Subscriber<Option>,
    networkId: number,
    provider: providers.BaseProvider,
    configuration: Hegic8888Config,
    block: number,
    fetchOpt?: FetchOptions
  ): Promise<void> {
    const now = Date.now();

    const minimumExpiry = now + 25 * 60 * 60 * 1000;
    const maximumExpiry = now + 45 * 24 * 60 * 60 * 1000;

    const Facade = new ethers.Contract(configuration.hegicFacadeAddress, FacadeAbi, provider);
    for (const poolDetails of configuration.hegicPoolDetails) {
      const Pool = new ethers.Contract(poolDetails.address, PoolAbi, provider);

      // Recover current asset price
      const priceProviderAddress = await Pool.priceProvider();
      const PriceProvider = await new ethers.Contract(priceProviderAddress, PriceProviderAbi, provider);
      const underlyingAssetPrice = (await PriceProvider.latestRoundData()).answer;
      const CollateralAsset = new ethers.Contract(await Pool.token(), ERC20Abi, provider);
      const UnderlyingAsset = new ethers.Contract(poolDetails.underlying, ERC20Abi, provider);
      const [_expiry] = Platform.getFetchOptions(
        [new Date(now + 25 * 60 * 60 * 1000), new Date(now + 45 * 24 * 60 * 60 * 1000)],
        underlyingAssetPrice,
        8,
        fetchOpt
      );

      let expiry = _expiry;
      const strike = underlyingAssetPrice;
      if (fetchOpt.strike) {
        if (
          (_.isArray(fetchOpt.strike) &&
            (fetchOpt.strike[0].gt(underlyingAssetPrice) || fetchOpt.strike[1].lt(underlyingAssetPrice))) ||
          (!_.isArray(fetchOpt.strike) && !strike.eq(underlyingAssetPrice))
        ) {
          subscriber.next({
            ...EMPTY_OPTION,
            id: poolDetails.address,
            address: poolDetails.address,
            platform: 'hegic8888',
            loaded: true,
            disabled: true,
          });
          return;
        }
      }

      if (_.isArray(_expiry)) {
        expiry = this.convertExpiryLimits(_expiry as [Date, Date], minimumExpiry, maximumExpiry);
      }

      const totalBalance = await Pool.totalBalance();
      const lockedAmount = await Pool.lockedAmount();
      const collRatio = await Pool.collateralizationRatio();
      const utilisationRate = await Pool.maxUtilizationRate();

      const collateralAssetAvailableBalance = totalBalance.mul(utilisationRate).div(100).sub(lockedAmount).mul(100).div(collRatio);
      let convertedCollateralAssetAvailableBalance = maxBN(BigNumber.from(0), collateralAssetAvailableBalance);
      const underlyingDecimals = await UnderlyingAsset.decimals();


      let minAmount;
      if (CollateralAsset.address.toLowerCase() !== poolDetails.underlying.toLowerCase()) {
        convertedCollateralAssetAvailableBalance = collateralAssetAvailableBalance
          .mul(`1${'0'.repeat(underlyingDecimals)}`)
          .mul('100')
          .div(underlyingAssetPrice);
        minAmount = minBN(
          BigNumber.from(`1${'0'.repeat(underlyingDecimals)}`),
          convertedCollateralAssetAvailableBalance
        );
      } else {
        minAmount = minBN(BigNumber.from(`1${'0'.repeat(underlyingDecimals)}`), collateralAssetAvailableBalance);
      }
      minAmount = maxBN(BigNumber.from(0), minAmount);

      const costComputer = async (expiry: Date, strike: ethers.BigNumber): Promise<ethers.BigNumber> => {
        const period = Math.floor((expiry.getTime() - now) / 1000);
        if (minAmount.eq(0)) {
          return BigNumber.from(0);
        }
        return (await Facade.getOptionPrice(poolDetails.address, period, minAmount, strike, [])).total;
      };

      const costs = await Platform.forEachOptions(expiry, strike, costComputer);

      const extremaCosts = [costs.reduce((a, b) => (a.gt(b) ? b : a)), costs.reduce((a, b) => (a.gt(b) ? a : b))];

      subscriber.next({
        id: poolDetails.address.toLowerCase(),
        address: poolDetails.address.toLowerCase(),
        platform: 'hegic8888',
        loaded: true,
        network: null,
        disabled: false,
        type: poolDetails.type,
        nature: 'US',
        expiry,
        strike,
        strikeDecimals: 8,
        amount: convertedCollateralAssetAvailableBalance,
        price: convertedCollateralAssetAvailableBalance.eq(0)
          ? BigNumber.from(0)
          : (extremaCosts as [BigNumber, BigNumber]),
        premiumAsset: CollateralAsset.address.toLowerCase(),
        underlyingAsset: UnderlyingAsset.address.toLowerCase(),
        strikeAsset: '0xUSD',
        collateralAsset: CollateralAsset.address.toLowerCase(),
        extraData: null,
      });
      this.logger.info(`#${block.toLocaleString()} + ${UnderlyingAsset.address} ${poolDetails.type} US`);
      // console.log(strike, expiry);
    }

    return;
  }

  private getStatusFromHegicState(state: number, profits: BigNumber, exercisable: boolean): OptionPositionStatus {
    switch (state) {
      default:
      case 0: {
        return 'inactive';
      }
      case 1: {
        if (exercisable) {
          if (profits.gt(0)) {
            return 'profitable';
          }
          return 'exercisable';
        }
        return 'active';
      }
      case 2: {
        return 'exercised';
      }
      case 3: {
        return 'expired';
      }
    }
  }

  public async retrieveAllOptionPositions(
    subscriber: Subscriber<OptionPosition>,
    chainId: number,
    provider: ethers.providers.BaseProvider,
    config: Hegic8888Config,
    account: string
  ): Promise<void> {
    const Gateway = getArtifact(artifacts, chainId, 'Gateway', provider);
    if (!Gateway) {
      throw new Error(`Missing Gateway artifact on chain ${chainId}`);
    }
    const logFilter = Gateway.filters.ExecutedAction('hegic8888', account);
    const logs = (await provider.getLogs({ fromBlock: 0, ...logFilter })).map((l) => ({
      blockNumber: l.blockNumber,
      ...Gateway.interface.parseLog(l),
    }));

    const OptionsManager = new ethers.Contract(config.hegicOptionsManagerAddress, OptionsManagerAbi, provider);
    const idsFromTbd: string[] = [];

    for (const log of logs) {
      const block = await provider.getBlock(log.blockNumber);
      const creationDate = new Date(block.timestamp * 1000);

      const inputData = log.args[5];
      const [price] = log.args[3];
      const [poolAddress] = ethers.utils.defaultAbiCoder.decode(
        ['address', 'uint256', 'uint256', 'uint256'],
        inputData
      );
      const poolIdx = config.hegicPoolDetails.findIndex(
        (pd: PoolDetails) => pd.address.toLowerCase() === poolAddress.toLowerCase()
      );
      if (poolIdx === -1) {
        continue;
      }
      const outputData = log.args[6];
      const [optionId] = ethers.utils.defaultAbiCoder.decode(['uint256'], outputData);

      const Pool = new ethers.Contract(poolAddress, PoolAbi, provider);
      const PriceProvider = new ethers.Contract(await Pool.priceProvider(), PriceProviderAbi, provider);

      const underlyingAssetPrice = (await PriceProvider.latestRoundData()).answer;
      const { amount, state, strike, expired } = await Pool.options(optionId);
      const poolDetails = config.hegicPoolDetails[poolIdx];
      const collateralAsset = await Pool.token();

      const rawProfits = await Pool.profitOf(optionId);
      const profits = rawProfits.sub(price);
      const exercisable =
        (poolDetails.type === 'PUT' ? underlyingAssetPrice.lte(strike) : underlyingAssetPrice.gte(strike)) &&
        rawProfits.gt(0);

      subscriber.next({
        option: {
          id: poolAddress.toLowerCase(),
          address: poolAddress.toLowerCase(),
          platform: 'hegic8888',
          loaded: true,
          network: chainId,
          disabled: false,
          type: poolDetails.type,
          nature: 'US',
          expiry: new Date(expired * 1000),
          amount,
          strike,
          strikeDecimals: 8,
          price,
          premiumAsset: collateralAsset.toLowerCase(),
          underlyingAsset: poolDetails.underlying.toLowerCase(),
          strikeAsset: '0xUSD',
          collateralAsset: collateralAsset.toLowerCase(),
        },
        creationDate,
        fungible: false,
        amount: null,
        positionId: optionId.toString(),
        exercised: state === 2,
        expired: state === 3,
        exercisable,
        profitAsset: collateralAsset.toLowerCase(),
        profits,
        status: this.getStatusFromHegicState(state, profits, exercisable),
      });

      idsFromTbd.push(optionId.toString());
    }

    const ERC721TransferFromEvents = OptionsManager.filters.Transfer(account, null, null);
    const ERC721TransferToEvents = OptionsManager.filters.Transfer(null, account, null);
    const transferFromEvents = (await provider.getLogs({ fromBlock: 0, ...ERC721TransferFromEvents })).map((l) => ({
      blockNumber: l.blockNumber,
      ...OptionsManager.interface.parseLog(l),
    }));
    const transferToEvents = (await provider.getLogs({ fromBlock: 0, ...ERC721TransferToEvents })).map((l) => ({
      blockNumber: l.blockNumber,
      ...OptionsManager.interface.parseLog(l),
    }));

    const mergedEvents = transferFromEvents.concat(transferToEvents);
    const conflictingIds: { [key: string]: boolean } = idsFromTbd.reduce(
      (agg: { [key: string]: boolean }, v: string): { [key: string]: boolean } => ({ ...agg, [v]: true }),
      {}
    );
    const idsToFetch: [string, number][] = [];

    for (let idx = 0; idx < mergedEvents.length; ++idx) {
      const mergedEvent = mergedEvents[idx];
      if (mergedEvents.slice(idx + 1).findIndex((v) => v.blockNumber === mergedEvent.blockNumber) !== -1) {
        conflictingIds[mergedEvent.args[2].toString()] = true;
        const registeredIdx = idsToFetch.indexOf(mergedEvent.args[2].toString());
        if (registeredIdx !== -1) {
          idsToFetch[registeredIdx][1] = mergedEvent.blockNumber;
        } else {
          idsToFetch.push(mergedEvent.args[2].toString());
        }
      }
    }

    const filteredSortedEvents = mergedEvents
      .filter((v) => conflictingIds[v.args[2].toString()] !== true)
      .sort((va, vb) => va.blockNumber - vb.blockNumber);
    const idsOwned: { [key: string]: [boolean, number] } = {};

    for (const ev of filteredSortedEvents) {
      if (ev.args[0].toLowerCase() === account.toLowerCase()) {
        idsOwned[ev.args[2].toString()] = [false, null];
      } else if (ev.args[1].toLowerCase() === account.toLowerCase()) {
        idsOwned[ev.args[2].toString()] = [true, ev.blockNumber];
      }
    }

    for (const optionIds of Object.keys(idsOwned)) {
      if (idsOwned[optionIds][0] === true) {
        idsToFetch.push([optionIds, idsOwned[optionIds][1]]);
      }
    }

    for (const [optionId, blockNumber] of idsToFetch) {
      const owner = await OptionsManager.ownerOf(optionId);
      if (owner.toLowerCase() !== account.toLowerCase()) {
        continue;
      }
      const poolAddress = await OptionsManager.tokenPool(optionId);
      const poolIdx = config.hegicPoolDetails.findIndex(
        (pd: PoolDetails) => pd.address.toLowerCase() === poolAddress.toLowerCase()
      );
      if (poolIdx === -1) {
        continue;
      }

      const block = await provider.getBlock(blockNumber);
      const creationDate = new Date(block.timestamp * 1000);

      const Pool = new ethers.Contract(poolAddress, PoolAbi, provider);
      const PriceProvider = new ethers.Contract(await Pool.priceProvider(), PriceProviderAbi, provider);

      const acquiredEventFilter = Pool.filters.Acquired(BigNumber.from(optionId));
      const acquiredEvents = (await provider.getLogs({ fromBlock: 0, ...acquiredEventFilter })).map((l) => ({
        blockNumber: l.blockNumber,
        ...Pool.interface.parseLog(l),
      }));

      let price = null;

      if (acquiredEvents.length) {
        price = acquiredEvents[0].args.premium.add(acquiredEvents[0].args.settlementFee);
      }

      console.log(acquiredEvents);
      const underlyingAssetPrice = (await PriceProvider.latestRoundData()).answer;
      const { amount, state, strike, expired } = await Pool.options(optionId);
      const poolDetails = config.hegicPoolDetails[poolIdx];
      const collateralAsset = await Pool.token();

      const rawProfits = await Pool.profitOf(optionId);
      const profits = price !== null ? rawProfits.sub(price) : rawProfits;
      const exercisable =
        (poolDetails.type === 'PUT' ? underlyingAssetPrice.lte(strike) : underlyingAssetPrice.gte(strike)) &&
        rawProfits.gt(0);

      subscriber.next({
        option: {
          id: poolAddress.toLowerCase(),
          address: poolAddress.toLowerCase(),
          platform: 'hegic8888',
          loaded: true,
          network: chainId,
          disabled: false,
          type: poolDetails.type,
          nature: 'US',
          expiry: new Date(expired * 1000),
          amount,
          strike,
          strikeDecimals: 8,
          price,
          premiumAsset: collateralAsset.toLowerCase(),
          underlyingAsset: poolDetails.underlying.toLowerCase(),
          strikeAsset: '0xUSD',
          collateralAsset: collateralAsset.toLowerCase(),
        },
        creationDate,
        fungible: false,
        amount: null,
        positionId: optionId.toString(),
        exercised: state === 2,
        expired: state === 3,
        exercisable,
        profitAsset: collateralAsset.toLowerCase(),
        profits,
        status: this.getStatusFromHegicState(state, profits, exercisable),
      });
    }
  }

  private readonly actions: string[] = ['exercise'];

  public supportsPlatformAction(action: string): boolean {
    return this.actions.includes(action);
  }

  public async generatePlatformActionPayload(
    action: string,
    config: Hegic8888Config,
    provider: providers.BaseProvider,
    optionPosition: OptionPosition
  ): Promise<PopulatedTransaction> {
    const Pool = new ethers.Contract(optionPosition.option.address, PoolAbi, provider);
    switch (action) {
      default:
        return null;
      case 'exercise': {
        return Pool.populateTransaction.exercise(optionPosition.positionId);
      }
    }
  }
}
