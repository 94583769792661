import {
  Platform,
  PlatformConfiguration,
  FetchOptions,
  Option,
  EMPTY_OPTION,
  LogLevel,
  OptionPrice,
  GatewayAction,
  OptionPosition,
  OptionPriceAndAmount,
  applyFee,
  GasSettings,
  withoutFee,
  getArtifact,
} from '@tbd/sdk';
import { Subscriber } from 'rxjs';
import { ethers, providers, BigNumber, PopulatedTransaction } from 'ethers';
import * as _ from 'lodash';
import { OptionsFactoryAbi } from './abis/OptionsFactory';
import { ZeroExMeshClient, OrderWithMetadata } from '@tbd/0x-client';
import { Decimal } from 'decimal.js';
import { ERC20Abi } from './abis/ERC20';
import { ZeroExV4Exchange } from './abis/ZeroExV4Exchange';
import artifacts from '@tbd/contract-artifacts';
import { oTokenAbi } from './abis/oToken';
import { OpynOracleAbi } from './abis/OpynOracle';

export interface OpynConfig {
  otokenFactory: string;
  otokenFactoryDeploymentBlock: number;
  oxmClient: ZeroExMeshClient;
  primaryPaymentToken: string;
  zeroExV4ExchangeAddress: string;
  opynOracleAddress: string;
}

interface OTokenDetails {
  strikePrice: ethers.BigNumber;
  underlying: string;
  collateral: string;
  strike: string;
  tokenAddress: string;
  expiry: ethers.BigNumber;
  isPut: boolean;
}

const warnings: string[] = [
  'Opyn uses an off-chain orderbook called 0x as its primary market. You cannot change your gas fee after the transaction is sent (no speedup), otherwise the transaction will fail. You can select your desired transaction speed from the settings BEFORE proceeding.',
];

const lc = (v: string) => (v ? v.toLowerCase() : v);

export class PlatformOpyn extends Platform<OpynConfig> {
  public constructor(testMode: boolean, level: LogLevel = LogLevel.INFO) {
    super('opyn', 10, testMode, level);
  }

  public async configs(): Promise<PlatformConfiguration<OpynConfig>> {
    if (this.testMode()) {
      return {
        1: {
          otokenFactory: '0x7C06792Af1632E77cb27a558Dc0885338F4Bdf8E',
          otokenFactoryDeploymentBlock: 11544447,
          oxmClient: new ZeroExMeshClient('http://127.0.0.1:3000'),
          primaryPaymentToken: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
          zeroExV4ExchangeAddress: '0xDef1C0ded9bec7F1a1670819833240f027b25EfF',
          opynOracleAddress: '0x789cd7ab3742e23ce0952f6bc3eb3a73a0e08833',
        },
        42: {
          otokenFactory: '0xb9d17ab06e27f63d0fd75099d5874a194ee623e2',
          otokenFactoryDeploymentBlock: 22854790,
          oxmClient: new ZeroExMeshClient('http://127.0.0.1:3000'),
          primaryPaymentToken: '0x7e6edA50d1c833bE936492BF42C1BF376239E9e2',
          zeroExV4ExchangeAddress: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
          opynOracleAddress: '0x32724C61e948892A906f5EB8892B1E7e6583ba1f',
        },
      };
    } else {
      return {
        1: {
          otokenFactory: '0x7C06792Af1632E77cb27a558Dc0885338F4Bdf8E',
          otokenFactoryDeploymentBlock: 11544447,
          oxmClient: new ZeroExMeshClient('https://opyn.api.0x.org/'),
          primaryPaymentToken: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
          zeroExV4ExchangeAddress: '0xDef1C0ded9bec7F1a1670819833240f027b25EfF',
          opynOracleAddress: '0x789cd7ab3742e23ce0952f6bc3eb3a73a0e08833',
        },
        42: {
          otokenFactory: '0xb9d17ab06e27f63d0fd75099d5874a194ee623e2',
          otokenFactoryDeploymentBlock: 22854790,
          oxmClient: new ZeroExMeshClient('https://kovan.api.0x.org/'),
          primaryPaymentToken: '0x7e6edA50d1c833bE936492BF42C1BF376239E9e2',
          zeroExV4ExchangeAddress: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
          opynOracleAddress: '0x32724C61e948892A906f5EB8892B1E7e6583ba1f',
        },
      };
    }
  }

  private otokens: { [key: string]: OTokenDetails[] } = {};
  private failedRounds = 0;

  private getConfigurationSignature(fetchOptions: FetchOptions): string {
    if (!fetchOptions) {
      return ethers.utils.keccak256(Buffer.from(''));
    } else {
      const keys = Object.keys(fetchOptions).sort((a, b) => a.localeCompare(b));
      let str = '';
      for (const key of keys) {
        str += `${key}=${fetchOptions[key]}`;
      }
      return ethers.utils.keccak256(Buffer.from(str));
    }
  }

  public async generateGatewayPayload(
    configuration: OpynConfig,
    provider: providers.BaseProvider,
    option: Option,
    amount: BigNumber,
    requiredParameters: string[],
    providedParameters: unknown[],
    fee: BigNumber,
    gas: GasSettings,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    caller: string
  ): Promise<GatewayAction> {
    const optionPriceAndAmount = await this.retrieveOptionPriceAndAmount(
      configuration,
      provider,
      option,
      amount,
      requiredParameters,
      providedParameters,
      fee,
      gas
    );
    const order: OrderWithMetadata = option.extraData as OrderWithMetadata;
    if (!order) {
      throw new Error('Missing order in option extra data');
    }

    const ZeroExV4ExchangeInstance = new ethers.Contract(
      configuration.zeroExV4ExchangeAddress,
      ZeroExV4Exchange,
      provider
    );

    const tx = await ZeroExV4ExchangeInstance.populateTransaction.fillLimitOrder(
      [
        order.order.makerToken,
        order.order.takerToken,
        order.order.makerAmount,
        order.order.takerAmount,
        order.order.takerTokenFeeAmount,
        order.order.maker,
        order.order.taker,
        order.order.sender,
        order.order.feeRecipient,
        order.order.pool,
        order.order.expiry,
        order.order.salt,
      ],
      [order.order.signature.signatureType, order.order.signature.v, order.order.signature.r, order.order.signature.s],
      withoutFee(optionPriceAndAmount.prices[0], fee)
    );

    const encodedData = ethers.utils.defaultAbiCoder.encode(['address', 'bytes'], [option.address, tx.data]);

    return {
      actionType: 'opyn',
      currencies: optionPriceAndAmount.currencies,
      amounts: optionPriceAndAmount.prices,
      data: encodedData,
    };
  }

  public async retrieveOptionPriceAndAmount(
    configuration: OpynConfig,
    provider: providers.BaseProvider,
    option: Option,
    amount: ethers.BigNumber,
    requiredParameters: string[],
    providedParameters: unknown[],
    fee: BigNumber,
    gas: GasSettings
  ): Promise<OptionPriceAndAmount> {
    const ZeroExExchange = new ethers.Contract(configuration.zeroExV4ExchangeAddress, ZeroExV4Exchange, provider);
    const multi = BigNumber.from(await ZeroExExchange.getProtocolFeeMultiplier());

    const updatedOption = await this.retrieveOption(
      provider,
      option.address,
      configuration.primaryPaymentToken,
      configuration.oxmClient,
      null,
      null,
      null,
      null,
      null,
      null
    );

    const ask: OrderWithMetadata = updatedOption.extraData as OrderWithMetadata;

    if (multi.gt(0)) {
      if (ask) {
        const underlyingToken = new ethers.Contract(updatedOption.underlyingAsset, ERC20Abi, provider);
        const price = ethers.BigNumber.from(
          new Decimal(ask.order.takerAmount.toString()).div(ask.order.makerAmount.toString()).mul('1e8').toFixed(0)
        );

        return {
          prices: [
            applyFee(price.mul(amount).div(`1${'0'.repeat(await underlyingToken.decimals())}`), fee),
            applyFee(multi.mul(gas.gasPrice || gas.maxFeePerGas), fee),
          ],
          currencies: [updatedOption.premiumAsset, '0xETH'],
          descriptions: ['Premium', '0x Exchange Fee'],
          option: {
            ...option,
            ...updatedOption,
            id: option.id,
          },
        };
      } else {
        return {
          prices: [ethers.BigNumber.from(0), ethers.BigNumber.from(0)],
          currencies: [updatedOption.premiumAsset, '0xETH'],
          descriptions: ['Premium', '0x Exchange Fee'],
          option: {
            ...option,
            ...updatedOption,
            id: option.id,
          },
        };
      }
    } else {
      if (ask) {
        const underlyingToken = new ethers.Contract(updatedOption.underlyingAsset, ERC20Abi, provider);
        const price = ethers.BigNumber.from(
          new Decimal(ask.order.takerAmount.toString()).div(ask.order.makerAmount.toString()).mul('1e8').toFixed(0)
        );

        return {
          prices: [applyFee(price.mul(amount).div(`1${'0'.repeat(await underlyingToken.decimals())}`), fee)],
          currencies: [updatedOption.premiumAsset],
          descriptions: ['Premium'],
          option: {
            ...option,
            ...updatedOption,
            id: option.id,
          },
        };
      } else {
        return {
          prices: [ethers.BigNumber.from(0)],
          currencies: [updatedOption.premiumAsset],
          descriptions: ['Premium'],
          option: {
            ...option,
            ...updatedOption,
            id: option.id,
          },
        };
      }
    }
  }

  private async fromBatchOrFetch(
    oTokenContract: ethers.Contract,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    batchedInfo: any,
    field: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<[any, any]> {
    if (batchedInfo === undefined) {
      try {
        batchedInfo = await oTokenContract.getOtokenDetails();
      } catch (e) {
        batchedInfo = null;
      }
    }

    if (batchedInfo && batchedInfo[field]) {
      return [batchedInfo, batchedInfo[field]];
    }

    return [batchedInfo, await oTokenContract[field]()];
  }

  private async retrieveOption(
    provider: ethers.providers.BaseProvider,
    oToken: string,
    primaryPaymentToken: string,
    oxmClient: ZeroExMeshClient,
    underlying: string,
    strike: string,
    collateral: string,
    strikePrice: BigNumber,
    isPut: boolean,
    expiry: BigNumber
  ): Promise<Partial<Option>> {
    const oTokenContract = new ethers.Contract(oToken, oTokenAbi, provider);
    let batchedInfo;

    if (underlying === null) {
      [batchedInfo, underlying] = await this.fromBatchOrFetch(oTokenContract, batchedInfo, 'underlyingAsset');
    }

    if (strike === null) {
      [batchedInfo, strike] = await this.fromBatchOrFetch(oTokenContract, batchedInfo, 'strikeAsset');
    }

    if (collateral === null) {
      [batchedInfo, collateral] = await this.fromBatchOrFetch(oTokenContract, batchedInfo, 'collateralAsset');
    }

    if (strikePrice === null) {
      [batchedInfo, strikePrice] = await this.fromBatchOrFetch(oTokenContract, batchedInfo, 'strikePrice');
    }

    if (isPut === null) {
      [batchedInfo, isPut] = await this.fromBatchOrFetch(oTokenContract, batchedInfo, 'isPut');
    }

    if (expiry === null) {
      [batchedInfo, expiry] = await this.fromBatchOrFetch(oTokenContract, batchedInfo, 'expiryTimestamp');
    }

    const orderbook = await oxmClient.getOrderbook({
      perPage: 100,
      baseToken: oToken,
      quoteToken: primaryPaymentToken,
    });

    const filteredAsks = orderbook.asks.records.filter((owm: OrderWithMetadata): boolean => {
      const willNotExpireShortly = parseInt(owm.order.expiry) - Date.now() / 1000 > 90; // 1m30 max
      const isOpen = owm.order.taker === `0x${'0'.repeat(40)}`;
      const noTakerFee = owm.order.takerTokenFeeAmount === '0';
      const notFilled = owm.metaData.remainingFillableTakerAmount !== '0';
      return willNotExpireShortly && isOpen && noTakerFee && notFilled;
    });

    if (filteredAsks.length) {
      const underlyingToken = new ethers.Contract(underlying, ERC20Abi, provider);
      const decimals = await underlyingToken.decimals();
      const bestAsk = filteredAsks[0];
      const totalAmount = ethers.BigNumber.from(bestAsk.metaData.remainingFillableTakerAmount)
        .mul(bestAsk.order.makerAmount)
        .div(bestAsk.order.takerAmount)
        .mul(`1${'0'.repeat(decimals - 8)}`);
      const price: OptionPrice = ethers.BigNumber.from(bestAsk.order.takerAmount.toString())
        .mul(`1${'0'.repeat(8)}`)
        .div(bestAsk.order.makerAmount.toString());

      return {
        id: oToken.toLowerCase(),
        address: oToken,
        loaded: true,
        disabled: false,
        type: isPut ? 'PUT' : 'CALL',
        nature: 'EU',
        expiry: new Date(parseInt(expiry.toString()) * 1000),
        strike: strikePrice,
        strikeDecimals: 8,
        underlyingAsset: lc(underlying),
        collateralAsset: lc(collateral),
        strikeAsset: lc(strike),
        premiumAsset: lc(primaryPaymentToken),
        amount: totalAmount,
        price,
        extraData: bestAsk,
        warnings,
      };
    } else {
      return {
        id: oToken.toLowerCase(),
        address: oToken,
        loaded: true,
        disabled: false,
        type: isPut ? 'PUT' : 'CALL',
        nature: 'EU',
        expiry: new Date(parseInt(expiry.toString()) * 1000),
        strike: strikePrice,
        strikeDecimals: 8,
        underlyingAsset: lc(underlying),
        collateralAsset: lc(collateral),
        strikeAsset: lc(strike),
        premiumAsset: lc(primaryPaymentToken),
        amount: ethers.constants.Zero,
        price: ethers.constants.Zero,
        extraData: null,
        warnings,
      };
    }
  }

  public async retrieveAllOptions(
    subscriber: Subscriber<Option>,
    networkId: number,
    provider: providers.BaseProvider,
    configuration: OpynConfig,
    block: number,
    fetchOptions?: FetchOptions
  ): Promise<void> {
    if (this.failedRounds >= 3) {
      this.logger.warning(`fetcher disabled`);
      return;
    }
    const configKey = this.getConfigurationSignature(fetchOptions);
    const [expiry, strike] = Platform.getFetchOptions(
      [new Date(), new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)],
      [ethers.constants.Zero, ethers.constants.MaxUint256],
      8,
      fetchOptions
    );

    if (!this.otokens[configKey] || block % 10 === 0) {
      const OTokensFactory = new ethers.Contract(configuration.otokenFactory, OptionsFactoryAbi, provider);
      const oTokenCreationFilter = OTokensFactory.filters.OtokenCreated();
      const oTokenLogs = (
        await provider.getLogs({
          ...oTokenCreationFilter,
          fromBlock: configuration.otokenFactoryDeploymentBlock,
        })
      )
        .map((l) => OTokensFactory.interface.parseLog(l))
        .filter((l) => {
          const parsedExpiry = new Date(parseInt(l.args.expiry.toString()) * 1000);
          if (_.isArray(expiry)) {
            return parsedExpiry.getTime() > expiry[0].getTime() && parsedExpiry.getTime() < expiry[1].getTime();
          } else {
            return parsedExpiry.getTime() === (expiry as Date).getTime();
          }
        })
        .filter((l) => {
          if (_.isArray(strike)) {
            return l.args.strikePrice.gte(strike[0]) && l.args.strikePrice.lte(strike[1]);
          } else {
            return l.args.strikePrice.eq(strike);
          }
        });

      for (const oTokenLog of oTokenLogs) {
        const opt: Option = {
          ...EMPTY_OPTION,
          id: oTokenLog.args.tokenAddress.toLowerCase(),
          loaded: false,
          disabled: false,
          type: oTokenLog.args.isPut ? 'PUT' : 'CALL',
          nature: 'EU',
          expiry: new Date(parseInt(oTokenLog.args.expiry.toString()) * 1000),
          strike: oTokenLog.args.strikePrice,
          strikeDecimals: 8,
          underlyingAsset: oTokenLog.args.underlying,
          collateralAsset: oTokenLog.args.collateral,
          strikeAsset: oTokenLog.args.strike,
          premiumAsset: configuration.primaryPaymentToken,
        };
        subscriber.next(opt);
      }

      this.otokens[configKey] = oTokenLogs.map((l) => ({
        strikePrice: l.args.strikePrice,
        underlying: l.args.underlying,
        collateral: l.args.collateral,
        strike: l.args.strike,
        tokenAddress: l.args.tokenAddress,
        expiry: l.args.expiry,
        isPut: l.args.isPut,
      }));
    }

    if (configuration.oxmClient) {
      const rounds = [];
      rounds.push(this.otokens[configKey]);
      let idx = 0;
      for (; idx < rounds.length && idx < 2; ++idx) {
        if (rounds[idx].length) {
          rounds.push([]);
        } else {
          continue;
        }

        if (idx > 0) {
          this.logger.warning(`Running opyn round ${idx + 1}`);
          await new Promise((ok) => setTimeout(ok, 1000 * idx));
        }

        const otokenFetchPromises = [];

        for (const oToken of rounds[idx]) {
          otokenFetchPromises.push(
            (async () => {
              try {
                const opt = {
                  ...EMPTY_OPTION,
                  ...(await this.retrieveOption(
                    provider,
                    oToken.tokenAddress,
                    configuration.primaryPaymentToken,
                    configuration.oxmClient,
                    oToken.underlying,
                    oToken.strike,
                    oToken.collateral,
                    oToken.strikePrice,
                    oToken.isPut,
                    oToken.expiry
                  )),
                };

                this.logger.info(
                  `#${block.toLocaleString()} + ${opt.underlyingAsset} ${opt.type} ${opt.nature} $${
                    parseInt(opt.strike.toString()) / 100000000
                  } ${opt.expiry.toLocaleString()}`
                );
                subscriber.next(opt);
              } catch (e) {
                console.error(`Error while fetching ${oToken.tokenAddress}, adding to queue`);
                // this.logger.error(e);
                rounds[idx + 1].push(oToken);
              }
            })().catch((e) => this.logger.error(e))
          );
        }

        await Promise.all(otokenFetchPromises);
      }

      if (idx < rounds.length && rounds[idx].length) {
        ++this.failedRounds;
      } else {
        this.failedRounds = 0;
      }
    }

    return;
  }

  public async retrieveAllOptionPositions(
    subscriber: Subscriber<OptionPosition>,
    chainId: number,
    provider: ethers.providers.BaseProvider,
    config: OpynConfig,
    account: string
  ): Promise<void> {
    const Gateway = getArtifact(artifacts, chainId, 'Gateway', provider);
    if (!Gateway) {
      throw new Error(`Missing Gateway artifact on chain ${chainId}`);
    }
    const logFilter = Gateway.filters.ExecutedAction('opyn', account);
    const logs = (await provider.getLogs({ fromBlock: 0, ...logFilter })).map((l) => ({
      blockNumber: l.blockNumber,
      ...Gateway.interface.parseLog(l),
    }));
    const oTokens = [];
    for (const log of logs) {
      const [takerToken, makerToken, takerAmount, makerAmount] = ethers.utils.defaultAbiCoder.decode(
        ['address', 'address', 'uint128', 'uint128'],
        log.args[6]
      );
      const block = await provider.getBlock(log.blockNumber);
      oTokens.push([takerToken, makerToken, takerAmount, block.timestamp, makerAmount]);
    }

    const extraOtoken = {};

    let idx = 0;

    for (const [takerToken, oToken, _amountPaid, timestamp, _makerAmount] of oTokens) {
      const oTokenContract = new ethers.Contract(oToken, oTokenAbi, provider);
      let balance = extraOtoken[oToken]?.balance ?? (await oTokenContract.balanceOf(account));
      let makerAmount = _makerAmount;
      let amountPaid = _amountPaid;
      if (balance.eq(0)) {
        continue;
      }
      if (balance.lte(makerAmount)) {
        makerAmount = balance;
        balance = ethers.BigNumber.from(0);
      }
      if (makerAmount.lt(_makerAmount)) {
        amountPaid = amountPaid.mul(makerAmount).div(_makerAmount);
      }
      const oTokenDecimals = await oTokenContract.decimals();
      const TakerAssetContract = new ethers.Contract(takerToken, ERC20Abi, provider);
      const takerAssetDecimals = await TakerAssetContract.decimals();
      let [collateralAsset, underlyingAsset, strikeAsset, strikePrice, expiryTimestamp, isPut] = [
        null,
        null,
        null,
        null,
        null,
        null,
      ];
      try {
        [collateralAsset, underlyingAsset, strikeAsset, strikePrice, expiryTimestamp, isPut] =
          await oTokenContract.getOtokenDetails();
      } catch (e) {
        collateralAsset = await oTokenContract.collateralAsset();
        underlyingAsset = await oTokenContract.underlyingAsset();
        strikeAsset = await oTokenContract.strikeAsset();
        strikePrice = await oTokenContract.strikePrice();
        expiryTimestamp = await oTokenContract.expiryTimestamp();
        isPut = await oTokenContract.isPut();
      }
      const collateralAssetContract = new ethers.Contract(collateralAsset, ERC20Abi, provider);
      const collateralDecimals = await collateralAssetContract.decimals();
      const underlyingAssetContract = new ethers.Contract(underlyingAsset, ERC20Abi, provider);
      const underlyingDecimals = await underlyingAssetContract.decimals();
      const expiry = new Date(expiryTimestamp * 1000);
      let amount = makerAmount;
      if (underlyingDecimals !== oTokenDecimals) {
        if (underlyingDecimals > oTokenDecimals) {
          amount = amount.mul(`1${'0'.repeat(underlyingDecimals - oTokenDecimals)}`);
          balance = balance.mul(`1${'0'.repeat(underlyingDecimals - oTokenDecimals)}`);
        } else {
          balance = balance.div(`1${'0'.repeat(oTokenDecimals - underlyingDecimals)}`);
          amount = amount.div(`1${'0'.repeat(oTokenDecimals - underlyingDecimals)}`);
        }
      }

      const OpynOracle = new ethers.Contract(config.opynOracleAddress, OpynOracleAbi, provider);
      const underlyingPrice = ethers.BigNumber.from(await OpynOracle.getPrice(underlyingAsset));
      let profits;
      if (collateralAsset !== takerToken) {
        const amountPaidToPriceDecimals =
          takerAssetDecimals > 8
            ? amountPaid.div(`1${'0'.repeat(takerAssetDecimals - 8)}`)
            : amountPaid.mul(`1${'0'.repeat(8 - takerAssetDecimals)}`);
        profits = ethers.BigNumber.from(0);
        if (isPut) {
          if (underlyingPrice.lte(strikePrice)) {
            profits = strikePrice
              .sub(underlyingPrice)
              .mul(amount)
              .div(underlyingPrice)
              .sub(amountPaidToPriceDecimals.mul(`1${'0'.repeat(collateralDecimals)}`).div(underlyingPrice));
          } else {
            profits = amountPaidToPriceDecimals
              .mul(`1${'0'.repeat(collateralDecimals)}`)
              .div(underlyingPrice)
              .mul(-1);
          }
        } else {
          if (underlyingPrice.gte(strikePrice)) {
            profits = underlyingPrice
              .sub(strikePrice)
              .mul(amount)
              .div(underlyingPrice)
              .sub(amountPaidToPriceDecimals.mul(`1${'0'.repeat(collateralDecimals)}`).div(underlyingPrice));
          } else {
            profits = amountPaidToPriceDecimals
              .mul(`1${'0'.repeat(collateralDecimals)}`)
              .div(underlyingPrice)
              .mul(-1);
          }
        }
      } else {
        if (isPut) {
          if (underlyingPrice.lte(strikePrice)) {
            profits = strikePrice
              .sub(underlyingPrice)
              .mul(amount)
              .div(`1${'0'.repeat(collateralDecimals)}`)
              .sub(amountPaid);
          } else {
            profits = amountPaid.mul(-1);
          }
        } else {
          if (underlyingPrice.gte(strikePrice)) {
            profits = underlyingPrice
              .sub(strikePrice)
              .mul(amount)
              .div(`1${'0'.repeat(collateralDecimals)}`)
              .sub(amountPaid);
          } else {
            profits = amountPaid.mul(-1);
          }
        }
      }
      subscriber.next({
        option: {
          id: `${oToken.toLowerCase()}-${idx}`,
          address: oToken,
          platform: 'opyn',
          loaded: true,
          network: chainId,
          disabled: false,
          type: isPut ? 'PUT' : 'CALL',
          nature: 'EU',
          expiry,
          amount,
          strike: strikePrice,
          strikeDecimals: 8,
          price: amountPaid,
          premiumAsset: takerToken,
          underlyingAsset,
          strikeAsset,
          collateralAsset,
        },
        creationDate: new Date(timestamp * 1000),
        fungible: true,
        amount,
        positionId: `${oToken.toLowerCase()}-${idx}`,
        exercised: false,
        expired: false,
        exercisable: isPut ? underlyingPrice.lte(strikePrice) : underlyingPrice.gte(strikePrice),
        profitAsset: collateralAsset,
        profits,
        status: 'active',
      });

      if (!extraOtoken[oToken]) {
        extraOtoken[oToken] = {
          oToken,
          balance,
          underlyingPrice,
          expiry,
          collateralAsset,
          collateralDecimals,
          underlyingAsset,
          strikeAsset,
          strikePrice,
          isPut,
          premiumAsset: takerToken,
        };
      }

      if (balance.gt(amount)) {
        if (extraOtoken[oToken]) {
          extraOtoken[oToken].balance = extraOtoken[oToken].balance.sub(amount);
        }
      }
      ++idx;
    }

    for (const oTokenKey of Object.keys(extraOtoken)) {
      const {
        oToken,
        balance,
        underlyingPrice,
        expiry,
        collateralAsset,
        collateralDecimals,
        underlyingAsset,
        strikeAsset,
        strikePrice,
        isPut,
        premiumAsset,
      } = extraOtoken[oTokenKey];
      if (balance.eq(0)) {
        continue;
      }
      let profits;
      if (collateralAsset !== premiumAsset) {
        profits = ethers.BigNumber.from(0);
        if (isPut) {
          if (underlyingPrice.lte(strikePrice)) {
            profits = strikePrice.sub(underlyingPrice).mul(balance).div(underlyingPrice);
          } else {
            profits = ethers.BigNumber.from(0);
          }
        } else {
          if (underlyingPrice.gte(strikePrice)) {
            profits = underlyingPrice.sub(strikePrice).mul(balance).div(underlyingPrice);
          } else {
            profits = ethers.BigNumber.from(0);
          }
        }
      } else {
        if (isPut) {
          if (underlyingPrice.lte(strikePrice)) {
            profits = strikePrice
              .sub(underlyingPrice)
              .mul(balance)
              .div(`1${'0'.repeat(collateralDecimals)}`);
          } else {
            profits = ethers.BigNumber.from(0);
          }
        } else {
          if (underlyingPrice.gte(strikePrice)) {
            profits = underlyingPrice
              .sub(strikePrice)
              .mul(balance)
              .div(`1${'0'.repeat(collateralDecimals)}`);
          } else {
            profits = ethers.BigNumber.from(0);
          }
        }
      }
      subscriber.next({
        option: {
          id: `${oToken.toLowerCase()}`,
          address: oToken,
          platform: 'opyn',
          loaded: true,
          network: chainId,
          disabled: false,
          type: isPut ? 'PUT' : 'CALL',
          nature: 'EU',
          expiry,
          amount: balance,
          strike: strikePrice,
          strikeDecimals: 8,
          price: null,
          premiumAsset,
          underlyingAsset,
          strikeAsset,
          collateralAsset,
        },
        creationDate: null,
        fungible: true,
        amount: balance,
        positionId: oToken.toLowerCase(),
        exercised: false,
        expired: false,
        exercisable: isPut ? underlyingPrice.lte(strikePrice) : underlyingPrice.gte(strikePrice),
        profitAsset: collateralAsset,
        profits,
        status: 'active',
      });
    }

    return;
  }

  private readonly actions: string[] = [];

  public supportsPlatformAction(action: string): boolean {
    return this.actions.includes(action);
  }

  public async generatePlatformActionPayload(
    action: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    config: OpynConfig,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    provider: providers.BaseProvider,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    optionPosition: OptionPosition
  ): Promise<PopulatedTransaction> {
    switch (action) {
      default:
        return null;
    }
  }
}
