export const ZeroExV4Exchange = [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'bytes32', name: 'orderHash', type: 'bytes32' },
      { indexed: false, internalType: 'address', name: 'maker', type: 'address' },
      { indexed: false, internalType: 'uint64', name: 'expiry', type: 'uint64' },
    ],
    name: 'ExpiredRfqOrder',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'bytes32', name: 'orderHash', type: 'bytes32' },
      { indexed: false, internalType: 'address', name: 'maker', type: 'address' },
      { indexed: false, internalType: 'address', name: 'taker', type: 'address' },
      { indexed: false, internalType: 'address', name: 'feeRecipient', type: 'address' },
      { indexed: false, internalType: 'address', name: 'makerToken', type: 'address' },
      { indexed: false, internalType: 'address', name: 'takerToken', type: 'address' },
      {
        indexed: false,
        internalType: 'uint128',
        name: 'takerTokenFilledAmount',
        type: 'uint128',
      },
      {
        indexed: false,
        internalType: 'uint128',
        name: 'makerTokenFilledAmount',
        type: 'uint128',
      },
      {
        indexed: false,
        internalType: 'uint128',
        name: 'takerTokenFeeFilledAmount',
        type: 'uint128',
      },
      { indexed: false, internalType: 'uint256', name: 'protocolFeePaid', type: 'uint256' },
      { indexed: false, internalType: 'bytes32', name: 'pool', type: 'bytes32' },
    ],
    name: 'LimitOrderFilled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'inputToken', type: 'address' },
      { indexed: false, internalType: 'address', name: 'outputToken', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'inputTokenAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'outputTokenAmount', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'provider', type: 'address' },
      { indexed: false, internalType: 'address', name: 'recipient', type: 'address' },
    ],
    name: 'LiquidityProviderSwap',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      { indexed: true, internalType: 'bytes4', name: 'selector', type: 'bytes4' },
      { indexed: false, internalType: 'address', name: 'signer', type: 'address' },
      { indexed: false, internalType: 'address', name: 'sender', type: 'address' },
    ],
    name: 'MetaTransactionExecuted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'caller', type: 'address' },
      { indexed: false, internalType: 'address', name: 'migrator', type: 'address' },
      { indexed: false, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'Migrated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'bytes32', name: 'orderHash', type: 'bytes32' },
      { indexed: false, internalType: 'address', name: 'maker', type: 'address' },
    ],
    name: 'OrderCancelled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'maker', type: 'address' },
      { indexed: false, internalType: 'address', name: 'makerToken', type: 'address' },
      { indexed: false, internalType: 'address', name: 'takerToken', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'minValidSalt', type: 'uint256' },
    ],
    name: 'PairCancelledLimitOrders',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'maker', type: 'address' },
      { indexed: false, internalType: 'address', name: 'makerToken', type: 'address' },
      { indexed: false, internalType: 'address', name: 'takerToken', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'minValidSalt', type: 'uint256' },
    ],
    name: 'PairCancelledRfqOrders',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes4', name: 'selector', type: 'bytes4' },
      { indexed: false, internalType: 'address', name: 'oldImpl', type: 'address' },
      { indexed: false, internalType: 'address', name: 'newImpl', type: 'address' },
    ],
    name: 'ProxyFunctionUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'quoteSigner', type: 'address' }],
    name: 'QuoteSignerUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'bytes32', name: 'orderHash', type: 'bytes32' },
      { indexed: false, internalType: 'address', name: 'maker', type: 'address' },
      { indexed: false, internalType: 'address', name: 'taker', type: 'address' },
      { indexed: false, internalType: 'address', name: 'makerToken', type: 'address' },
      { indexed: false, internalType: 'address', name: 'takerToken', type: 'address' },
      {
        indexed: false,
        internalType: 'uint128',
        name: 'takerTokenFilledAmount',
        type: 'uint128',
      },
      {
        indexed: false,
        internalType: 'uint128',
        name: 'makerTokenFilledAmount',
        type: 'uint128',
      },
      { indexed: false, internalType: 'bytes32', name: 'pool', type: 'bytes32' },
    ],
    name: 'RfqOrderFilled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'origin', type: 'address' },
      { indexed: false, internalType: 'address[]', name: 'addrs', type: 'address[]' },
      { indexed: false, internalType: 'bool', name: 'allowed', type: 'bool' },
    ],
    name: 'RfqOrderOriginsAllowed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'taker', type: 'address' },
      { indexed: false, internalType: 'address', name: 'inputToken', type: 'address' },
      { indexed: false, internalType: 'address', name: 'outputToken', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'inputTokenAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'outputTokenAmount', type: 'uint256' },
    ],
    name: 'TransformedERC20',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'transformerDeployer', type: 'address' }],
    name: 'TransformerDeployerUpdated',
    type: 'event',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerTokenFeeAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'address', name: 'feeRecipient', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.LimitOrder',
        name: 'order',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'enum LibSignature.SignatureType',
            name: 'signatureType',
            type: 'uint8',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct LibSignature.Signature',
        name: 'signature',
        type: 'tuple',
      },
      { internalType: 'uint128', name: 'takerTokenFillAmount', type: 'uint128' },
      { internalType: 'address', name: 'taker', type: 'address' },
      { internalType: 'address', name: 'sender', type: 'address' },
    ],
    name: '_fillLimitOrder',
    outputs: [
      { internalType: 'uint128', name: 'takerTokenFilledAmount', type: 'uint128' },
      { internalType: 'uint128', name: 'makerTokenFilledAmount', type: 'uint128' },
    ],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'txOrigin', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.RfqOrder',
        name: 'order',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'enum LibSignature.SignatureType',
            name: 'signatureType',
            type: 'uint8',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct LibSignature.Signature',
        name: 'signature',
        type: 'tuple',
      },
      { internalType: 'uint128', name: 'takerTokenFillAmount', type: 'uint128' },
      { internalType: 'address', name: 'taker', type: 'address' },
    ],
    name: '_fillRfqOrder',
    outputs: [
      { internalType: 'uint128', name: 'takerTokenFilledAmount', type: 'uint128' },
      { internalType: 'uint128', name: 'makerTokenFilledAmount', type: 'uint128' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IERC20TokenV06', name: 'token', type: 'address' },
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: '_spendERC20Tokens',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address payable', name: 'taker', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'inputToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'outputToken', type: 'address' },
          { internalType: 'uint256', name: 'inputTokenAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'minOutputTokenAmount', type: 'uint256' },
          {
            components: [
              { internalType: 'uint32', name: 'deploymentNonce', type: 'uint32' },
              { internalType: 'bytes', name: 'data', type: 'bytes' },
            ],
            internalType: 'struct ITransformERC20Feature.Transformation[]',
            name: 'transformations',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct ITransformERC20Feature.TransformERC20Args',
        name: 'args',
        type: 'tuple',
      },
    ],
    name: '_transformERC20',
    outputs: [{ internalType: 'uint256', name: 'outputTokenAmount', type: 'uint256' }],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerTokenFeeAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'address', name: 'feeRecipient', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.LimitOrder[]',
        name: 'orders',
        type: 'tuple[]',
      },
    ],
    name: 'batchCancelLimitOrders',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IERC20TokenV06[]', name: 'makerTokens', type: 'address[]' },
      { internalType: 'contract IERC20TokenV06[]', name: 'takerTokens', type: 'address[]' },
      { internalType: 'uint256[]', name: 'minValidSalts', type: 'uint256[]' },
    ],
    name: 'batchCancelPairLimitOrders',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IERC20TokenV06[]', name: 'makerTokens', type: 'address[]' },
      { internalType: 'contract IERC20TokenV06[]', name: 'takerTokens', type: 'address[]' },
      { internalType: 'uint256[]', name: 'minValidSalts', type: 'uint256[]' },
    ],
    name: 'batchCancelPairRfqOrders',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'txOrigin', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.RfqOrder[]',
        name: 'orders',
        type: 'tuple[]',
      },
    ],
    name: 'batchCancelRfqOrders',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'uint256', name: 'minGasPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'maxGasPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'expirationTimeSeconds', type: 'uint256' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
          { internalType: 'bytes', name: 'callData', type: 'bytes' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'contract IERC20TokenV06', name: 'feeToken', type: 'address' },
          { internalType: 'uint256', name: 'feeAmount', type: 'uint256' },
        ],
        internalType: 'struct IMetaTransactionsFeature.MetaTransactionData[]',
        name: 'mtxs',
        type: 'tuple[]',
      },
      {
        components: [
          {
            internalType: 'enum LibSignature.SignatureType',
            name: 'signatureType',
            type: 'uint8',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct LibSignature.Signature[]',
        name: 'signatures',
        type: 'tuple[]',
      },
    ],
    name: 'batchExecuteMetaTransactions',
    outputs: [{ internalType: 'bytes[]', name: 'returnResults', type: 'bytes[]' }],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'inputToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'outputToken', type: 'address' },
          { internalType: 'uint256', name: 'sellAmount', type: 'uint256' },
          {
            components: [
              { internalType: 'bytes4', name: 'selector', type: 'bytes4' },
              { internalType: 'uint256', name: 'sellAmount', type: 'uint256' },
              { internalType: 'bytes', name: 'data', type: 'bytes' },
            ],
            internalType: 'struct IMultiplexFeature.WrappedBatchCall[]',
            name: 'calls',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct IMultiplexFeature.BatchFillData',
        name: 'fillData',
        type: 'tuple',
      },
      { internalType: 'uint256', name: 'minBuyAmount', type: 'uint256' },
    ],
    name: 'batchFill',
    outputs: [{ internalType: 'uint256', name: 'outputTokenAmount', type: 'uint256' }],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerTokenFeeAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'address', name: 'feeRecipient', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.LimitOrder[]',
        name: 'orders',
        type: 'tuple[]',
      },
      {
        components: [
          {
            internalType: 'enum LibSignature.SignatureType',
            name: 'signatureType',
            type: 'uint8',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct LibSignature.Signature[]',
        name: 'signatures',
        type: 'tuple[]',
      },
      { internalType: 'uint128[]', name: 'takerTokenFillAmounts', type: 'uint128[]' },
      { internalType: 'bool', name: 'revertIfIncomplete', type: 'bool' },
    ],
    name: 'batchFillLimitOrders',
    outputs: [
      { internalType: 'uint128[]', name: 'takerTokenFilledAmounts', type: 'uint128[]' },
      { internalType: 'uint128[]', name: 'makerTokenFilledAmounts', type: 'uint128[]' },
    ],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'txOrigin', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.RfqOrder[]',
        name: 'orders',
        type: 'tuple[]',
      },
      {
        components: [
          {
            internalType: 'enum LibSignature.SignatureType',
            name: 'signatureType',
            type: 'uint8',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct LibSignature.Signature[]',
        name: 'signatures',
        type: 'tuple[]',
      },
      { internalType: 'uint128[]', name: 'takerTokenFillAmounts', type: 'uint128[]' },
      { internalType: 'bool', name: 'revertIfIncomplete', type: 'bool' },
    ],
    name: 'batchFillRfqOrders',
    outputs: [
      { internalType: 'uint128[]', name: 'takerTokenFilledAmounts', type: 'uint128[]' },
      { internalType: 'uint128[]', name: 'makerTokenFilledAmounts', type: 'uint128[]' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerTokenFeeAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'address', name: 'feeRecipient', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.LimitOrder[]',
        name: 'orders',
        type: 'tuple[]',
      },
      {
        components: [
          {
            internalType: 'enum LibSignature.SignatureType',
            name: 'signatureType',
            type: 'uint8',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct LibSignature.Signature[]',
        name: 'signatures',
        type: 'tuple[]',
      },
    ],
    name: 'batchGetLimitOrderRelevantStates',
    outputs: [
      {
        components: [
          { internalType: 'bytes32', name: 'orderHash', type: 'bytes32' },
          { internalType: 'enum LibNativeOrder.OrderStatus', name: 'status', type: 'uint8' },
          { internalType: 'uint128', name: 'takerTokenFilledAmount', type: 'uint128' },
        ],
        internalType: 'struct LibNativeOrder.OrderInfo[]',
        name: 'orderInfos',
        type: 'tuple[]',
      },
      { internalType: 'uint128[]', name: 'actualFillableTakerTokenAmounts', type: 'uint128[]' },
      { internalType: 'bool[]', name: 'isSignatureValids', type: 'bool[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'txOrigin', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.RfqOrder[]',
        name: 'orders',
        type: 'tuple[]',
      },
      {
        components: [
          {
            internalType: 'enum LibSignature.SignatureType',
            name: 'signatureType',
            type: 'uint8',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct LibSignature.Signature[]',
        name: 'signatures',
        type: 'tuple[]',
      },
    ],
    name: 'batchGetRfqOrderRelevantStates',
    outputs: [
      {
        components: [
          { internalType: 'bytes32', name: 'orderHash', type: 'bytes32' },
          { internalType: 'enum LibNativeOrder.OrderStatus', name: 'status', type: 'uint8' },
          { internalType: 'uint128', name: 'takerTokenFilledAmount', type: 'uint128' },
        ],
        internalType: 'struct LibNativeOrder.OrderInfo[]',
        name: 'orderInfos',
        type: 'tuple[]',
      },
      { internalType: 'uint128[]', name: 'actualFillableTakerTokenAmounts', type: 'uint128[]' },
      { internalType: 'bool[]', name: 'isSignatureValids', type: 'bool[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerTokenFeeAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'address', name: 'feeRecipient', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.LimitOrder',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'cancelLimitOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
      { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
      { internalType: 'uint256', name: 'minValidSalt', type: 'uint256' },
    ],
    name: 'cancelPairLimitOrders',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
      { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
      { internalType: 'uint256', name: 'minValidSalt', type: 'uint256' },
    ],
    name: 'cancelPairRfqOrders',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'txOrigin', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.RfqOrder',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'cancelRfqOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'createTransformWallet',
    outputs: [{ internalType: 'contract IFlashWallet', name: 'wallet', type: 'address' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'uint256', name: 'minGasPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'maxGasPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'expirationTimeSeconds', type: 'uint256' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
          { internalType: 'bytes', name: 'callData', type: 'bytes' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'contract IERC20TokenV06', name: 'feeToken', type: 'address' },
          { internalType: 'uint256', name: 'feeAmount', type: 'uint256' },
        ],
        internalType: 'struct IMetaTransactionsFeature.MetaTransactionData',
        name: 'mtx',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'enum LibSignature.SignatureType',
            name: 'signatureType',
            type: 'uint8',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct LibSignature.Signature',
        name: 'signature',
        type: 'tuple',
      },
    ],
    name: 'executeMetaTransaction',
    outputs: [{ internalType: 'bytes', name: 'returnResult', type: 'bytes' }],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes4', name: 'selector', type: 'bytes4' },
      { internalType: 'address', name: 'impl', type: 'address' },
    ],
    name: 'extend',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerTokenFeeAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'address', name: 'feeRecipient', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.LimitOrder',
        name: 'order',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'enum LibSignature.SignatureType',
            name: 'signatureType',
            type: 'uint8',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct LibSignature.Signature',
        name: 'signature',
        type: 'tuple',
      },
      { internalType: 'uint128', name: 'takerTokenFillAmount', type: 'uint128' },
    ],
    name: 'fillLimitOrder',
    outputs: [
      { internalType: 'uint128', name: 'takerTokenFilledAmount', type: 'uint128' },
      { internalType: 'uint128', name: 'makerTokenFilledAmount', type: 'uint128' },
    ],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerTokenFeeAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'address', name: 'feeRecipient', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.LimitOrder',
        name: 'order',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'enum LibSignature.SignatureType',
            name: 'signatureType',
            type: 'uint8',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct LibSignature.Signature',
        name: 'signature',
        type: 'tuple',
      },
      { internalType: 'uint128', name: 'takerTokenFillAmount', type: 'uint128' },
    ],
    name: 'fillOrKillLimitOrder',
    outputs: [{ internalType: 'uint128', name: 'makerTokenFilledAmount', type: 'uint128' }],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'txOrigin', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.RfqOrder',
        name: 'order',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'enum LibSignature.SignatureType',
            name: 'signatureType',
            type: 'uint8',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct LibSignature.Signature',
        name: 'signature',
        type: 'tuple',
      },
      { internalType: 'uint128', name: 'takerTokenFillAmount', type: 'uint128' },
    ],
    name: 'fillOrKillRfqOrder',
    outputs: [{ internalType: 'uint128', name: 'makerTokenFilledAmount', type: 'uint128' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'txOrigin', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.RfqOrder',
        name: 'order',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'enum LibSignature.SignatureType',
            name: 'signatureType',
            type: 'uint8',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct LibSignature.Signature',
        name: 'signature',
        type: 'tuple',
      },
      { internalType: 'uint128', name: 'takerTokenFillAmount', type: 'uint128' },
    ],
    name: 'fillRfqOrder',
    outputs: [
      { internalType: 'uint128', name: 'takerTokenFilledAmount', type: 'uint128' },
      { internalType: 'uint128', name: 'makerTokenFilledAmount', type: 'uint128' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getAllowanceTarget',
    outputs: [{ internalType: 'address', name: 'target', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerTokenFeeAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'address', name: 'feeRecipient', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.LimitOrder',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'getLimitOrderHash',
    outputs: [{ internalType: 'bytes32', name: 'orderHash', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerTokenFeeAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'address', name: 'feeRecipient', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.LimitOrder',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'getLimitOrderInfo',
    outputs: [
      {
        components: [
          { internalType: 'bytes32', name: 'orderHash', type: 'bytes32' },
          { internalType: 'enum LibNativeOrder.OrderStatus', name: 'status', type: 'uint8' },
          { internalType: 'uint128', name: 'takerTokenFilledAmount', type: 'uint128' },
        ],
        internalType: 'struct LibNativeOrder.OrderInfo',
        name: 'orderInfo',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerTokenFeeAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'address', name: 'feeRecipient', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.LimitOrder',
        name: 'order',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'enum LibSignature.SignatureType',
            name: 'signatureType',
            type: 'uint8',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct LibSignature.Signature',
        name: 'signature',
        type: 'tuple',
      },
    ],
    name: 'getLimitOrderRelevantState',
    outputs: [
      {
        components: [
          { internalType: 'bytes32', name: 'orderHash', type: 'bytes32' },
          { internalType: 'enum LibNativeOrder.OrderStatus', name: 'status', type: 'uint8' },
          { internalType: 'uint128', name: 'takerTokenFilledAmount', type: 'uint128' },
        ],
        internalType: 'struct LibNativeOrder.OrderInfo',
        name: 'orderInfo',
        type: 'tuple',
      },
      { internalType: 'uint128', name: 'actualFillableTakerTokenAmount', type: 'uint128' },
      { internalType: 'bool', name: 'isSignatureValid', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'uint256', name: 'minGasPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'maxGasPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'expirationTimeSeconds', type: 'uint256' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
          { internalType: 'bytes', name: 'callData', type: 'bytes' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'contract IERC20TokenV06', name: 'feeToken', type: 'address' },
          { internalType: 'uint256', name: 'feeAmount', type: 'uint256' },
        ],
        internalType: 'struct IMetaTransactionsFeature.MetaTransactionData',
        name: 'mtx',
        type: 'tuple',
      },
    ],
    name: 'getMetaTransactionExecutedBlock',
    outputs: [{ internalType: 'uint256', name: 'blockNumber', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'uint256', name: 'minGasPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'maxGasPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'expirationTimeSeconds', type: 'uint256' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
          { internalType: 'bytes', name: 'callData', type: 'bytes' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'contract IERC20TokenV06', name: 'feeToken', type: 'address' },
          { internalType: 'uint256', name: 'feeAmount', type: 'uint256' },
        ],
        internalType: 'struct IMetaTransactionsFeature.MetaTransactionData',
        name: 'mtx',
        type: 'tuple',
      },
    ],
    name: 'getMetaTransactionHash',
    outputs: [{ internalType: 'bytes32', name: 'mtxHash', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'mtxHash', type: 'bytes32' }],
    name: 'getMetaTransactionHashExecutedBlock',
    outputs: [{ internalType: 'uint256', name: 'blockNumber', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getProtocolFeeMultiplier',
    outputs: [{ internalType: 'uint32', name: 'multiplier', type: 'uint32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getQuoteSigner',
    outputs: [{ internalType: 'address', name: 'signer', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'txOrigin', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.RfqOrder',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'getRfqOrderHash',
    outputs: [{ internalType: 'bytes32', name: 'orderHash', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'txOrigin', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.RfqOrder',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'getRfqOrderInfo',
    outputs: [
      {
        components: [
          { internalType: 'bytes32', name: 'orderHash', type: 'bytes32' },
          { internalType: 'enum LibNativeOrder.OrderStatus', name: 'status', type: 'uint8' },
          { internalType: 'uint128', name: 'takerTokenFilledAmount', type: 'uint128' },
        ],
        internalType: 'struct LibNativeOrder.OrderInfo',
        name: 'orderInfo',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20TokenV06', name: 'makerToken', type: 'address' },
          { internalType: 'contract IERC20TokenV06', name: 'takerToken', type: 'address' },
          { internalType: 'uint128', name: 'makerAmount', type: 'uint128' },
          { internalType: 'uint128', name: 'takerAmount', type: 'uint128' },
          { internalType: 'address', name: 'maker', type: 'address' },
          { internalType: 'address', name: 'taker', type: 'address' },
          { internalType: 'address', name: 'txOrigin', type: 'address' },
          { internalType: 'bytes32', name: 'pool', type: 'bytes32' },
          { internalType: 'uint64', name: 'expiry', type: 'uint64' },
          { internalType: 'uint256', name: 'salt', type: 'uint256' },
        ],
        internalType: 'struct LibNativeOrder.RfqOrder',
        name: 'order',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'enum LibSignature.SignatureType',
            name: 'signatureType',
            type: 'uint8',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct LibSignature.Signature',
        name: 'signature',
        type: 'tuple',
      },
    ],
    name: 'getRfqOrderRelevantState',
    outputs: [
      {
        components: [
          { internalType: 'bytes32', name: 'orderHash', type: 'bytes32' },
          { internalType: 'enum LibNativeOrder.OrderStatus', name: 'status', type: 'uint8' },
          { internalType: 'uint128', name: 'takerTokenFilledAmount', type: 'uint128' },
        ],
        internalType: 'struct LibNativeOrder.OrderInfo',
        name: 'orderInfo',
        type: 'tuple',
      },
      { internalType: 'uint128', name: 'actualFillableTakerTokenAmount', type: 'uint128' },
      { internalType: 'bool', name: 'isSignatureValid', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes4', name: 'selector', type: 'bytes4' },
      { internalType: 'uint256', name: 'idx', type: 'uint256' },
    ],
    name: 'getRollbackEntryAtIndex',
    outputs: [{ internalType: 'address', name: 'impl', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'selector', type: 'bytes4' }],
    name: 'getRollbackLength',
    outputs: [{ internalType: 'uint256', name: 'rollbackLength', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IERC20TokenV06', name: 'token', type: 'address' },
      { internalType: 'address', name: 'owner', type: 'address' },
    ],
    name: 'getSpendableERC20BalanceOf',
    outputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getTransformWallet',
    outputs: [{ internalType: 'contract IFlashWallet', name: 'wallet', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getTransformerDeployer',
    outputs: [{ internalType: 'address', name: 'deployer', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'target', type: 'address' },
      { internalType: 'bytes', name: 'data', type: 'bytes' },
      { internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'migrate',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address[]', name: 'tokens', type: 'address[]' },
          { internalType: 'uint256', name: 'sellAmount', type: 'uint256' },
          {
            components: [
              { internalType: 'bytes4', name: 'selector', type: 'bytes4' },
              { internalType: 'bytes', name: 'data', type: 'bytes' },
            ],
            internalType: 'struct IMultiplexFeature.WrappedMultiHopCall[]',
            name: 'calls',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct IMultiplexFeature.MultiHopFillData',
        name: 'fillData',
        type: 'tuple',
      },
      { internalType: 'uint256', name: 'minBuyAmount', type: 'uint256' },
    ],
    name: 'multiHopFill',
    outputs: [{ internalType: 'uint256', name: 'outputTokenAmount', type: 'uint256' }],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: 'ownerAddress', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'origins', type: 'address[]' },
      { internalType: 'bool', name: 'allowed', type: 'bool' },
    ],
    name: 'registerAllowedRfqOrigins',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes4', name: 'selector', type: 'bytes4' },
      { internalType: 'address', name: 'targetImpl', type: 'address' },
    ],
    name: 'rollback',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IERC20TokenV06', name: 'inputToken', type: 'address' },
      { internalType: 'contract IERC20TokenV06', name: 'outputToken', type: 'address' },
      { internalType: 'contract ILiquidityProvider', name: 'provider', type: 'address' },
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256', name: 'sellAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'minBuyAmount', type: 'uint256' },
      { internalType: 'bytes', name: 'auxiliaryData', type: 'bytes' },
    ],
    name: 'sellToLiquidityProvider',
    outputs: [{ internalType: 'uint256', name: 'boughtAmount', type: 'uint256' }],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IERC20TokenV06[]', name: 'tokens', type: 'address[]' },
      { internalType: 'uint256', name: 'sellAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'minBuyAmount', type: 'uint256' },
      { internalType: 'bool', name: 'isSushi', type: 'bool' },
    ],
    name: 'sellToUniswap',
    outputs: [{ internalType: 'uint256', name: 'buyAmount', type: 'uint256' }],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'quoteSigner', type: 'address' }],
    name: 'setQuoteSigner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'transformerDeployer', type: 'address' }],
    name: 'setTransformerDeployer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32[]', name: 'poolIds', type: 'bytes32[]' }],
    name: 'transferProtocolFeesForPools',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IERC20TokenV06', name: 'inputToken', type: 'address' },
      { internalType: 'contract IERC20TokenV06', name: 'outputToken', type: 'address' },
      { internalType: 'uint256', name: 'inputTokenAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'minOutputTokenAmount', type: 'uint256' },
      {
        components: [
          { internalType: 'uint32', name: 'deploymentNonce', type: 'uint32' },
          { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        internalType: 'struct ITransformERC20Feature.Transformation[]',
        name: 'transformations',
        type: 'tuple[]',
      },
    ],
    name: 'transformERC20',
    outputs: [{ internalType: 'uint256', name: 'outputTokenAmount', type: 'uint256' }],
    stateMutability: 'payable',
    type: 'function',
  },
];
