import { useBlockNumber, useEthers } from '@usedapp/core';
import { Radio, RadioChangeEvent, Select } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getFeeAsString } from '../../utils/getFeeAsString';
import { glassmorphism } from '../../utils/glassmorphism';
import { styled } from '../ThemeProvider';
import { WalletModalContext } from '../WalletModalProvider';
import { Weighted } from '../Weighted';
import {
  UpOutlined,
  LogoutOutlined,
  SettingOutlined,
  WalletFilled,
  WalletOutlined,
  EditFilled,
  EditOutlined,
  ShoppingFilled,
  ShoppingOutlined,
  FolderFilled,
  FolderOutlined,
  SettingFilled,
} from '@ant-design/icons';
import { Network, useNetworkState } from '../NetworkProvider';
import { useTBD } from '@tbd/react';
import { useAppState } from '../AppStateProvider';

const SelectOption = styled(Select.Option)`
  font-weight: 700;
`;

const NetworkText = styled.span`
  font-weight: 300;
  cursor: pointer;
  text-transform: uppercase;
  margin: ${(props) => props.theme.spacing.s};
`;

const GasText = styled.span`
  font-weight: 300;
  cursor: pointer;
  text-transform: uppercase;
  margin: ${(props) => props.theme.spacing.s};
`;
const MobileBottomBarContainer = styled.div`
  width: 100%;
  height: calc(70px + env(safe-area-inset-bottom));
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
  padding-bottom: env(safe-area-inset-bottom);

  ${(props) => glassmorphism(`${props.theme.fg}10`, props.theme.bg)};
`;

const WalletIcon = ({ selected, onClick }: { selected: boolean; onClick: () => void }) => {
  if (selected) {
    return (
      <WalletFilled
        onClick={onClick}
        style={{
          fontSize: 24,
          opacity: 1,
        }}
      />
    );
  } else {
    return (
      <WalletOutlined
        onClick={onClick}
        style={{
          fontSize: 24,
          opacity: 0.6,
        }}
      />
    );
  }
};

const WriteIcon = ({ selected, onClick }: { selected: boolean; onClick: () => void }) => {
  if (selected) {
    return (
      <EditFilled
        onClick={onClick}
        style={{
          fontSize: 24,
          opacity: 1,
        }}
      />
    );
  } else {
    return (
      <EditOutlined
        onClick={onClick}
        style={{
          fontSize: 24,
          opacity: 0.6,
        }}
      />
    );
  }
};

const ShoppingIcon = ({ selected, onClick }: { selected: boolean; onClick: () => void }) => {
  if (selected) {
    return (
      <ShoppingFilled
        onClick={onClick}
        style={{
          fontSize: 24,
          opacity: 1,
        }}
      />
    );
  } else {
    return (
      <ShoppingOutlined
        onClick={onClick}
        style={{
          fontSize: 24,
          opacity: 0.6,
        }}
      />
    );
  }
};

const FolderIcon = ({ selected, onClick }: { selected: boolean; onClick: () => void }) => {
  if (selected) {
    return (
      <FolderFilled
        onClick={onClick}
        style={{
          fontSize: 24,
          opacity: 1,
        }}
      />
    );
  } else {
    return (
      <FolderOutlined
        onClick={onClick}
        style={{
          fontSize: 24,
          opacity: 0.6,
        }}
      />
    );
  }
};

const SettingIcon = ({ selected, onClick }: { selected: boolean; onClick: () => void }) => {
  if (selected) {
    return (
      <SettingFilled
        onClick={onClick}
        style={{
          fontSize: 24,
          opacity: 1,
        }}
      />
    );
  } else {
    return (
      <SettingOutlined
        onClick={onClick}
        style={{
          fontSize: 24,
          opacity: 0.6,
        }}
      />
    );
  }
};

const MobileSettingsContainer = styled.div<{ opened: boolean }>`
  width: 100%;
  height: calc(200px + env(safe-area-inset-bottom));
  position: fixed;
  bottom: ${(props) => (props.opened ? '0' : `calc(-200px - env(safe-area-inset-bottom))`)};
  transition: all 200ms ease-in-out;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 99;
  padding: 12px;
  padding-bottom: calc(82px + env(safe-area-inset-bottom));

  ${(props) => glassmorphism(`${props.theme.fg}10`, props.theme.bg)};
`;

const MobileSettingsMenu = ({ opened }: { opened: boolean }) => {
  const [networkState, dispatchNetworkState] = useNetworkState();
  const [appState, dispatchAppState] = useAppState();
  const tbd = useTBD();
  const { chainId } = useEthers();
  const currentBlockNumber = useBlockNumber();
  const [lastBlockNumber, setLastBlockNumber] = useState(0);

  useEffect(() => {
    (async () => {
      if (
        tbd &&
        (!appState.gasSettings || appState.gasSettings.speed !== appState.gas || lastBlockNumber < currentBlockNumber)
      ) {
        const gasSettings = await tbd.getGasSettings(chainId, appState.gas);
        dispatchAppState({
          type: 'SetGasSettings',
          gasSettings,
        });
        setLastBlockNumber(currentBlockNumber);
      }
    })();
  }, [tbd, appState, chainId, dispatchAppState, currentBlockNumber, lastBlockNumber]);

  const setNetworkCallback = useCallback(
    (network: Network): void => dispatchNetworkState({ type: 'SetNetwork', network }),
    [dispatchNetworkState]
  );
  const setGasCallback = useCallback(
    (e: RadioChangeEvent): void => dispatchAppState({ type: 'SetGas', gas: e.target.value }),
    [dispatchAppState]
  );

  return (
    <MobileSettingsContainer opened={opened}>
      <NetworkText>
        Network
        <Select
          style={{ fontWeight: 700, fontSize: 18 }}
          bordered={false}
          onChange={setNetworkCallback}
          value={networkState.network}
          suffixIcon={() => <UpOutlined />}
          dropdownAlign={{
            points: ['bl', 'tl'],
            offset: [0, 0],
            overflow: {
              adjustX: 0,
              adjustY: 1,
            },
          }}
        >
          {process.env.NX_LIVE === 'true' ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              <SelectOption value="ethereum">Ethereum</SelectOption>
            </>
          ) : (
            <>
              <SelectOption value="ethereum">Ethereum</SelectOption>
              <SelectOption value="ethereum_fork">Ethereum Fork</SelectOption>
              <SelectOption value="arbitrum">Arbitrum</SelectOption>
              <SelectOption value="arbitrum_fork">Arbitrum Fork</SelectOption>
              <SelectOption value="optimism">Optimism</SelectOption>
              <SelectOption value="optimism_fork">Optimism Fork</SelectOption>
              <SelectOption value="kovan">Kovan</SelectOption>
            </>
          )}
        </Select>
      </NetworkText>
      <GasText>
        Gas
        <Radio.Group
          style={{
            marginLeft: 12,
          }}
          buttonStyle={'solid'}
          options={[
            { label: 'Slow', value: 'slow' },
            { label: 'Regular', value: 'regular' },
            { label: 'Fast', value: 'fast' },
            { label: 'Faster', value: 'faster' },
          ]}
          onChange={setGasCallback}
          value={appState.gas}
          optionType="button"
        />
      </GasText>
      <GasText>
        <Weighted weight={'500'}>{getFeeAsString(appState.gasSettings)}</Weighted>
      </GasText>
    </MobileSettingsContainer>
  );
};

const MobileWalletContainer = styled.div<{ opened: boolean }>`
  width: 100%;
  height: calc(120px + env(safe-area-inset-bottom));
  position: fixed;
  bottom: ${(props) => (props.opened ? '0' : `calc(-120px - env(safe-area-inset-bottom))`)};
  transition: all 200ms ease-in-out;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 98;
  padding-bottom: calc(70px + env(safe-area-inset-bottom));

  ${(props) => glassmorphism(`${props.theme.fg}10`, props.theme.bg)};
`;

const ClickToConnect = styled.span`
  font-size: 20px;
  font-weight: 400;
`;

const MobileWalletMenu = ({ opened }: { opened: boolean }) => {
  const { account, deactivate } = useEthers();
  return (
    <MobileWalletContainer opened={opened}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={deactivate}
      >
        {account ? (
          <ClickToConnect>
            {account.slice(0, 8)}..{account.slice(-6)}
          </ClickToConnect>
        ) : null}
        <LogoutOutlined
          style={{
            fontSize: 18,
            marginLeft: 18,
          }}
        />
      </div>
    </MobileWalletContainer>
  );
};

export const MobileBottomBar = () => {
  const { account } = useEthers();
  const [, setActive] = useContext(WalletModalContext);
  const [settingsOpened, setSettingsOpened] = useState(false);
  const [walletOpened, setWalletOpened] = useState(true);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const tid = setTimeout(() => {
      setWalletOpened(false);
    }, 5000);
    return () => {
      clearTimeout(tid);
    };
  }, [account]);

  return (
    <>
      <MobileSettingsMenu opened={settingsOpened} />
      <MobileWalletMenu opened={walletOpened && account !== null} />
      <MobileBottomBarContainer>
        <ShoppingIcon selected={location.pathname === '/buy'} onClick={() => history.push('/buy')} />
        <WriteIcon selected={location.pathname === '/sell'} onClick={() => history.push('/sell')} />
        <FolderIcon selected={location.pathname === '/positions'} onClick={() => history.push('/positions')} />
        <SettingIcon
          selected={settingsOpened}
          onClick={() => {
            if (walletOpened && !settingsOpened) {
              setWalletOpened(false);
            }
            setSettingsOpened(!settingsOpened);
          }}
        />
        <WalletIcon
          selected={walletOpened && account !== null}
          onClick={() => {
            if (!account) {
              setActive(true);
            }
            if (settingsOpened && !walletOpened) {
              setSettingsOpened(false);
            }
            setWalletOpened(!walletOpened);
          }}
        />
      </MobileBottomBarContainer>
    </>
  );
};
