import axios, { AxiosInstance } from 'axios';

interface PagedRequest {
  page?: number;
  perPage?: number;
}

interface GetOrderBookArguments extends PagedRequest {
  baseToken: string;
  quoteToken: string;
}

export interface Order {
  chainId: number;
  expiry: string;
  feeRecipient: string;
  maker: string;
  makerAmount: string;
  makerToken: string;
  pool: string;
  salt: string;
  sender: string;
  signature: {
    signatureType: number;
    r: string;
    s: string;
    v: number;
  };
  taker: string;
  takerAmount: string;
  takerToken: string;
  takerTokenFeeAmount: string;
  verifyingContract: string;
}

export interface OrderWithMetadata {
  metaData: {
    createdAt: string;
    orderHash: string;
    remainingFillableTakerAmount: string;
  };
  order: Order;
}

interface OrderList {
  page: number;
  total: number;
  records: OrderWithMetadata[];
}

interface OrderBook {
  bids: OrderList;
  asks: OrderList;
}

export class ZeroExMeshClient {
  private readonly ac: AxiosInstance;
  constructor(url: string) {
    this.ac = axios.create({
      baseURL: url,
    });
  }

  public async getOrderbook(opt: GetOrderBookArguments): Promise<OrderBook> {
    const res = await this.ac.get('/sra/v4/orderbook', {
      params: opt,
    });
    return res.data;
  }

  public async postOrder(order: Order): Promise<void> {
    const res = await this.ac.post('/sra/v4/order', JSON.stringify(order), {
      params: { skipConfirmation: false },
      headers: { 'Content-Type': 'application/json' },
    });
    return res.data;
  }
}
