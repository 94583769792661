import { ChainId, Config } from '@usedapp/core';

export const network_configs: { [key: string]: Config } = {
  ethereum: {
    readOnlyChainId: ChainId.Mainnet,
    readOnlyUrls: {
      [ChainId.Mainnet]: 'https://mainnet.infura.io/v3/079d74c2b64b44e094ee15c039a1403e',
    },
  },
  ethereum_fork: {
    readOnlyChainId: ChainId.Mainnet,
    readOnlyUrls: {
      [ChainId.Mainnet]: 'http://192.168.1.17:8545',
    },
    multicallAddresses: {
      [ChainId.Mainnet]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
    },
  },
  arbitrum: {
    readOnlyChainId: 42161,
    readOnlyUrls: {
      42161: 'https://arb1.arbitrum.io/rpc',
    },
    multicallAddresses: {
      42161: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
    },
  },
  optimism: {
    readOnlyChainId: 10,
    readOnlyUrls: {
      10: 'https://mainnet.optimism.io',
    },
    multicallAddresses: {
      10: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
    },
  },
  kovan: {
    readOnlyChainId: ChainId.Kovan,
    readOnlyUrls: {
      [ChainId.Kovan]: 'https://kovan.infura.io/v3/079d74c2b64b44e094ee15c039a1403e',
    },
    multicallAddresses: {
      [ChainId.Kovan]: '0x2cc8688c5f75e365aaeeb4ea8d6a480405a48d2a',
    },
  },
};

export interface AddNetworkConfig {
  rpc: string;
  chainId: number;
  chainName: string;
  explorer: string;
  currency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: number;
  };
}

export const add_network_configs: { [key: string]: AddNetworkConfig } = {
  arbitrum: {
    rpc: 'https://arb1.arbitrum.io/rpc',
    chainId: 42161,
    chainName: 'Arbitrum',
    explorer: 'https://arbiscan.io',
    currency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  optimism: {
    rpc: 'https://mainnet.optimism.io',
    chainId: 10,
    chainName: 'Optimism',
    explorer: 'https://optimistic.etherscan.io/',
    currency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
};
