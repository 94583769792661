/* eslint-disable react/jsx-no-useless-fragment */
import { Suspense, lazy } from 'react';

const MyPositions = lazy(() => import('../app/MyPositions'));

export const LLMyPositions = (props: React.ComponentProps<typeof MyPositions>) => {
  return (
    <Suspense fallback={<></>}>
      <MyPositions {...props} />
    </Suspense>
  );
};
