import * as _styled from 'styled-components';

export interface Theme {
  bg: string;
  fg: string;
  spacing: {
    s: string;
    m: string;
    l: string;
    xl: string;
  };
  error: string;
  success: string;
  warning: string;
}

const theme: Theme = {
  bg: '#ffffff',
  fg: '#000000',
  spacing: {
    s: '4px',
    m: '8px',
    l: '16px',
    xl: '32px',
  },
  success: '#77DD77',
  error: '#FF6961',
  warning: '#ffd394',
};

export const ThemeProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <_styled.ThemeProvider theme={theme}>{children}</_styled.ThemeProvider>
  );
};

export const styled: _styled.ThemedBaseStyledInterface<Theme> = _styled.default;
